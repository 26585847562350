import React from "react";

import { Typography, Box, Modal, Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ForceRefreshInfo = ({ open }) => {
  const handleClose = () => {
    window.location.reload();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          K dispozícií je nová verzia aplikácie. Pre korektné fungovanie je
          potrebné opätovné načítanie stránky.
        </Typography>
        <Button variant="contained" sx={{ mt: 2 }} onClick={handleClose}>
          Obnoviť stránku
        </Button>
      </Box>
    </Modal>
  );
};

export default ForceRefreshInfo;
