import { Box, Link, Typography } from "@mui/material";
import { pubPlan1, pubPlan2, pubPlan3 } from "./TextConstants";
import { Link as RouterLink } from "react-router-dom";

const TutorialPubPlan = () => {
  const fontSize = "1.2rem";
  return (
    <Box sx={{ backgroundColor: "white", p: 2, borderRadius: "8px" }}>
      <Typography variant="h3" textAlign={"center"}>
        Návod pre prácu s publikačným plánom MAVE
      </Typography>
      <Typography variant="h5" color={"red"} textAlign={"justify"}>
        Pre správne fungovanie publikačného plánu musí mať používateľ systému
        prideleného seba ako autora z knižnice - spárovanie MAVE účtu s autorom
        z knižnice. Spárovanie majú na starosti referenti publikačnej činnosti.
      </Typography>
      <Typography fontSize={fontSize} mt={1} textAlign={"justify"}>
        Prejdite na url:{" "}
        <Link
          component={RouterLink}
          underline="none"
          to="https://mave.feit.uniza.sk/scienceOutputs"
          target="_blank"
        >
          https://mave.feit.uniza.sk/scienceOutputs
        </Link>{" "}
        a otvorte si druhý tab v poradí s názvom „Publikačný plán“:
      </Typography>
      <Box
        component="img"
        src="/assets/images/plan1.png"
        sx={{ width: "95%", height: "auto" }}
      />
      <Typography fontSize={fontSize} mt={1} textAlign={"justify"}>
        {pubPlan1}
      </Typography>
      <Typography fontSize={fontSize} mt={1} textAlign={"justify"}>
        {pubPlan2}
      </Typography>
      <Box sx={{ textAlign: "center" }}>
        <Box
          component="img"
          src="/assets/images/plan2.png"
          sx={{ width: "40%", height: "auto" }}
        />
      </Box>
      <Typography fontSize={fontSize} mt={1} textAlign={"justify"}>
        S každou publikáciou je možné robiť nasledovné operácie:
      </Typography>
      <ul
        style={{
          fontSize: fontSize,
          padding: 0,
          paddingLeft: "2.5rem",
          margin: 5,
        }}
      >
        <li>upraviť všetky položky publikácie,</li>
        <li>zmeniť stav publikácie,</li>
        <li>
          zobraziť si históriu zmien publikácie – každá zmena sa ukladá do
          databázy,
        </li>
        <li>
          presunúť publikáciu do ďalšieho roku – publikáciu nie je možné
          presúvať späť v čase,
        </li>
        <li>
          vymazať záznam – záznam sa v skutočnosti nevymaže, zmení sa len
          príznak na zmazaný. Je možné si zobraziť aj zmazané publikácie pomocou
          prepínača „Zobraziť zmazané publikácie“.
        </li>
      </ul>
      <Typography fontSize={fontSize} mt={1} textAlign={"justify"}>
        {pubPlan3}
      </Typography>
    </Box>
  );
};

export default TutorialPubPlan;
