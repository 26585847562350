import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import GeneralInfo from "components/aboutPage/GeneralInfo";
import TutorialPubPlan from "components/aboutPage/TutorialPubPlan";
import QAPage from "components/aboutPage/QAPage";
import TutorialJournal from "components/aboutPage/TutorialJournal";
import TutorialExtAuthor from "components/aboutPage/TutorialExtAuthor";

const AboutPage = () => {
  const [value, setValue] = useState("0");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      title: "Všeobecné informácie",
      element: <GeneralInfo />,
    },
    {
      title: "Návod publikačný plán",
      element: <TutorialPubPlan />,
    },
    {
      title: "Návod priznanie podielu autorom",
      element: <TutorialExtAuthor />,
    },
    {
      title: "Návod priradenia časopisu",
      element: <TutorialJournal />,
    },
    {
      title: "Otázky a odpovede",
      element: <QAPage changeTab={handleChange} />,
    },
  ];

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList centered onChange={handleChange}>
            {tabs.map((o, i) => (
              <Tab
                key={i}
                label={o.title}
                value={String(i)}
                sx={{
                  fontSize: 13,
                  padding: 0.9,
                }}
              />
            ))}
          </TabList>
        </Box>
        {tabs.map((o, i) => (
          <TabPanel key={i} value={String(i)}>
            {o.element}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default AboutPage;
