import { Box, Card, CardContent, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

const TutorialData = () => {
  return (
    <Card sx={{ maxHeight: 550 }}>
      <Typography
        gutterBottom
        variant="h5"
        component="div"
        sx={{ pt: 2, px: 2 }}
      >
        Základné info o MAVE systéme, pre návod a podrobnosti kliknite{" "}
        <Link component={RouterLink} to="/about">
          sem
        </Link>
      </Typography>
      <CardContent>
        <Box
          component="img"
          src="/assets/images/veda.png"
          sx={{ width: "90%", height: "auto" }}
        />
      </CardContent>
    </Card>
  );
};

export default TutorialData;
