import React, { useCallback, useEffect } from "react";

import {
  MenuItem,
  TextField,
  Card,
  Box,
  Tab,
  Tabs,
  Checkbox,
  FormControlLabel,
  Stack,
} from "@mui/material";
import PropTypes from "prop-types";

import api from "../../api/api";
import { formatError } from "../../helpers";
import DirectActivities from "./DirectActivities";
import Organizations from "./Organizations";
import IndirectActivities from "./IndirectActivities";
import YearSummary from "./YearSummary";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const YearDetails = ({ isVisible, forceReload }) => {
  const [years, setYears] = React.useState([]);
  const [selectedYear, setSelectedYear] = React.useState("");
  const [useOnlyApproved, setUseOnlyApproved] = React.useState(false);
  const [yearData, setYearData] = React.useState(null);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const prepareData = useCallback(
    async (year) => {
      try {
        if (forceReload) {
        }
        const response = await api.get("/user/yearReport/" + year);
        setYearData(response.data);
      } catch (error) {
        alert(formatError(error));
      }
    },
    [forceReload]
  );

  let yearDetailContent = null;

  if (yearData && isVisible) {
    yearDetailContent = (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Priamo merateľné aktivity" {...a11yProps(0)} />
            <Tab label="Organizácia aktivít" {...a11yProps(1)} />
            <Tab label="Nepriamo merateľné aktivity" {...a11yProps(2)} />
            <Tab label="Do rozpoctu" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <DirectActivities
            data={yearData.direct}
            useOnlyApproved={useOnlyApproved}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Organizations
            data={yearData.organizing}
            useOnlyApproved={useOnlyApproved}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <IndirectActivities
            data={yearData.indirect}
            useOnlyApproved={useOnlyApproved}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <YearSummary data={yearData} useOnlyApproved={useOnlyApproved} />
        </TabPanel>
      </Box>
    );
  }

  useEffect(() => {
    const startYear = 2021;
    let currentYear = new Date().getFullYear();
    const arr = [];
    arr.push(currentYear);
    while (currentYear - startYear) {
      arr.push(--currentYear);
    }
    setYears(arr);
    if (selectedYear === "") {
      setSelectedYear(new Date().getFullYear().toString());
      prepareData(new Date().getFullYear());
    } else {
      prepareData(selectedYear);
    }
  }, [prepareData, selectedYear]);
  return (
    <Card sx={{ p: 1, mb: 1, flexDirection: "row", display: "flex" }}>
      <Stack>
        <TextField
          autoFocus
          margin="normal"
          id="year"
          name="year"
          label="Vyber rok"
          value={selectedYear}
          onChange={(event) => {
            setSelectedYear(event.target.value);
            //prepareData(event.target.value);
          }}
          select
          sx={{ width: 150, mr: 1 }}
        >
          {years.map((item) => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </TextField>
        <FormControlLabel
          control={
            <Checkbox
              checked={useOnlyApproved}
              onChange={(event) => {
                setUseOnlyApproved(event.target.checked);
              }}
            />
          }
          label="Počítaj len schválené výkony"
        />
      </Stack>
      {yearDetailContent}
    </Card>
  );
};

export default YearDetails;
