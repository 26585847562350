export const newVersionAvailable = (arr) => {
  for (const ar of arr) {
    if (ar.indexOf("appVersion") !== -1) {
      const appVersion = localStorage.getItem("appVersion");
      //console.log(!appVersion, appVersion !== ar);
      if (!appVersion || appVersion !== ar) {
        localStorage.setItem("appVersion", ar);
        window.location.reload();
        return false;
      }
    }
  }
  return false;
};

export const formatError = (err) => {
  if (err.response && err.response.data) {
    if (err.response.status === 413) {
      return "Obrázok je príliž veľký. Vyberte menší obrázok.";
    }
    let errStr = err.response.data.message;
    if (err.response.data.reason?.length > 0) {
      errStr +=
        ", " +
        err.response.data.reason
          .map((item) => {
            return `${item.msg} (${item.param})`;
          })
          .join(", ");
    }
    return errStr;
  } else {
    return err.message;
  }
};

export const formatDate = (src) => {
  let res = "";
  const dtObj = new Date(src);
  res = `${dtObj.getDate()}.${dtObj.getMonth() + 1}.${dtObj.getFullYear()}`;
  return res;
};

export const authLevel = [
  { level: 0, text: "Radový člen" },
  { level: 1, text: "Katedrový koordinátor" },
  { level: 2, text: "Fakultný koordinátor" },
  { level: 3, text: "Prodekan" },
];

export const personCategories = [
  { level: 0, text: "Študent" },
  { level: 1, text: "PhD študent" },
  { level: 2, text: "Zamestnanec" },
  { level: 3, text: "Katedra" },
];

export const translatePersonCategoty = (cat) => {
  for (const item of personCategories) {
    if (item.level === cat) {
      return item.text;
    }
  }
  return "";
};

export const translateAuthLevel = (level) => {
  for (const item of authLevel) {
    if (item.level === level) {
      return item.text;
    }
  }
  if (level === 5) {
    return "Administrátor";
  }
  return "";
};

export const activityBase = [
  { level: 1, text: "Priamo merateľné aktivity", color: "red" },
  { level: 2, text: "Organizácia aktivít", color: "green" },
  { level: 3, text: "Nepriamo merateľné aktivity", color: "blue" },
];

export const getActivityColor = (level) => {
  for (const base of activityBase) {
    if (base.level === level) {
      return base.color;
    }
  }
  return "black";
};

export const translateActivityBase = (level) => {
  for (const base of activityBase) {
    if (base.level === level) {
      return base.text;
    }
  }
  return "";
};

export const checkDates = (dates, canBeSame = true) => {
  const datesObj = dates.map((d) => {
    const parts = d.split(".");
    return new Date(`${parts[2]}/${parts[1]}/${parts[0]}`);
  });
  const result = [];
  new Date().toLocaleDateString();
  for (let i = 0; i < datesObj.length - 1; i++) {
    if (datesObj[i] > datesObj[i + 1]) {
      result.push(
        `Dátum ${datesObj[i + 1].toLocaleDateString(
          "sk"
        )} nemôže byť skorší ako ${datesObj[i].toLocaleDateString("sk")}`
      );
    } else if (datesObj[i] === datesObj[i + 1] && !canBeSame) {
      result.push(
        `Dátum ${datesObj[i + 1].toLocaleDateString(
          "sk"
        )} nemôže byť rovnaký ako ${datesObj[i].toLocaleDateString("sk")}`
      );
    }
  }
  return result;
};

export const getUrl = (url) => {
  const yearRestriction = localStorage.getItem("yearRestriction");
  if (parseInt(yearRestriction) !== 0 && yearRestriction !== null) {
    url += "ByYear/" + yearRestriction;
  }
  return url;
};

export const departments = [
  "FEIT",
  "IAS",
  "KEEP",
  "KF",
  "KMAE",
  "KME",
  "KMIKT",
  "KRIS",
  "KTEBI",
];

const diacritics = {
  a: "áÁä",
  c: "čČ",
  d: "ďĎ",
  e: "éÉěĚ",
  i: "íÍ",
  l: "ľĺĽĹ",
  n: "ňŇ",
  o: "óÓô",
  r: "řŘ",
  s: "Šš",
  t: "ťŤ",
  u: "úÚ",
  y: "ýÝ",
  z: "žŽ",
};

export const calcOuputPerDep = (outputs, useOnlyApproved = false) => {
  const outputObj = {};
  for (const d of departments) {
    if (d !== "FEIT") {
      let sum = 0;
      for (const out of outputs) {
        if (out.approved || !useOnlyApproved) {
          if (out.department === d) {
            sum += parseFloat(out.output);
          }
        }
      }
      outputObj[d] = Math.floor(sum * 100) / 100;
    }
  }
  return outputObj;
};

export const replaceDiacritics = (text) => {
  let res = "";
  for (let c of text) {
    let c_added = false;
    for (let toLetter in diacritics) {
      if (diacritics[toLetter].indexOf(c) !== -1) {
        res += toLetter;
        c_added = true;
        break;
      }
    }
    if (!c_added) {
      res += c.toLocaleLowerCase();
    }
  }
  return res;
};

export const toFixedFloat = (value, decimals = 2) => {
  if (typeof value === "undefined") {
    return 0;
  }
  return parseFloat(value.toFixed(decimals));
};

export const pubHasExtAuthors = (pub) => {
  return pub.authors.some(
    (author) => author.affiliation === "EXT" || author.affiliation === "N/A"
  );
};

const editColumnData = (item) => {
  if (item === "undefined" || typeof item === "undefined") {
    return " ";
  }
  if (typeof item === "boolean") {
    return item ? "Áno" : "Nie";
  }
  if (typeof item === "object") {
    const lookupKeys = ["sourceName"];
    for (const key in item) {
      const index = lookupKeys.indexOf(key);
      if (index !== -1) {
        return item[key];
      }
    }
    return "";
  }
  if (Array.isArray(item)) {
    return item.length;
  }
  if (typeof item === "number") {
    if (item > 1622505600) {
      return new Date(item).toLocaleDateString("sk");
    }
  }
  return item;
};

export const exportExcel = (wsName, wbName, columns, data, wsCols = []) => {
  const XLSX = require("xlsx");
  const rows = [];
  for (const row of data) {
    const obj = {};
    for (let i = 0; i < columns.length; i++) {
      obj[columns[i].label] = editColumnData(row.data[i]);
    }
    rows.push(obj);
  }
  const ws = XLSX.utils.json_to_sheet(rows);
  const wb = XLSX.utils.book_new();
  ws["!cols"] =
    wsCols.length > 0
      ? wsCols
      : [
          { width: 10 },
          { width: 10 },
          { width: 20 },
          { width: 30 },
          { width: 30 },
          { width: 10 },
          { width: 10 },
          { width: 10 },
          { width: 10 },
        ];
  XLSX.utils.book_append_sheet(wb, ws, wsName);
  XLSX.writeFile(wb, wbName);
};
