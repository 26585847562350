import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Box,
  TextField,
  Alert,
  IconButton,
  Tooltip,
} from "@mui/material";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";

import api from "../../api/api";

import { useTheme } from "@mui/material/styles";
import { formatError } from "../../helpers";

const AddJournal = ({ onEdit, showIcon = false }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [crepcJournal, setCrepcJournal] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
    setCrepcJournal(null);
    setError(null);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setCrepcJournal(null);
    setError(null);
    try {
      const response = await api.get(
        "science/findJournal/" + event.target.crepcID.value
      );
      setCrepcJournal(response.data);
    } catch (err) {
      setError(formatError(err));
    }
  };
  const handleJournalUpload = async () => {
    try {
      await api.post("science/journal/", crepcJournal);
      if (onEdit) {
        onEdit();
      }
      setCrepcJournal(null);
      handleClose();
    } catch (err) {
      setError(formatError(err));
    }
  };

  const renderTable = (data) => {
    return (
      <MuiTable size="small" aria-label="simple table">
        <TableBody>
          {Object.keys(data).map((key, i) => (
            <TableRow key={i}>
              <TableCell align="left">{key}</TableCell>
              <TableCell align="left">
                {["string", "number", "boolean"].includes(typeof data[key])
                  ? String(data[key])
                  : renderTable(data[key])}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    );
  };

  return (
    <div>
      {showIcon ? (
        <Tooltip title="Nastav crepcID">
          <IconButton aria-label="uprav" size="small" onClick={handleClickOpen}>
            <EditIcon fontSize="small" color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button variant="contained" onClick={handleClickOpen}>
          Pridaj casopis
        </Button>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ minWidth: matches ? 400 : 300 }}>
          <Box onSubmit={handleSubmit} component="form">
            <DialogTitle>Vyhladaj podla crepcID</DialogTitle>
            <TextField
              variant="outlined"
              label="crepcID"
              name="crepcID"
              id="crepcID"
              fullWidth
              required
            />
            {crepcJournal && renderTable(crepcJournal)}
            {error && (
              <Alert sx={{ m: 1, mb: 0, width: "100%" }} severity="error">
                {error}
              </Alert>
            )}
            <DialogActions>
              <Button onClick={handleClose} color="error">
                Zruš
              </Button>
              <Button type="submit">Vyhladaj</Button>
              {crepcJournal && (
                <Button onClick={handleJournalUpload}>Uloz zurnal</Button>
              )}
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddJournal;
