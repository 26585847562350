import { chartTypes } from "constants/pubChartType";

export const calcDiffJournals = (publications) => {
  const result = [];
  for (const pub of publications) {
    if (!pub.quartil) {
      continue;
    }
    if (pub.quartil.indexOf("Q") === -1) {
      continue;
    }
    let add = true;
    for (const r of result) {
      if (r.sourceOrgId === pub.sourceOrgId) {
        add = false;
        r.value++;
        break;
      }
    }
    if (add) {
      result.push({
        name: pub.sourceName,
        value: 1,
        sourceOrgId: pub.sourceOrgId,
      });
    }
  }

  return result.sort((a, b) => {
    return b.value - a.value;
  });
};

export const calcPubTypes = (publications) => {
  const tmpGData = chartTypes.map((item) => {
    return {
      ...item,
      value: 0,
    };
  });
  for (const pub of publications) {
    if (pub.quartil !== "undefined") {
      for (const g of tmpGData) {
        if (g.name === pub.quartil || g.name === pub.quartile) {
          g.value++;
          break;
        }
      }
    } else {
      if (pub.isWOS) {
        for (const g of tmpGData) {
          if (g.name === "WOS") {
            g.value++;
            break;
          }
        }
      } else if (pub.isScopus) {
        for (const g of tmpGData) {
          if (g.name === "Scopus") {
            g.value++;
            break;
          }
        }
      }
    }
  }
  return tmpGData;
};
