import { Grid, Typography } from "@mui/material";
import { FAQ } from "./TextConstants";
import QaComponent from "./QaComponent";

const QAPage = ({ changeTab }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{ backgroundColor: "white", p: 2, borderRadius: "8px" }}
    >
      <Grid item xs={12}>
        <Typography variant="h3" textAlign={"center"}>
          Často kladené otázky
        </Typography>
      </Grid>
      {FAQ.filter((item) => item.question !== "").map((item, index) => {
        return <QaComponent qaData={item} changeTab={changeTab} key={index} />;
      })}
    </Grid>
  );
};

export default QAPage;
