import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
//import api from "../../api/api";
import { formatError } from "../../helpers";

import api from "../../api/api";
import UpdatePublicationStatusFormular from "./UpdatePublicationStatusFormular";

const UpdatePublicationStatus = ({ values, onEdit }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await api.put(
        "/user/publicationPlan/updateState/" + values._id,
        { publicationStatus: event.target.publicationState.value }
      );
      if (response.status === 200) {
        handleClose();
        onEdit(500);
      }
    } catch (error) {
      setError(formatError(error));
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Zmeň stav publikácie">
        <IconButton
          aria-label="pubStatus"
          size="small"
          onClick={handleClickOpen}
        >
          <PublishedWithChangesIcon fontSize="medium" color="primary" />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Zmeň stav publikácie</DialogTitle>
          <DialogContent>
            <UpdatePublicationStatusFormular values={values} error={error} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default UpdatePublicationStatus;
