import { Box, Link, Typography } from "@mui/material";
import { journalText1, journalText2 } from "./TextConstants";
import { Link as RouterLink } from "react-router-dom";

const TutorialJournal = () => {
  const fontSize = "1.2rem";
  return (
    <Box sx={{ backgroundColor: "white", p: 2, borderRadius: "8px" }}>
      <Typography variant="h3" textAlign={"center"}>
        Návod pre priradenie časopisu CREPČ2 identifikátor
      </Typography>
      <Typography fontSize={fontSize} mt={1} textAlign={"justify"}>
        {journalText1}
        <Link
          component={RouterLink}
          underline="none"
          to="https://app.crepc.sk/"
          target="_blank"
        >
          CREPČ2.
        </Link>{" "}
        Následne na stráke "Zoznam časopisov" stlačte tlačidlo "Pridaj časopis"
      </Typography>
      <Box sx={{ textAlign: "center", my: 1 }}>
        <Box
          component="img"
          src="/assets/images/journal1.png"
          sx={{ width: "95%", height: "auto" }}
        />
      </Box>
      <Typography fontSize={fontSize} mt={1} textAlign={"justify"}>
        {journalText2}
      </Typography>{" "}
      <Box sx={{ textAlign: "center", my: 2 }}>
        <Box
          component="img"
          src="/assets/images/journal2.png"
          sx={{ width: "95%", height: "auto" }}
        />
      </Box>
    </Box>
  );
};

export default TutorialJournal;
