import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  forceReloader: 0,
  lastSeenTimestamp: 0,
  yearRestriction: 0,
  scienceTabPosition: "0",
};

const dataSlice = createSlice({
  name: "data",
  initialState: {
    ...defaultState,
  },
  reducers: {
    setScienceTabPosition(state, action) {
      state.scienceTabPosition = action.payload;
    },
    setYaerRestriction(state, action) {
      state.yearRestriction = action.payload;
    },
    setLastSeenTimestamp(state, action) {
      state.lastSeenTimestamp = new Date().getTime();
    },
    updateForceReloader(state, action) {
      // state.forceReloader = new Date().getTime();
      return {
        ...state,
        forceReloader: new Date().getTime(),
      };
    },
  },
});

export const dataAction = dataSlice.actions;

export default dataSlice;
