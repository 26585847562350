import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import api from "../../api/api";
import { formatError } from "../../helpers";
import FormularItem from "./FormularItem";

const EditItem = ({ values, onEdit }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("title", event.target.title.value);
      formData.append("price", parseFloat(event.target.price.value));
      formData.append("note", event.target.note.value);
      if (event.target.image.files.length) {
        formData.append("image", event.target.image.files[0]);
      }
      const response = await api.put(
        "/fakulta/warehouse/" + values._id,
        formData
      );
      if (response.status === 200) {
        handleClose();
        onEdit(500);
      }
    } catch (err) {
      setError(formatError(err));
    }
  };

  return (
    <div>
      <Tooltip title="Uprav">
        <IconButton aria-label="uprav" size="small" onClick={handleClickOpen}>
          <EditIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Title</DialogTitle>
          <DialogContent>
            <FormularItem values={values} error={error} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default EditItem;
