import React, { Fragment } from "react";
import { Container, Box, Typography } from "@mui/material";

import MainNavigation from "./MainNavigation";
import { Outlet, useLocation } from "react-router-dom";

const Layout = () => {
  const location = useLocation();
  let maxWidth = "lg";
  if (
    location.pathname.includes("manageOutputs") ||
    location.pathname.includes("scienceOutputs") ||
    location.pathname.includes("systemAdmin")
  ) {
    maxWidth = false;
  }
  if (
    location.pathname.includes("manageEvents") ||
    location.pathname.includes("manageUsers") ||
    location.pathname.includes("/scienceOutputs/manageAuthors")
  ) {
    maxWidth = "xl";
  }
  return (
    <Fragment>
      <MainNavigation></MainNavigation>
      <Container sx={{ mb: 10 }} maxWidth={maxWidth}>
        <Outlet />
      </Container>
      <Box
        sx={{
          bgcolor: "#262626",
          bottom: 0,
          color: "#666666",
          display: "flex",
          justifyContent: "flex-end",
          position: "fixed",
          textTransform: "uppercase",
          width: "100%",
          pr: 2,
        }}
      >
        <Typography variant="h6" mr={1}>
          Designed by FEIT {new Date().getFullYear()}
        </Typography>
      </Box>
    </Fragment>
  );
};

export default Layout;
