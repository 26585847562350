import React from "react";

import { Alert, MenuItem, TextField } from "@mui/material";
import { authLevel, departments, personCategories } from "../../helpers";

const EditUserForm = ({ values, error }) => {
  const nameSplits = values.name.split(" ");
  let name = "";
  let surname = "";
  if (nameSplits.length > 0) {
    surname = nameSplits[0];
  }
  if (nameSplits.length > 1) {
    name = nameSplits[1];
  }
  return (
    <React.Fragment>
      <TextField
        sx={{ mt: 2 }}
        variant="outlined"
        label="Meno"
        name="name"
        id="name"
        defaultValue={name}
        fullWidth
        required
      />
      <TextField
        sx={{ mt: 2 }}
        variant="outlined"
        label="Priezvisko"
        name="surname"
        id="surname"
        defaultValue={surname}
        fullWidth
        required
      />
      <TextField
        autoFocus
        margin="normal"
        id="authorizationLevel"
        name="authorizationLevel"
        label="Úroveň účtu"
        fullWidth
        defaultValue={values.authorizationLevel || 0}
        select
      >
        {authLevel.map((item) => {
          return (
            <MenuItem key={item.level} value={item.level}>
              {item.text}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        margin="normal"
        id="department"
        name="department"
        label="Katedra (zaradenie)"
        fullWidth
        defaultValue={values.department || "FEIT"}
        select
      >
        {departments.map((item) => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        margin="normal"
        id="personCategory"
        name="personCategory"
        label="Kategória"
        fullWidth
        defaultValue={values.personCategory || 0}
        select
      >
        {personCategories.map((item) => {
          return (
            <MenuItem key={item.level} value={item.level}>
              {item.text}
            </MenuItem>
          );
        })}
      </TextField>
      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
    </React.Fragment>
  );
};

export default EditUserForm;
