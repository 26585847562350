import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  isAuthentificated: false,
  token: null,
  email: "",
  isAdmin: false,
  authorizationLevel: 0,
  personCategory: 2,
  department: "",
  name: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    ...defaultState,
  },
  reducers: {
    setToken(state, action) {
      localStorage.setItem("appToken", action.payload);
      state.token = action.payload;
      state.isAuthentificated = true;
    },
    setUserData(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearAuthData(state) {
      localStorage.removeItem("appToken");
      return defaultState;
    },
  },
});

export const authAction = authSlice.actions;

export default authSlice;
