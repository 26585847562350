import React, { useCallback, useEffect } from "react";

import { calcOuputPerDep, departments } from "../../helpers";
import Table from "../universal/Table";

const YearSummary = ({ data, useOnlyApproved }) => {
  const [rows, setRows] = React.useState([]);

  const prepareData = useCallback(() => {
    const sumArray = {};
    const sumDirect = {};
    const sumInDirect = {};
    const sumOrganizations = {};
    for (const d of departments) {
      if (d !== "FEIT") {
        sumArray[d] = 0;
        sumDirect[d] = 0;
        sumInDirect[d] = 0;
        sumOrganizations[d] = 0;
      }
    }
    let counter = 0;

    for (const a of data.direct.activities) {
      const perDep = calcOuputPerDep(a.outputs, useOnlyApproved);
      for (const p in perDep) {
        sumDirect[p] += perDep[p];
      }
    }
    for (const a of data.indirect.events) {
      const perDep = calcOuputPerDep(a.outputs, useOnlyApproved);
      for (const p in perDep) {
        sumInDirect[p] += perDep[p];
      }
    }

    for (const a of data.organizing) {
      const perDep = calcOuputPerDep(a.outputs, useOnlyApproved);
      for (const p in perDep) {
        sumOrganizations[p] += perDep[p];
      }
    }
    for (const key in sumArray) {
      sumArray[key] =
        Math.floor(
          (sumDirect[key] + sumInDirect[key] + sumOrganizations[key]) * 100
        ) / 100;
    }

    const arr = [];

    arr.push({
      type: "Priamo",
      counter: ++counter,
      ...sumDirect,
    });
    arr.push({
      type: "Organizácia",
      counter: ++counter,
      ...sumOrganizations,
    });
    arr.push({
      type: "Nepriamo",
      counter: ++counter,
      ...sumInDirect,
    });

    let sumOutputs = 0;
    for (const key in sumArray) {
      sumOutputs += sumArray[key];
    }
    const percentageArray = {};
    for (const key in sumArray) {
      let val = 0;
      if (sumOutputs !== 0) {
        val = ((sumArray[key] * 100) / sumOutputs).toFixed(2);
      }
      percentageArray[key] = val;
    }

    arr.push({
      type: "Spolu [človek/deň]",
      counter: ++counter,
      ...sumArray,
      sum: sumOutputs.toFixed(2),
    });
    arr.push({
      type: "Podiel na FEIT [%]",
      counter: ++counter,
      ...percentageArray,
      sum: 100,
    });
    setRows(arr);
  }, [data, useOnlyApproved]);

  const head = [
    {
      id: "type",
      numeric: false,
      label: "",
      search: false,
    },
  ];
  for (const d of departments) {
    if (d !== "FEIT") {
      head.push({
        id: d,
        numeric: true,
        label: d,
        search: false,
      });
    }
  }
  head.push({
    id: "sum",
    numeric: true,
    label: "Spolu FEIT",
    search: false,
  });
  head.push({
    id: "counter",
    numeric: true,
    label: "Por. číslo",
    search: false,
  });

  const settings = {
    rows: rows,
    loading: false,
    colOrder: {
      order: "desc",
      orderBy: "counter",
    },
    dense: true,
    headCells: head,
  };

  useEffect(() => {
    prepareData();
  }, [prepareData]);

  return <Table settings={settings} showPagination={false} />;
};

export default YearSummary;
