import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  Alert,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import api from "../../api/api";

import { formatError } from "../../helpers";

const SetForcedPoints = ({ recordId, onEdit }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
    setError(null);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    try {
      await api.put("user/publication/setForcedPoints/" + recordId, {
        forcedPoints: event.target.forcedPoints.value,
      });
      onEdit();
      handleClose();
    } catch (err) {
      setError(formatError(err));
    }
  };

  return (
    <div>
      <Tooltip title="Pevné nastavenie bodov">
        <IconButton aria-label="Nastav" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Box onSubmit={handleSubmit} component="form">
            <DialogTitle>Nastav pevné body pre publikáciu</DialogTitle>
            <TextField
              variant="outlined"
              label="Pevné body"
              name="forcedPoints"
              id="forcedPoints"
              fullWidth
              required
            />
            {error && (
              <Alert sx={{ m: 1, mb: 0, width: "100%" }} severity="error">
                {error}
              </Alert>
            )}
            <DialogActions>
              <Button onClick={handleClose} color="error">
                Zruš
              </Button>
              <Button type="submit">Nastav</Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SetForcedPoints;
