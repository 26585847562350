import React, { useEffect } from "react";
import {
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
  TableSortLabel,
  TextField,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  searchCell: {
    paddingTop: "0px",
  },
  searchCellButton: {
    padding: "0 !important",
    borderBottom: "0",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableFilter: {
    marginBottom: theme.spacing(2),
  },
}));

const TableHead = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    filterChange,
    timeoutTime,
    filter,
    setFilter,
    tableId,
  } = props;
  const classes = useStyles();
  const [typingTimeout, setTypingTimeout] = React.useState(false);
  const [typingTimeoutTime, setTypingTimeoutTime] = React.useState(100);

  const createSortHandler = (property, tableId) => (event) => {
    onRequestSort(event, property, tableId);
  };

  const setFilterValue = (name, value) => {
    let tmpFilter = filter;
    if (value === "") {
      delete tmpFilter[name];
    } else {
      tmpFilter[name] = value;
    }
    setFilter(tmpFilter);
    clearTimeout(typingTimeout);
    setTypingTimeout(
      setTimeout(() => {
        filterChange(tmpFilter);
      }, typingTimeoutTime)
    );
  };

  const filterChangeLocal = (event) => {
    setFilterValue(event.target.name, event.target.value);
  };

  const deleteFilter = () => {
    clearTimeout(typingTimeout);
    filterChange({});
  };

  useEffect(() => {
    if (timeoutTime) {
      setTypingTimeoutTime(timeoutTime);
    }
  }, [timeoutTime]);

  const renderSearchField = (headCell) => {
    return (
      <TextField
        variant="standard"
        label={headCell.label}
        name={headCell.id}
        value={filter[headCell.id] || ""}
        onChange={filterChangeLocal}
      />
    );
  };

  return (
    <MuiTableHead>
      <TableRow className={"filterRow"}>
        {headCells.map((headCell, index) =>
          headCell.hide ? null : (
            <TableCell key={index} className={classes.searchCell}>
              {index !== headCells.length - 1 ? (
                headCell.search ? (
                  renderSearchField(headCell)
                ) : null
              ) : (
                <Box display="flex" justifyContent="end">
                  {headCell.search && renderSearchField(headCell)}
                  <Tooltip title="text">
                    <IconButton aria-label="delete" onClick={deleteFilter}>
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </TableCell>
          )
        )}
      </TableRow>
      <TableRow>
        {headCells.map((headCell, index) =>
          headCell.hide ? null : (
            <TableCell
              key={index}
              align={headCell.numeric ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableOrder ? (
                headCell.renderLabel ? (
                  headCell.renderLabel()
                ) : (
                  headCell.label
                )
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id, tableId)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;
