const generalText1 =
  "Informačný systém MAVE slúži pre interné potreby fakulty FEIT. Skladá sa z dvoch častí: ";
const generalText2 = `Hlavnou úlohou systému je v čo najväčšej miere zjednodušiť procesy, 
ktoré sú spojené s vyhodnocovaním výkonov zamestnancov FEIT. Každý zamestnanec FEIT má
prístup k správe publikačnej činnosti, pričom k správe výkonov marketingovej činnosti
majú prístup iba tí zamestnanci, ktorí sa priamo podieľajú na marketingových aktivitách. Prehľadový obrázok fungovania 
správy publikačnej činnosti je zobrazený nižšie. Hlavným zdrojom informácií je univerzitná 
knižnica, z ktorej si systém pravidelne aktualizuje údaje o publikačnej činnosti. Pre správne
určovanie bodov za publikácie v karentovaných časopisoch je potrebné poznať kvartil časopisu. 
Táto informácia sa v knižnici nenachádza. Preto si ju systém sťahuje z databázy CREPČ2. Okrem 
toho systém v pravidelných intervaloch kontroluje indexáciu publikácií v databázach Scopus a
WOS.`;

const generalText3 = `Systém MAVE je navrhnutý tak, aby poskytoval čo najviac informácií
s najmenším úsilím. Pre správne určenie bodov za publikácie je ale v niektorých prípadoch 
potrebná interakcia autorov. Podiel na článku za zahraničných autorov sa ignoruje. Ich podiel
je prepočítaný na autorov z UNIZA. Systém ale nevie, ktorí autori si uplatňujú autorský podiel
na Slovensku a ktorí nie. Preto je potrebné pri autoroch mimo UNIZA nastaviť príznak 
vyžaduje/nevyžaduje podiel. Tieto príznaky si môže autor nastaviť pri svojich článkoch, 
alebo to môže spraviť katedrový publikačný referent pre celú katedru. Pri nových časopisoch, 
ktoré nie sú ešte v systéme, je potrebné nájsť crepcID z CREPC2 databázy. Na základe crepcID
systém následne dokáže získať údaje o kvartiloch pre daný časopis.`;

const pubPlan1 = `Tu si môžete vybrať rok, pre ktorý chcete spravovať publikačný plán a tiež 
zobrazenie. V zobrazení je na výber môj plán – tu sú zobrazené iba moje plánované publikácie
(všetky kde som uvedený ako autor – takže ak aj môj kolega pridá publikáciu do plánu a nastaví 
ma ako autora, tak sa mi tu zobrazí). Druhá možnosť je plán katedry – tu vidím všetky 
publikácie katedry pre daný rok.`;

const pubPlan2 = `Pre pridanie publikácie stlač tlačidlo „Pridaj publikáciu“. Následne sa zobrazí 
formulár pridania publikácie. Celý proces pridávania záznamov do publikačného plánu sme sa 
snažili spraviť čo najjednoduchší. Najprv je potrebné vybrať typ publikácie – časopis, konferencia,
 iné. Formulár sa následne 
 mení podľa výberu. Pri časopise je potrebné vybrať cieľový časopis z už existujúcich časopisov 
 v systéme. Ak autor ide písať do časopisu, ktorý nie je v systému, musí ho najprv cez stránku 
 „Zoznam časopisov“ do systému pridať. Po vybratí časopisu sa vyplnia niektoré údaje automaticky.
  Ďalej je potrebné zadať názov publikácie a zoznam autorov. Autora, ktorý publikáciu pridáva, systém
  automaticky pridá do zoznamu autorov. Pridajte ostatných autorov - zadávajú sa iba tí autori,
  ktorí si uplatňujú podiel na Slovensku. Potom je potrebné autorom
   prideliť podiel (súčet musí byť 100). Je možné použiť tlačidlo pre rovnomerné pridelenie bodov.
    Pridajte publikáciu stlačením tlačidla „Pridaj publikáciu“.`;

const pubPlan3 = `V pravom hornom rohu sa počítajú body pre aktuálny výber (to znamená pre katedru,
alebo len pre jedného autora). Keď prepnete
do módu zobrazenia plán katedry, tak tam sa zobrazujú body pre katedru. Tu je možné si 
vybrať autora katedry (kde sa zobrazujú body pre vybraného autora). Doc. 
Hockicko ako prodekan si môže prezerať publikačný plán podľa katedier a taktiež všetkých
autorov fakulty.`;

const FAQ = [
  {
    question: "Moja publikácia nie je v MAVE.",
    answer: `Skontrolujte, či sa daná publikácia nachádza v univerzitnej knižnici aspoň 10 dní.
    Systém aktualizuje dáta z knižnice každý pondelok.`,
  },
  {
    question: "Nemám pristup k publikačnému plánu.",
    answer: `Kontaktujte svojho referenta publikačnej činnosti, aby Vám spároval MAVE účet s 
    autorom z knižnice.
    `,
  },
  {
    question: "Moja publikácia má nesprávne pridelené body.",
    answer: `Skontrolujte príznak vyžaduje/nevyžaduje sa podiel pre zahraničných autorov. Táto zmena sa prejaví hneď.
    Ak sa menila indexácia publikácie, proces aktualizovania bodov prebieha raz mesačne. V niektorých
    prípadoch môže aktualizácia bodov trvať skoro 2 mesiace.`,
  },

  {
    question: "Časopis nemá pridelený kvartil.",
    answer: `Kvartily časopisov sa aktualizujú raz za mesiac. Ak časopis má kvartil za minulé roky,
    ale nie za aktuálny, tak táto informácia nebola zverejnená na CREPČ2 databáze. Ak časopis
    nemá pridelené kvartily ani za ostatné roky, tak buď nie je indexovaný na WOS, alebo nemá 
    priradené crepcID. Ako postupovať pre priradenie crepcID časopisu nájdete v návode - `,
    link: {
      text: "TU.",
      type: "tab",
      navigateTo: "3",
    },
  },
  {
    question: "",
    answer: ``,
  },
  {
    question: "",
    answer: ``,
  },
  {
    question: "",
    answer: ``,
  },
  {
    question: "",
    answer: ``,
  },
  {
    question: "",
    answer: ``,
  },
];

const extText1 = `Pre každú publikáciu v systéme od roku 2021 je potrebné pre každého 
externého autora nastaviť príznak, či si vyžaduje alebo nevyžaduje uplatnenie svojho 
autorského podielu. Nastavuje sa to pre každú publikáciu samostatne. Je to potrebné 
pre korektné počítanie podielu pre katedru. Aj keď sa externí autori opakujú pri viacerých 
publikáciách, pre každý záznam je potrebné nastaviť tento príznak. Tiež sa dá pri externom 
autorovi nastaviť príznak „Vynútená príslušnosť“. Toto slúži na to, ak napríklad doktorand
 už skončil a článok vyšiel neskôr. V knižnici ma príznak EXT, ale na kolégiu dekana sa 
 dohodlo, že si to jeho podiel môže uplatňovať katedra. Viď obrázok nižšie.`;

const extText2 = `Nastavenie EXT autorov je možné z dvoch obrazoviek: moje publikácie a 
 publikácie katedry. Pre publikáciu, ktorá má EXT autorov, otvoríte okno nastavovania pomocou
  ikony úpravy na konci riadku, viď obrázok nižšie.`;

const extText3 = `Pre publikácie, ktoré nemajú EXT autorov sa v tabuľke ikona úpravy
   nenachádza. Príznaky pre EXT autorov môžu nastavovať referenti za svoje katedry, 
   alebo autori článku. Príznaky vyžaduje, nevyžaduje autorský podiel pre EXT autorov 
   sú dôležité z hľadiska pridelenia správneho počtu bodov pre katedru.
    Vyžaduje-nevyžaduje podiel pre EXT autorov sa počíta len pre publikácie
     z roku 2021 a vyššie.`;

const journalText1 = `Pre správne určovanie bodov za publikácie v karentovaných časopisoch
 je potrebné poznať kvartil časopisu. Táto informácia sa v knižnici nenachádza.
  Preto si ju systém sťahuje z databázy CREPČ2. Na to, aby si vedel systém stiahnuť informácie 
  z CREPČ2 databázy, musí poznať identifikátor časopisu. Ak v tabuľke Zoznam časopisov
  je pre príslušný časopis vyplnené id organizácie, tak je všetko OK. Ak tam nie je nič,
  pomlčka (-) alebo otázniky (???), tak časopis nemá pridelené správne crepcID.
  Prvý krok v priradení crepcID je nájdenie ID časopisu na stránkach
  `;
const journalText2 = `Zadajte crepcID a stlačte tlačidlo Vyhľadaj. V prípade neexistujúceho crepcID
systém vypíše chybové hlásenie, v opačnom prípade zobrazí aktuálne informácie o kvartile časopisu
z CREPČ2 databázy. Následne kliknem tlačidlo "Ulož žurnál" a crepcID a informácie o kvartile sa 
uložia do našej databázy.`;

module.exports = {
  generalText1,
  generalText2,
  generalText3,
  pubPlan1,
  pubPlan2,
  pubPlan3,
  FAQ,
  extText1,
  extText2,
  extText3,
  journalText1,
  journalText2,
};
