import React, { useCallback } from "react";
import "./App.css";
import { useRoutes } from "react-router-dom";
import publicRoutes from "./routes/PublicRoutes";
import { useDispatch, useSelector } from "react-redux";
import signinRoutes from "./routes/SignInRoutes";
import { authAction } from "./store/auth-slice";
import { formatError, newVersionAvailable } from "./helpers";
import api from "./api/api";
import ForceRefreshInfo from "./components/universal/ForceRefreshInfo";

function App() {
  const dispatch = useDispatch();
  const [showForceRefresh, setShowForceRefresh] = React.useState(false);
  const isAuthentificated = useSelector((state) => {
    return state.auth.isAuthentificated;
  });

  const getRouting = () => {
    const token = localStorage.getItem("appToken");
    if ((token && token !== undefined) || isAuthentificated) {
      return signinRoutes;
    } else {
      return publicRoutes;
    }
  };

  const checkUnseen = useCallback(async () => {
    try {
      const response = await api.get(
        "/user/unseen/" + sessionStorage.getItem("lastSeenTimestamp")
      );
      setShowForceRefresh(newVersionAvailable(response.data));
    } catch (error) {}
  }, []);

  const getCurrentUser = useCallback(async () => {
    try {
      const response = await api.get("/user/current");
      dispatch(authAction.setUserData(response.data));
      checkUnseen();
    } catch (err) {
      alert(formatError(err));
    }
  }, [dispatch, checkUnseen]);

  React.useEffect(() => {
    const token = localStorage.getItem("appToken");
    if (token && token !== undefined) {
      dispatch(authAction.setToken(token));
      getCurrentUser();
    } else {
      dispatch(authAction.clearAuthData());
    }
  }, [dispatch, getCurrentUser]);

  const routing = useRoutes(getRouting());
  return (
    <React.Fragment>
      {routing}
      <ForceRefreshInfo open={showForceRefresh} />
    </React.Fragment>
  );
}

export default App;
