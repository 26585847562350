import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import api from "../../api/api";
import { calcOuputPerDep, departments, formatError } from "../../helpers";
import { utils, writeFile } from "xlsx";

const YearExportDialog = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = React.useState(false);
  const [years, setYears] = React.useState([]);
  const [useOnlyApproved, setUseOnlyApproved] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    const startYear = 2021;
    let currentYear = new Date().getFullYear();
    const arr = [];
    arr.push(currentYear);
    while (currentYear - startYear) {
      arr.push(--currentYear);
    }
    setYears(arr);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await api.get(
        "/user/yearReport/" + event.target.year.value
      );
      exportFile(response.data, event.target.year.value);
      setTimeout(() => {
        handleClose();
      }, 500);
    } catch (error) {
      formatError(error);
    }
  };

  const exportFile = (data, year) => {
    ///mozno format npm i xlsx-js-style
    let dep = {};
    for (const d of departments) {
      if (d !== "FEIT") {
        dep[d] = d;
      }
    }
    ///////////////// WS1 ////////////////////
    const ws1 = utils.json_to_sheet([{ A: "Spočítané [človek/deň]" }], {
      skipHeader: true,
      origin: "D1",
    });
    utils.sheet_add_json(
      ws1,
      [{ A: "koeficient", B: "opis koeficientu", ...dep }],
      { skipHeader: true, origin: "B2" }
    );
    if (!data.direct.activities.length) {
      data.direct.activities.push({
        type: "",
        title: "",
        coefficient: 0,
        description: "",
        outputs: [],
      });
    }

    if (!data.direct.events.length) {
      data.direct.events.push({
        A: "",
        B: "",
        C: "",
        outputs: [],
      });
    }
    utils.sheet_add_json(
      ws1,
      data.direct.activities.map((a) => {
        return {
          A: a.type,
          B: a.coefficient,
          C: a.description,
          ...calcOuputPerDep(a.outputs, useOnlyApproved),
        };
      }),
      {
        skipHeader: true,
        origin: "A3",
      }
    );
    let sumObj = {};

    sumObj.A = "Suma priamo";
    sumObj.B = "";
    sumObj.C = "";
    for (let i = 67; i < 67 + departments.length; i++) {
      sumObj[String.fromCharCode(i)] = {
        t: "n",
        f: `SUM(${String.fromCharCode(i)}3:${String.fromCharCode(i)}${
          data.direct.activities.length + 2
        })`,
      };
    }
    utils.sheet_add_json(ws1, [sumObj], {
      skipHeader: true,
      origin: "A" + (3 + data.direct.activities.length),
    });
    utils.sheet_add_json(
      ws1,
      data.direct.events.map((event) => {
        return {
          A: event.title,
          B: "",
          C: event.description,
          ...calcOuputPerDep(event.outputs, useOnlyApproved),
        };
      }),
      {
        skipHeader: true,
        origin: "A" + (6 + data.direct.activities.length),
      }
    );
    ws1["!merges"] = [
      { s: { r: 0, c: 3 }, e: { r: 0, c: 1 + departments.length } },
      {
        s: { r: 2 + data.direct.activities.length, c: 0 },
        e: { r: 2 + data.direct.activities.length, c: 2 },
      },
    ];
    ws1["!cols"] = [
      { width: 50 },
      { width: 12 },
      { width: 25 },
      ...departments.map((d) => {
        return { width: 6 };
      }),
    ];

    ///////////////// WS2 ////////////////////
    const ws2 = utils.json_to_sheet([dep], {
      skipHeader: true,
      origin: "C1",
    });
    if (!data.organizing.length) {
      data.organizing.push({
        type: "",
        title: "",
        outputs: [],
      });
    }

    utils.sheet_add_json(
      ws2,
      data.organizing.map((item) => {
        return {
          A: item.type,
          B: item.title,
          ...calcOuputPerDep(item.outputs, useOnlyApproved),
        };
      }),
      { skipHeader: true, origin: "A2" }
    );

    sumObj = {};
    sumObj.A = "Spolu organizácia [človek/deň]";
    sumObj.B = "";
    for (let i = 66; i < 66 + departments.length; i++) {
      sumObj[String.fromCharCode(i)] = {
        t: "n",
        f: `SUM(${String.fromCharCode(i)}2:${String.fromCharCode(i)}${
          data.organizing.length + 1
        })`,
      };
    }

    utils.sheet_add_json(ws2, [sumObj], {
      skipHeader: true,
      origin: "A" + (data.organizing.length + 2),
    });
    //utils.sheet_add_json(ws1,[], {skipHeader: true, origin: ""})
    ws2["!merges"] = [
      { s: { r: 1, c: 0 }, e: { r: data.organizing.length, c: 0 } },
      {
        s: { r: 1 + data.organizing.length, c: 0 },
        e: { r: 1 + data.organizing.length, c: 1 },
      },
    ];
    ws2["!cols"] = [
      { width: 20 },
      { width: 20 },
      ...departments.map((d) => {
        return { width: 6 };
      }),
    ];

    ///////////////// WS3 ////////////////////
    const ws3 = utils.json_to_sheet([{ A: "Typ", B: "Popis", ...dep }], {
      skipHeader: true,
      origin: "A1",
    });

    if (!data.indirect.events.length) {
      data.indirect.events.push({
        type: "",
        title: "",
        outputs: [],
      });
    }

    utils.sheet_add_json(
      ws3,
      data.indirect.events.map((item) => {
        return {
          A: item.type,
          B: item.title,
          ...calcOuputPerDep(item.outputs, useOnlyApproved),
        };
      }),
      { skipHeader: true, origin: "A2" }
    );

    sumObj = {};
    sumObj.A = "Spolu nepriamo";
    sumObj.B = "";
    for (let i = 66; i < 66 + departments.length; i++) {
      sumObj[String.fromCharCode(i)] = {
        t: "n",
        f: `SUM(${String.fromCharCode(i)}2:${String.fromCharCode(i)}${
          data.indirect.events.length + 1
        })`,
      };
    }
    utils.sheet_add_json(ws3, [sumObj], {
      skipHeader: true,
      origin: "A" + (data.indirect.events.length + 2),
    });

    let indirectOutputs = [];
    for (const item of data.indirect.events) {
      indirectOutputs.push(...item.outputs);
    }
    indirectOutputs = indirectOutputs.sort(
      (a, b) => a.department > b.department
    );

    utils.sheet_add_json(
      ws3,
      indirectOutputs
        .filter((item) => item.approved || !useOnlyApproved)
        .map((item) => {
          return {
            A: item.name,
            B: item.department,
            C: item.output,
            D: item.note,
          };
        }),
      { skipHeader: true, origin: `A${data.indirect.events.length + 5}` }
    );

    ws3["!merges"] = [
      {
        s: { r: 1 + data.indirect.events.length, c: 0 },
        e: { r: 1 + data.indirect.events.length, c: 1 },
      },
    ];

    ws3["!cols"] = [
      { width: 25 },
      { width: 35 },
      ...departments.map((d) => {
        return { width: 6 };
      }),
    ];

    ///////////////// WS4 ////////////////////
    const ws4 = utils.json_to_sheet([dep], {
      skipHeader: true,
      origin: "B1",
    });

    /// Odkazy na priamo
    sumObj = {};
    sumObj.A = "Priamo";
    for (let i = 66; i < 66 + departments.length - 1; i++) {
      sumObj[String.fromCharCode(i)] = {
        t: "n",
        f: `='Priamo merateľné aktivity'!${String.fromCharCode(i + 2)}${
          data.direct.activities.length + 3
        }`,
      };
    }
    utils.sheet_add_json(ws4, [sumObj], {
      skipHeader: true,
      origin: "A2",
    });

    /// Odkazy na Organizácia
    sumObj = {};
    sumObj.A = "Organizácia";
    for (let i = 66; i < 66 + departments.length - 1; i++) {
      sumObj[String.fromCharCode(i)] = {
        t: "n",
        f: `='Organizácia aktivít'!${String.fromCharCode(i + 1)}${
          data.organizing.length + 2
        }`,
      };
    }
    utils.sheet_add_json(ws4, [sumObj], {
      skipHeader: true,
      origin: "A3",
    });

    /// Odkazy na Nepriamo
    sumObj = {};
    sumObj.A = "Nepriamo";
    for (let i = 66; i < 66 + departments.length - 1; i++) {
      sumObj[String.fromCharCode(i)] = {
        t: "n",
        f: `='Nepriamo merateľné aktivity'!${String.fromCharCode(i + 1)}${
          data.indirect.events.length + 2
        }`,
      };
    }
    sumObj.next = "Spolu FEIT";
    utils.sheet_add_json(ws4, [sumObj], {
      skipHeader: true,
      origin: "A4",
    });

    sumObj = {};
    sumObj.A = "Spolu [človek/deň]";
    for (let i = 66; i < 66 + departments.length - 1; i++) {
      sumObj[String.fromCharCode(i)] = {
        t: "n",
        f: `=SUM(${String.fromCharCode(i)}2:${String.fromCharCode(i)}4)`,
      };
    }
    sumObj.next = {
      t: "n",
      f: `=SUM(B5:${String.fromCharCode(66 + departments.length - 2)}5)`,
    };

    utils.sheet_add_json(ws4, [sumObj], {
      skipHeader: true,
      origin: "A5",
    });

    sumObj = {};
    sumObj.A = "Podiel na FEIT [%]";
    for (let i = 66; i < 66 + departments.length - 1; i++) {
      sumObj[String.fromCharCode(i)] = {
        t: "n",
        f: `=${String.fromCharCode(i)}5*100/$${String.fromCharCode(
          66 + departments.length - 1
        )}$5`,
        z: "0.00",
      };
    }
    sumObj.next = {
      t: "n",
      f: `=SUM(B6:${String.fromCharCode(66 + departments.length - 2)}6)`,
    };

    utils.sheet_add_json(ws4, [sumObj], {
      skipHeader: true,
      origin: "A6",
    });

    ws4["!cols"] = [
      { width: 20 },
      ...departments.map((d) => {
        return { width: 10 };
      }),
    ];

    const wb = utils.book_new();

    utils.book_append_sheet(wb, ws1, "Priamo merateľné aktivity");
    utils.book_append_sheet(wb, ws2, "Organizácia aktivít");
    utils.book_append_sheet(wb, ws3, "Nepriamo merateľné aktivity");
    utils.book_append_sheet(wb, ws4, "Do rozpoctu");

    writeFile(wb, `vykony_mrkt_feit_${year}.xlsx`);
  };

  return (
    <div>
      <Button
        sx={{ m: 1 }}
        variant="contained"
        color="secondary"
        fullWidth
        onClick={handleClickOpen}
      >
        Exportuj ročné údaje
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle sx={{ pl: matches ? 10 : 4, pr: matches ? 10 : 4 }}>
            Export ročných údajov
          </DialogTitle>
          <DialogContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useOnlyApproved}
                  onChange={(event) => {
                    setUseOnlyApproved(event.target.checked);
                  }}
                />
              }
              label="Exportuj len schválené výkony"
            />
            <TextField
              autoFocus
              margin="normal"
              id="year"
              name="year"
              label="Vyber rok"
              fullWidth
              defaultValue={new Date().getFullYear()}
              select
            >
              {years.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default YearExportDialog;
