import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import api from "api/api";
import Table from "components/universal/Table";
import { utils, writeFile } from "xlsx";

export default function DepartmentBudget() {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentyear = Math.max(new Date().getFullYear(), 2023);
  const [year, setYear] = React.useState(currentyear);
  const years = Array(currentyear - 2023 + 2)
    .fill("")
    .map((v, idx) => 2023 + idx);

  const handleChange = (event) => {
    setYear(event.target.value);
    setLoading(true);
  };

  const getRowBudgetObject = (row) => {
    let cpoints_p1 = `${row.partial_1.citationPointCount} (pouzitelne: ${
      parseFloat(row.partial_1.citationPointCount) >
      parseFloat(row.partial_1.publicationPoints)
        ? row.partial_1.publicationPoints
        : row.partial_1.citationPointCount
    })`;
    let cpoints_p2 = `${row.partial_2.citationPointCount} (pouzitelne: ${
      parseFloat(row.partial_2.citationPointCount) >
      parseFloat(row.partial_2.publicationPoints)
        ? row.partial_2.publicationPoints
        : row.partial_2.citationPointCount
    })`;
    return {
      A: row.department,
      B: row.totalPoint,
      C: row.partial_1.year,
      D: row.partial_1.together,
      E: row.partial_1.publicationPoints,
      F: cpoints_p1,
      G: row.partial_2.year,
      H: row.partial_2.together,
      I: row.partial_2.publicationPoints,
      J: cpoints_p2,
    };
  };

  const handleBudgetExport = async () => {
    const ws = utils.json_to_sheet(
      [
        {
          A: "Katedra",
          B: "Body celkovo",
          C: "Rok",
          D: "Body za rok",
          E: "Za publikacie",
          F: "Za citacie",
          G: "Rok",
          H: "Body za rok",
          I: "Za publikacie",
          J: "Za citacie",
        },
      ],
      { skipHeader: true }
    );
    utils.sheet_add_json(
      ws,
      data.map((row) => {
        return getRowBudgetObject(row);
      }),
      { skipHeader: true, origin: `A${2}` }
    );
    ws["!cols"] = [
      { width: 10 },
      { width: 13 },
      { width: 10 },
      { width: 10 },
      { width: 12 },
      { width: 30 },
      { width: 10 },
      { width: 12 },
      { width: 10 },
      { width: 30 },
    ];
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "rozpocet");
    writeFile(wb, `rozpocet-2023.xlsx`);
  };

  const fetchData = useCallback(async () => {
    const response = await api.post("/user/getBudgetByYear", {
      year: year,
    });
    setData(response.data);

    const arr = response.data.map((item) => {
      return {
        department: item.department,
        totalPoint: item.totalPoint,
        totalPoints1: item.partial_1.together,
        publication1: item.partial_1.publicationPoints,
        citation1: item.partial_1.citationPointCount,
        totalPoints2: item.partial_2.together,
        publication2: item.partial_2.publicationPoints,
        citation2: item.partial_2.citationPointCount,
      };
    });
    setTableData(arr);
    setLoading(false);
  }, [setData, setLoading, year]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  let partialYear = data.length ? data[0].partial_2.year : "";
  const settings = {
    rows: tableData,
    loading: loading,
    dense: true,
    colOrder: {
      order: "asc",
      orderBy: "totalPoint",
    },
    headCells: [
      {
        id: "department",
        numeric: false,
        label: "Katedra",
        search: true,
      },
      {
        id: "totalPoint",
        numeric: true,
        label: "Body celkovo",
        search: false,
      },
      {
        id: "totalPoints2",
        numeric: true,
        label: `Spolu ${partialYear}`,
        search: false,
      },
      {
        id: "publication2",
        numeric: true,
        label: `Publikácie ${partialYear}`,
        search: false,
      },
      {
        id: "citation2",
        numeric: true,
        label: `Citácie ${partialYear}`,
        search: false,
      },
      {
        id: "totalPoints1",
        numeric: true,
        label: `Spolu ${partialYear + 1}`,
        search: false,
      },
      {
        id: "publication1",
        numeric: true,
        label: `Publikácie ${partialYear + 1}`,
        search: false,
      },
      {
        id: "citation1",
        numeric: true,
        label: `Citácie ${partialYear + 1}`,
        search: false,
      },
    ],
  };

  return (
    <Container maxWidth="lg">
      <Card>
        <Box pt={2} pl={2} display="flex">
          <FormControl>
            <InputLabel id="simple-select-label">Rok</InputLabel>
            <Select
              labelId="simple-select-label"
              value={year}
              label="Rok"
              onChange={handleChange}
            >
              {years.length > 0 ? (
                years.map((d, i) => (
                  <MenuItem key={i} value={d}>
                    {d}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={year}>{year}</MenuItem>
              )}
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
            <Button variant="contained" onClick={handleBudgetExport}>
              Exportovať
            </Button>
          </Box>
        </Box>
        <Table settings={settings} />
      </Card>
    </Container>
  );
}
