import React, { useCallback, useEffect } from "react";

import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
  IconButton,
  Alert,
  Button,
} from "@mui/material";
import { publicationPlanBaseTypes } from "constants/planConstants";
import api from "../../api/api";
import { formatError } from "helpers";
import CloseIcon from "@mui/icons-material/Close";

const AddPlanItemFormular = ({
  authData,
  setSelectedAuthors,
  selectedAuthors,
  selectedJournal,
  setSelectedJournal,
  values,
}) => {
  const develTest = false;
  const [error, setError] = React.useState(null);

  const [pubType, setPubType] = React.useState(
    !develTest ? "" : publicationPlanBaseTypes[0]
  );
  const [authors, setAuthors] = React.useState([]);
  const [criterias, setCriterias] = React.useState([]);
  const [journals, setJournals] = React.useState([]);
  const [commonValues, setCommonValues] = React.useState({
    epcaKod: develTest ? "V3" : "",
    quartile: develTest ? "Q3" : "",
    isCurrentContent: develTest,
    isWOS: develTest,
    isScopus: develTest,
    isForeighnLanguage: true,
    title: develTest ? "Novel testing suite" : "",
    note: "",
    pointsExpectation: develTest ? 150 : 0,
    sourceId: "",
    conferenceName: "",
  });

  const addAuthor = (author) => {
    for (const sa of selectedAuthors) {
      if (sa._id === author._id) {
        return;
      }
    }
    author.proportion = 0;
    author.authorId = author._id;
    setSelectedAuthors((prevState) => {
      return [...prevState, author];
    });
  };

  const loadAuthors = useCallback(async () => {
    try {
      const response = await api.get("/user/authors");
      let authors = response.data.map((item) => {
        item.label = `${item.name} ${item.lastName} - ${item.orgId}`;
        return item;
      });
      setAuthors(authors);
    } catch (error) {
      setError(formatError(error));
    }
  }, []);

  const loadCriteria = useCallback(async () => {
    try {
      const response = await api.get("/user/criteria");

      setCriterias(response.data);
    } catch (error) {
      setError(formatError(error));
    }
  }, []);

  const loadJournals = useCallback(async () => {
    const response = await api.get("/user/journal");
    response.data = response.data.map((data) => {
      if (data.quartils.length > 0) {
        data.lastQuartil = data.quartils[data.quartils.length - 1].q;
        data.quartils = data.quartils
          .map((d) => (d = d.year + " " + d.q))
          .join(", ");
      } else {
        data.quartils = "";
        data.lastQuartil = "";
      }
      return data;
    });
    setJournals(
      response.data.map((item) => {
        return {
          ...item,
          label: `${item.sourceName}-${item.sourceIdentification}`,
        };
      })
    );
    if (develTest) {
      setSelectedJournal({
        ...response.data[0],
        label: `${response.data[0].sourceName}-${response.data[0].sourceIdentification}`,
      });
    }
  }, [develTest, setSelectedJournal]);

  const setCheckables = (value) => {
    handleCommonChange(value, "isCurrentContent");
    handleCommonChange(value, "isWOS");
    handleCommonChange(value, "isScopus");
  };
  const calcPoints = (
    epcaKod,
    quartil,
    isWOS,
    isScopus,
    isForeighnLanguage
  ) => {
    let pointsExpectation = 0;
    for (const crit of criterias) {
      if (crit.epcaKod !== epcaKod) continue;
      if (crit.quartil !== "undefined") {
        if (quartil === crit.quartil) {
          pointsExpectation = crit.points;
          break;
        }
      } else {
        if (
          crit.epcaKod === epcaKod &&
          crit.isWOS === isWOS &&
          crit.isScopus === isScopus &&
          crit.isForeighnLanguage === isForeighnLanguage
        ) {
          pointsExpectation = crit.points;
          break;
        }
      }
    }
    handleCommonChange(pointsExpectation, "pointsExpectation");
  };

  const handleCommonChange = (value, key) => {
    setCommonValues((prevState) => {
      return { ...prevState, [key]: value };
    });
  };

  useEffect(() => {
    loadAuthors();
    loadJournals();
    loadCriteria();
  }, [loadAuthors, loadJournals, loadCriteria]);

  useEffect(() => {
    if (Object.keys(values).length > 0) {
      if (values.epcaKod === "V3") {
        setPubType(publicationPlanBaseTypes[0]);
      } else if (values.epcaKod === "V2") {
        setPubType(publicationPlanBaseTypes[1]);
      } else {
        setPubType(publicationPlanBaseTypes[2]);
      }
      setCommonValues({
        epcaKod: values.epcaKod,
        quartile: values.quartile,
        isCurrentContent: values.isCurrentContent,
        isWOS: values.isWOS,
        isScopus: values.isScopus,
        isForeighnLanguage: values.isForeighnLanguage,
        title: values.title,
        note: values.note,
        pointsExpectation: values.pointsExpectation,
        sourceId: values.sourceId,
        conferenceName: values.conferenceName,
      });
      if (values.sourceId) {
        for (const jou of journals) {
          if (jou._id.toString() === values.sourceId._id.toString()) {
            setSelectedJournal({
              ...jou[0],
              label: `${jou.sourceName}-${jou.sourceIdentification}`,
            });
            break;
          }
        }
      }
    }
  }, [values, journals, setSelectedJournal]);

  let restContent = null;
  let checkboxRow = (
    <Stack direction={"row"}>
      {publicationPlanBaseTypes[1] !== pubType && (
        <FormControlLabel
          control={
            <Checkbox
              name="isCurrentContent"
              checked={commonValues.isCurrentContent}
              onChange={(event) => {
                handleCommonChange(event.target.checked, "isCurrentContent");
              }}
            />
          }
          label="Current content"
        />
      )}
      <FormControlLabel
        control={
          <Checkbox
            name="isWOS"
            checked={commonValues.isWOS}
            onChange={(event) => {
              handleCommonChange(event.target.checked, "isWOS");
              calcPoints(
                commonValues.epcaKod,
                "",
                event.target.checked,
                commonValues.isScopus,
                commonValues.isForeighnLanguage
              );
            }}
          />
        }
        label="WOS"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="isScopus"
            checked={commonValues.isScopus}
            onChange={(event) => {
              handleCommonChange(event.target.checked, "isScopus");
              calcPoints(
                commonValues.epcaKod,
                "",
                commonValues.isWOS,
                event.target.checked,
                commonValues.isForeighnLanguage
              );
            }}
          />
        }
        label="Scopus"
      />
      <FormControlLabel
        control={
          <Checkbox
            name="isForeighnLanguage"
            checked={commonValues.isForeighnLanguage}
            onChange={(event) => {
              handleCommonChange(event.target.checked, "isForeighnLanguage");
              calcPoints(
                commonValues.epcaKod,
                commonValues.quartile,
                commonValues.isWOS,
                commonValues.isScopus,
                event.target.checked
              );
            }}
          />
        }
        label="Zahraničný jazyk"
      />
    </Stack>
  );
  if (publicationPlanBaseTypes[0] === pubType) {
    restContent = (
      <React.Fragment>
        <Autocomplete
          disablePortal
          id="journal"
          options={journals}
          sx={{ mt: 1 }}
          fullWidth
          value={selectedJournal}
          onChange={(event, newValue) => {
            setSelectedJournal(newValue);
            if (newValue) {
              handleCommonChange(newValue.lastQuartil, "quartile");
              setCheckables(newValue.lastQuartil !== "");
              calcPoints(
                commonValues.epcaKod,
                newValue.lastQuartil,
                commonValues.isWOS,
                commonValues.isScopus,
                commonValues.isForeighnLanguage
              );
            }
          }}
          renderInput={(params) => (
            <TextField
              id="journal"
              required
              {...params}
              label="Vyber časopis"
            />
          )}
        />
      </React.Fragment>
    );
  }
  if (publicationPlanBaseTypes[1] === pubType) {
    restContent = (
      <React.Fragment>
        <TextField
          name="conferenceName"
          label="Názov konferencie"
          fullWidth
          required
          value={commonValues.conferenceName}
          onChange={(event) => {
            handleCommonChange(event.target.value, "conferenceName");
          }}
        />
      </React.Fragment>
    );
  }
  let commonContent = null;
  if (pubType !== "") {
    //
    commonContent = (
      <React.Fragment>
        <Stack direction={"row"} spacing={2}>
          <TextField
            name="epcaKod"
            label="EPCA kód"
            fullWidth
            InputProps={{
              readOnly: publicationPlanBaseTypes[2] !== pubType,
            }}
            //disabled={publicationPlanBaseTypes[2] !== pubType}
            value={commonValues.epcaKod}
            onChange={(event) => {
              handleCommonChange(event.target.value, "epcaKod");
            }}
          />
          {publicationPlanBaseTypes[0] === pubType && (
            <TextField
              name="quartile"
              label="Kvartil časopisu"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              value={commonValues.quartile}
            />
          )}
        </Stack>
        {checkboxRow}
        <TextField
          name="title"
          label="Názov publikácie"
          fullWidth
          required
          value={commonValues.title}
          onChange={(event) => {
            handleCommonChange(event.target.value, "title");
          }}
        />

        <TextField
          name="note"
          label="Poznámka"
          fullWidth
          value={commonValues.note}
          onChange={(event) => {
            handleCommonChange(event.target.value, "note");
          }}
        />
        <TextField
          name="pointsExpectation"
          label="Očakávaný počet bodov"
          fullWidth
          InputProps={{
            readOnly: publicationPlanBaseTypes[2] !== pubType,
          }}
          value={commonValues.pointsExpectation}
          onChange={(event) => {
            handleCommonChange(event.target.value, "pointsExpectation");
          }}
        />
        <Stack direction={"row"} spacing={1}>
          <Autocomplete
            options={authors}
            fullWidth
            value={null}
            onChange={(event, newValue) => {
              if (newValue) {
                addAuthor(newValue);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Pridaj autora" />
            )}
          />
          <Button
            variant="contained"
            onClick={() => {
              const proportion = parseInt(100 / selectedAuthors.length);
              setSelectedAuthors((prevState) => {
                return prevState.map((item, index) => {
                  let innerProportion = proportion;
                  if (
                    selectedAuthors.length * proportion < 100 &&
                    index === 0
                  ) {
                    innerProportion =
                      100 - selectedAuthors.length * proportion + proportion;
                  }
                  return {
                    ...item,
                    proportion: innerProportion,
                  };
                });
              });
            }}
          >
            Priraď podiel rovnomerne
          </Button>
        </Stack>

        {selectedAuthors.map((item, index) => {
          return (
            <Stack
              key={index}
              direction="row"
              spacing={2}
              sx={{ marginTop: 2 }}
              alignItems="center"
            >
              <Typography sx={{ width: "100%", paddingTop: 1 }} noWrap>
                {item.label}
              </Typography>
              <TextField
                name="proportion"
                label="Podiel"
                size="small"
                sx={{ margin: "0 !important" }}
                variant="standard"
                value={item.proportion}
                margin="dense"
                onChange={(event) => {
                  setError(null);
                  const val = parseInt(event.target.value);
                  if (isNaN(val) || val < 0 || val > 100) {
                    setError("Podiel musí byť čislo od 0 do 100");
                    return;
                  }
                  item.proportion = event.target.value;
                  setSelectedAuthors([...selectedAuthors]);
                }}
                fullWidth
              />
              <IconButton
                onClick={() => {
                  setSelectedAuthors(
                    selectedAuthors.filter((sa) => sa.label !== item.label)
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          );
        })}
        {error ? (
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        ) : null}
      </React.Fragment>
    );
  }

  return (
    <Stack spacing={1}>
      <TextField
        autoFocus
        margin="normal"
        id="pubBaseType"
        name="pubBaseType"
        label="Vyber typ publikácie"
        required
        value={pubType}
        onChange={(event) => {
          setPubType(event.target.value);
          let epcaKod = "";
          if (publicationPlanBaseTypes[0] === event.target.value) {
            loadJournals();
            epcaKod = "V3";
          }
          if (publicationPlanBaseTypes[1] === event.target.value) {
            epcaKod = "V2";
          }
          handleCommonChange(epcaKod, "epcaKod");
          setCheckables(false);
          calcPoints(
            epcaKod,
            "",
            commonValues.isWOS,
            commonValues.isScopus,
            commonValues.isForeighnLanguage
          );
          if (authData.authorId) {
            let findAuthor = authors.find((a) => a._id === authData.authorId);
            if (findAuthor) {
              addAuthor(findAuthor);
            }
          }
        }}
        select
      >
        {publicationPlanBaseTypes.map((item) => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </TextField>
      {restContent}
      {commonContent}
    </Stack>
  );
};

export default AddPlanItemFormular;
