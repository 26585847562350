import { useState } from "react";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const DialogPubInfo = (data) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const renderTable = (data) => {
    return (
      <MuiTable size="small" aria-label="simple table">
        <TableBody>
          {Object.keys(data).map((key, i) => (
            <TableRow key={i}>
              <TableCell align="left">{key}</TableCell>
              <TableCell align="left">
                {["string", "number", "boolean"].includes(typeof data[key])
                  ? String(data[key])
                  : renderTable(data[key])}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    );
  };

  return (
    <>
      <Tooltip title="Podrobné informácie">
        <IconButton variant="outlined" onClick={handleClick}>
          <InfoIcon />
        </IconButton>
      </Tooltip>
      <Dialog onClose={handleClick} open={open} maxWidth="md">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {renderTable(data.props)}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogPubInfo;
