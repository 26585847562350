import React from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts";

const ChartBarComponent = ({ chartData, barData }) => {
  let bars = barData.map((item, index) => {
    return (
      <Bar key={index} dataKey={item.value} name={item.name} fill={item.color}>
        {item.showLabelList === true && (
          <LabelList dataKey={item.value} position="top" />
        )}
      </Bar>
    );
  });
  let content = (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={200}
        data={chartData}
        margin={{
          top: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {bars}
      </BarChart>
    </ResponsiveContainer>
  );
  if (chartData.length === 0) {
    content = <></>;
  }
  return content;
};

export default ChartBarComponent;
