import React from "react";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { formatError, personCategories } from "../../helpers";
import api from "../../api/api";

const EditUserData = ({ values, onEdit }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await api.put("/user", {
        name: `${event.target.surname.value} ${event.target.name.value}`,
        personCategory: event.target.personCategory.value,
      });
      if (response.status === 200) {
        if (onEdit) {
          onEdit();
        }
      }
      handleClose();
    } catch (error) {
      alert(formatError(error));
    }
  };
  const nameSplits = values.name.split(" ");
  let name = "";
  let surname = "";
  if (nameSplits.length > 0) {
    surname = nameSplits[0];
  }
  if (nameSplits.length > 1) {
    name = nameSplits[1];
  }

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Uprav údaje
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <DialogTitle>Formulár úpravy údajov</DialogTitle>
            <DialogContent component="form" onSubmit={handleSubmit}>
              <TextField
                sx={{ mt: 2 }}
                variant="outlined"
                label="Meno"
                name="name"
                id="name"
                defaultValue={name}
                fullWidth
                required
              />
              <TextField
                sx={{ mt: 2 }}
                variant="outlined"
                label="Priezvisko"
                name="surname"
                id="surname"
                defaultValue={surname}
                fullWidth
                required
              />
              <TextField
                autoFocus
                margin="normal"
                id="personCategory"
                name="personCategory"
                label="Kategória"
                fullWidth
                defaultValue={values.personCategory || 0}
                select
              >
                {personCategories.map((item) => {
                  return (
                    <MenuItem key={item.level} value={item.level}>
                      {item.text}
                    </MenuItem>
                  );
                })}
              </TextField>
              {error && (
                <Alert sx={{ m: 1, mb: 0, width: "100%" }} severity="error">
                  {error}
                </Alert>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="error">
                Zruš
              </Button>
              <Button type="submit">Potvrď</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditUserData;
