import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import FormularInput from "./FormularInput";
import api from "../../api/api";
import { formatError } from "../../helpers";

const AddInput = ({ values, onAdd }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const updateData = {
        type: event.target.type.value,
        base: parseInt(event.target.base.value),
        coefficient: parseFloat(event.target.coefficient.value),
        description: event.target.description.value,
        example: event.target.example.value,
      };
      const response = await api.post("/dekan/activity", updateData);
      if (response.status === 200) {
        handleClose();
        onAdd(500);
      }
    } catch (error) {
      alert(formatError(error));
    }
  };

  return (
    <React.Fragment>
      <Button sx={{ m: 1 }} variant="contained" onClick={handleClickOpen}>
        Pridaj šablónu aktivity
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Pridaj šablónu aktivity</DialogTitle>
          <DialogContent>
            <FormularInput values={values} error={error} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default AddInput;
