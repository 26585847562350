import React from "react";

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const ClaimAuthor = ({ authors, user, onConfirm }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedAuthor, setSelectedAuthor] = React.useState("");
  const handleClickOpen = () => {
    setOpen(true);
    setSelectedAuthor("");
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (onConfirm) onConfirm(selectedAuthor);
    handleClose();
  };

  return (
    <React.Fragment>
      <Tooltip title="Vytvor spojenie">
        <IconButton aria-label="uprav" size="small" onClick={handleClickOpen}>
          <AddCircleIcon fontSize="medium" color="primary" />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogTitle>Vyberte autora</DialogTitle>
          <DialogContent>
            <Autocomplete
              sx={{ mt: 2 }}
              id="simple-select-label"
              options={authors}
              value={selectedAuthor}
              onChange={(event, newValue) => {
                setSelectedAuthor(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  id="selectedAuthor"
                  sx={{ width: 250 }}
                  required
                  {...params}
                  label="Vyberte autora"
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button onClick={handleSubmit}>Potvrď</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ClaimAuthor;
