import {
  Box,
  Card,
  Chip,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import api from "api/api";
import MUIDataTable from "mui-datatables";
import { defaultTableOptions } from "constants/tableTexts";
import SetForcedPoints from "./SetForcedPoints";

const PuHandler = ({ authData }) => {
  const [publications, setPublications] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "epcaKod",
      label: "Kategória publikácie",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "epcaRok",
      label: "Kategória publikácie",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "authorsList",
      label: "Autori",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "title",
      label: "Názov",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "subTitle",
      label: "Pod názov",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "points",
      label: "Body pre katedru",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const data = publications[dataIndex];
          return (
            <Chip
              sx={{ minWidth: 50 }}
              label={data.points}
              color={data.isForced ? "error" : undefined}
            />
          );
        },
      },
    },
  ];
  if (authData.isScienceManager) {
    columns.push({
      name: "btns",
      label: "Akcie",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const data = publications[dataIndex];
          return <SetForcedPoints onEdit={load} recordId={data._id} />;
        },
      },
    });
  }

  const load = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get("user/publicationD1");
      setPublications(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  }, [setPublications, setLoading]);

  useEffect(() => {
    load();
  }, [load]);
  return (
    <Card>
      <Grid py={1} px={1} container spacing={1}>
        <Grid item>
          <Typography mr={2} variant="h5" sx={{ mt: 1 }}>
            Patenty a úžitokové vzory
          </Typography>
        </Grid>
      </Grid>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <MUIDataTable
        data={publications}
        columns={columns}
        options={defaultTableOptions({
          sortOrder: {
            name: "title",
            direction: "desc",
          },
          //   onDownload: (buildHead, buildBody, columns, data) => {
          //     exportExcel(columns, data);
          //     return false;
          //   },
        })}
      />
    </Card>
  );
};

export default PuHandler;
