import { Box, Typography } from "@mui/material";
import { extText1, extText2, extText3 } from "./TextConstants";

const TutorialExtAuthor = () => {
  const fontSize = "1.2rem";
  return (
    <Box sx={{ backgroundColor: "white", p: 2, borderRadius: "8px" }}>
      <Typography variant="h3" textAlign={"center"}>
        Návod na nastavenie podielu externého autora (vyžaduje/nevyžaduje) v
        systéme MAVE
      </Typography>
      <Typography fontSize={fontSize} mt={1} textAlign={"justify"}>
        {extText1}
      </Typography>
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Box
          component="img"
          src="/assets/images/ext1.png"
          sx={{ width: "55%", height: "auto" }}
        />
      </Box>
      <Typography fontSize={fontSize} mt={1} textAlign={"justify"}>
        {extText2}
      </Typography>
      <Box sx={{ textAlign: "center", my: 2 }}>
        <Box
          component="img"
          src="/assets/images/ext2.png"
          sx={{ width: "96%", height: "auto" }}
        />
      </Box>
      <Typography fontSize={fontSize} mt={1} textAlign={"justify"}>
        {extText3}
      </Typography>{" "}
    </Box>
  );
};

export default TutorialExtAuthor;
