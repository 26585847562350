import {
  Card,
  CardContent,
  CardHeader,
  Alert,
  Box,
  CircularProgress,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import api from "api/api";
import { calcAuthorDepPointShare } from "util/excelExportUtil";
import ChartBarComponent from "components/scienceOutput/ChartBarComponent";

const ScienceChart = ({ userData }) => {
  const [loading, setLoading] = useState(false);
  const [gdata, setGdata] = useState([]);

  const barData = [
    {
      value: "pub",
      name: "Publikácie",
      color: "#8884d8",
      showLabelList: false,
    },
    { value: "cit", name: "Citácie", color: "#82ca9d", showLabelList: false },
    { value: "sum", name: "Spolu", color: "#F1416C", showLabelList: false },
  ];

  let cardContent = (
    <Alert variant="filled" severity="warning">
      Nemáte spárovaný svoj účet s autorom z knižnice. Pre spárovanie
      kontaktujte katedrového koordinátora.
    </Alert>
  );
  if (userData.authorId) {
    cardContent = <ChartBarComponent chartData={gdata} barData={barData} />;
  }
  if (loading) {
    cardContent = (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={48} />
      </Box>
    );
  }

  const load = useCallback(async () => {
    let author = null;
    let authors = await api.get("/user/authors");

    if (userData.authorId) {
      author = authors.data.find((a) => a._id === userData.authorId);
    }

    if (author) {
      const response = await api.get(
        "/user/publication/userReport/" + author._id
      );

      for (const pub of response.data) {
        calcAuthorDepPointShare(pub);
      }

      const points = {
        pub: 0,
        cit: 0,
      };
      const tmpGData = [];
      const year = new Date().getFullYear();
      for (let i = year - 4; i <= year; i++) {
        tmpGData.push({ name: i, pub: 0, cit: 0, sum: 0 });
      }
      for (const p of response.data) {
        for (const g of tmpGData) {
          if (g.name === p.epcaRok) {
            g.pub += p.authorPoints;
          }
          let cits = p.citations.filter((c) => c.year === g.name);
          for (const c of cits) {
            const pointCitShare = c.points * p.authorProportion;
            g.cit += pointCitShare;
            if (c.year === year) {
              points.cit += pointCitShare;
              c.myPub = p.customAuthorsList + " " + p.title;
              c.pointShare = pointCitShare;
            }
          }
        }
      }
      for (const g of tmpGData) {
        g.sum = g.pub + g.cit;
      }
      setGdata(tmpGData);

      setLoading(false);
    }
    setLoading(false);
  }, [userData.authorId, setLoading]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      load();
    }, 100);
  }, [load]);

  return (
    <Card sx={{ maxHeight: 400 }}>
      <CardHeader title="Výkony za vedu" />
      <CardContent sx={{ height: 200 }}>{cardContent}</CardContent>
    </Card>
  );
};

export default ScienceChart;
