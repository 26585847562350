import { TextField, Alert, Checkbox, FormControlLabel } from "@mui/material";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import deLocale from "date-fns/locale/de";
import React from "react";

const FormularOutputMultiEdit = ({ error }) => {
  const [approved, setApproved] = React.useState(false);
  const [outputDate, setOutputDate] = React.useState(null);

  return (
    <React.Fragment>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={deLocale}
      >
        <DatePicker
          label="Dátum"
          value={outputDate}
          onChange={(newValue) => {
            setOutputDate(newValue);
          }}
          renderInput={(params) => (
            <TextField id="outputDate" {...params} sx={{ mt: 2 }} fullWidth />
          )}
        />
      </LocalizationProvider>
      <TextField
        margin="normal"
        id="note"
        name="note"
        label="Poznámka"
        fullWidth
        defaultValue={""}
      />
      <TextField
        margin="normal"
        id="menDay"
        name="menDay"
        label="Človeko/deň (pracovný deň)"
        inputProps={{ step: "0.1" }}
        fullWidth
      />
      <TextField
        margin="normal"
        id="menDayWeekend"
        name="menDayWeekend"
        label="Človeko/deň (víkendový deň)"
        type="number"
        inputProps={{ step: "0.1" }}
        fullWidth
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={approved}
            onChange={(value) => {
              setApproved(value.target.checked);
            }}
          />
        }
        label="Schválené *"
        id="approved"
        name="approved"
        labelPlacement="start"
        sx={{ justifyContent: "space-between", display: "flex" }}
      />

      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
    </React.Fragment>
  );
};

export default FormularOutputMultiEdit;
