import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import Table from "../../components/universal/Table";
import HistoryIcon from "@mui/icons-material/History";
import { formatDate } from "../../helpers";

const ShowOutputHistory = ({ values }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const translateChangeType = (type) => {
    const changes = [
      { type: "create", value: "Vytvorenie" },
      { type: "edit", value: "Úprava" },
    ];
    for (const c of changes) {
      if (c.type === type) {
        return c.value;
      }
    }

    return "";
  };

  const head = [
    {
      id: "createdAt",
      numeric: false,
      label: "Dátum zmeny",
      render: (data) => new Date(data.createdAt).toLocaleString("sk"),
    },
    {
      id: "type",
      numeric: false,
      label: "Typ zmeny",
      render: (data) => translateChangeType(data.type),
    },
    {
      id: "name",
      numeric: false,
      label: "Osoba",
    },
    {
      id: "note",
      numeric: false,
      label: "Poznámka",
    },
    {
      id: "outputDate",
      numeric: false,
      label: "Dátum výkonu",
      render: (data) => formatDate(data.outputDate),
    },
    {
      id: "menDay",
      numeric: false,
      label: "Človeko/deň (víkendový deň)",
      render: (data) => {
        return `${data.menDay} (${data.menDayWeekend})`;
      },
    },

    {
      id: "approved",
      numeric: false,
      label: "Schválené",
      search: false,
      render: (data) => {
        const text = data.approved ? "Áno" : "Nie";
        const color = data.approved ? "green" : "red";
        return (
          <Box sx={{ bgcolor: color, borderRadius: 2 }}>
            <Typography color="white" align="center">
              {text}
            </Typography>
          </Box>
        );
      },
    },
  ];
  const settings = {
    rows: values,
    loading: false,
    colOrder: {
      order: "asc",
      orderBy: "createdAt",
    },
    dense: true,
    headCells: head,
    tableId: "history",
  };

  return (
    <div>
      <Tooltip title={"Zobraz históriu výkonu"}>
        <span>
          <IconButton aria-label="uprav" size="small" onClick={handleClickOpen}>
            <HistoryIcon fontSize="small" color={"primary"} />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth={"xl"}>
        <DialogTitle>História zmien výkonu</DialogTitle>
        <DialogContent sx={{ minHeight: 300 }}>
          <Table settings={settings} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Zavri okno
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShowOutputHistory;
