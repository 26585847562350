import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import api from "../../api/api";
import { formatError } from "../../helpers";
import FormularItem from "./FormularItem";

const AddItem = ({ values = {}, onAdd }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("title", event.target.title.value);
      formData.append("price", parseFloat(event.target.price.value));
      formData.append("note", event.target.note.value);
      if (event.target.image.files.length) {
        formData.append("image", event.target.image.files[0]);
      }
      const response = await api.post("/fakulta/warehouse", formData);
      if (response.status === 200) {
        handleClose();
        onAdd(500);
      }
    } catch (error) {
      setError(formatError(error));
    }
  };

  return (
    <React.Fragment>
      <Button sx={{ m: 1 }} variant="contained" onClick={handleClickOpen}>
        Pridaj položku
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Pridaj položku</DialogTitle>
          <DialogContent>
            <FormularItem values={values} error={error} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default AddItem;
