import React, { useEffect } from "react";
import TableHead from "./TableHead";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  LinearProgress,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

function descendingComparator(a, b, orderBy, headCell) {
  if (headCell.numeric) {
    return parseFloat(a[orderBy]) > parseFloat(b[orderBy]) ? 1 : -1;
  }
  return String(b[orderBy]).localeCompare(String(a[orderBy]));
}

function getComparator(order, orderBy, headCell) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, headCell)
    : (a, b) => -descendingComparator(a, b, orderBy, headCell);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  table: {
    overflow: "hidden",
  },
  rowclick: {
    cursor: "pointer",
  },
}));

export default function Table({
  settings,
  callbackExtraText,
  callbackChangeMultiSelect,
  counterSelected,
  showPagination,
  ...rest
}) {
  const { headCells, loading, rows, colOrder, tableId } = settings;
  const rowsPerPageInitial = [15, 50, 200];
  const classes = useStyles();
  const [order, setOrder] = React.useState(colOrder.order);
  const [orderBy, setOrderBy] = React.useState(colOrder.orderBy);
  const [page, setPage] = React.useState(0);
  const [filter, setFilter] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(
    settings.rowsPerPage ? settings.rowsPerPage : rowsPerPageInitial[1]
  );
  const [rowsRendered, setRowsRendered] = React.useState([]);

  const handleRequestSort = (event, property, tableId) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    if (tableId) {
      let colsLocal = JSON.parse(localStorage.getItem("colsLocal"));
      if (!colsLocal) {
        colsLocal = {};
      }
      let addNewRecord = true;
      for (const key in colsLocal) {
        if (key === tableId) {
          colsLocal[key].order = isAsc ? "desc" : "asc";
          colsLocal[key].orderBy = property;
          addNewRecord = false;
        }
      }
      if (addNewRecord) {
        colsLocal[tableId] = {
          order: isAsc ? "desc" : "asc",
          orderBy: property,
        };
      }
      localStorage.setItem("colsLocal", JSON.stringify(colsLocal));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterChange = (filter) => {
    let normalizedFilter = {};
    for (const key in filter) {
      normalizedFilter[key] = filter[key]
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    }
    setFilter(normalizedFilter);
  };

  useEffect(() => {
    const search = () => {
      let tmpRows = [];
      for (const row of rows) {
        let find = true;
        for (const value in filter) {
          if (
            String(row[value])
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .indexOf(filter[value]) === -1
          ) {
            find = false;
            break;
          }
        }
        if (find) {
          tmpRows.push(row);
        }
      }
      setRowsRendered(tmpRows);
    };

    const colsLocal = JSON.parse(localStorage.getItem("colsLocal"));
    for (const key in colsLocal) {
      if (key === tableId) {
        setOrder(colsLocal[key].order);
        setOrderBy(colsLocal[key].orderBy);
      }
    }

    search(rows);
  }, [rows, filter, tableId]);

  return (
    <div className={classes.root}>
      {loading && <LinearProgress style={{ marginBottom: "1rem" }} />}
      <Stack sx={{ display: "flex", flexDirection: "row" }}>
        {callbackExtraText && (
          <Typography variant="h6" sx={{ pt: 1, pl: 1 }}>
            {callbackExtraText(rowsRendered)}
          </Typography>
        )}
        {callbackChangeMultiSelect && (
          <Button
            sx={{ ml: 1, mt: 0.5 }}
            onClick={() => {
              callbackChangeMultiSelect(rowsRendered);
            }}
          >
            {counterSelected > 0 ? "Zruš označenia" : "Označ všetky"}
          </Button>
        )}
      </Stack>
      {showPagination !== false && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageInitial}
          component="div"
          count={rowsRendered.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <TableContainer>
        <MuiTable
          className={classes.table}
          size={"small"}
          aria-label="enhanced table"
        >
          <TableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            filterChange={filterChange}
            filter={filter}
            setFilter={setFilter}
            tableId={tableId}
          />
          <TableBody>
            {stableSort(
              rowsRendered,
              getComparator(
                order,
                orderBy,
                headCells.find((v) => v.id === orderBy)
              )
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={index}>
                    {headCells.map((cell, index) => {
                      return cell.hide ? null : (
                        <TableCell
                          key={index}
                          align={cell.numeric ? "right" : "left"}
                        >
                          {cell.render ? cell.render(row) : row[cell.id]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {showPagination !== false && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageInitial}
          component="div"
          count={rowsRendered.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}
