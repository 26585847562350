import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import UserActivity from "components/adminPage/UserActivity";
import SystemLogs from "components/adminPage/SystemLogs";
import ThirdPartyRequests from "components/adminPage/ThirdPartyRequests";
import UnderIndexedPublications from "components/adminPage/UnderIndexedPublications";
import AuthorHandler from "components/adminPage/AuthorHandler";

const AdminPage = () => {
  const [value, setValue] = useState("0");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      title: "Aktivita používateľov",
      element: <UserActivity />,
    },
    {
      title: "Správa autorov knižnice",
      element: <AuthorHandler />,
    },
    {
      title: "Systémove logy",
      element: <SystemLogs />,
    },
    {
      title: "Požiadavky na externé systémy",
      element: <ThirdPartyRequests />,
    },
    {
      title: "Nezaindexované články",
      element: <UnderIndexedPublications />,
    },
  ];

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            centered
            onChange={handleChange}
            aria-label="lab API tabs example"
            //variant="scrollable"
          >
            {tabs.map((o, i) => (
              <Tab
                key={i}
                label={o.title}
                value={String(i)}
                sx={{
                  fontSize: 13,
                  padding: 0.9,
                }}
              />
            ))}
          </TabList>
        </Box>
        {tabs.map((o, i) => (
          <TabPanel key={i} value={String(i)}>
            {o.element}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default AdminPage;
