import React from "react";
import { Navigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import ResetPasswordFinalScreen from "../pages/ResetPasswordFinalScreen";
import ResetPasswordScreen from "../pages/ResetPasswordScreen";
import Signin from "../pages/Signin";
import SignUp from "../pages/Signup";

const publicRoutes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <Signin /> },
      { path: "/signup", element: <SignUp /> },
      { path: "/reset-password", element: <ResetPasswordScreen /> },
      {
        path: "/reset-password/:email/:hash",
        element: <ResetPasswordFinalScreen />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace={true} />,
      },
    ],
  },
];

export default publicRoutes;
