import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { formatError } from "../../helpers";

import api from "../../api/api";
import FormularOutputMultiEdit from "./FormularOutputMultiEdit";

const EditOutputMulti = ({ values, onEdit, name, isEnabled }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        approved: event.target.approved.checked,
      };
      if (data.approved) {
        data.approvedBy = name;
      }
      if (event.target.menDay.value !== "") {
        data.menDay = event.target.menDay.value;
      }
      if (event.target.menDayWeekend.value !== "") {
        data.menDayWeekend = event.target.menDayWeekend.value;
      }
      if (event.target.note.value !== "") {
        data.note = event.target.note.value;
      }
      if (event.target.outputDate.value !== "") {
        data.outputDate = event.target.outputDate.value;
      }
      const url = "/katedra/output/";
      for (const row of values) {
        if (row.selected) {
          await api.put(url + row._id, data);
        }
      }
      handleClose();
      onEdit(500);
    } catch (error) {
      setError(formatError(error));
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Uprav výkony hromadne">
        <span>
          <IconButton
            disabled={!isEnabled}
            aria-label="uprav"
            size="small"
            onClick={handleClickOpen}
          >
            <EditIcon
              fontSize="medium"
              color={isEnabled ? "primary" : "#EBEBE4"}
            />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Uprav výkony hromadne</DialogTitle>
          <DialogContent>
            <FormularOutputMultiEdit error={error} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default EditOutputMulti;
