import React, { useCallback, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";

import api from "../../api/api";
import { formatError } from "../../helpers";
import { useSelector } from "react-redux";
import FormularOutputMulti from "./FormularOutputMulti";

const AddOutputMulti = ({ values, onAdd, showIcon = false }) => {
  const { authorizationLevel, department, name } = useSelector((state) => {
    return state.auth;
  });

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [completeOptionsUser, setCompleteOptionsUser] = useState([]);
  const [completeOptionsEvent, setCompleteOptionsEvent] = useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);

  const handleClickOpen = () => {
    getUsers();
    getEvents();
    setSelectedUsers([]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedUsers.length) {
      setError("Vyberte aspoň jedného zamestnanca");
      return;
    }
    try {
      let eventId = undefined;

      for (const c of completeOptionsEvent) {
        if (c.title === event.target.eventId.value) {
          eventId = c._id;
          break;
        }
      }
      for (const s of selectedUsers) {
        const data = {
          eventId,
          note: event.target.note.value,
          menDay: event.target.useSameManDay.checked
            ? event.target.menDay.value
            : s.menDay,
          menDayWeekend: event.target.useSameManDay.checked
            ? event.target.menDayWeekend.value
            : s.menDayWeekend,
          outputDate: event.target.outputDate.value,
          approved:
            authorizationLevel < 2 ? false : event.target.approved.checked,
        };
        if (data.approved) {
          data.approvedBy = name;
        }

        const url = "/katedra/output/" + s._id;
        await api.post(url, data);
      }
      handleClose();
      onAdd(500);
    } catch (error) {
      setError(formatError(error));
    }
  };

  const getUsers = useCallback(async () => {
    try {
      let url = "";
      if (authorizationLevel === 1) {
        url = "/katedra/users/" + department;
      } else if (authorizationLevel > 1) {
        url = "/fakulta/users";
      }
      if (url !== "") {
        const response = await api.get(url);
        setCompleteOptionsUser(
          response.data.map((r) => {
            return {
              ...r,
              label: `${r.name}`,
            };
          })
        );
      }
    } catch (error) {
      alert(formatError(error));
    }
  }, [authorizationLevel, department]);

  const getEvents = useCallback(async () => {
    try {
      let url = "/user/events";
      if (authorizationLevel > 1) {
        url = "/fakulta/event";
      }
      const response = await api.get(url);
      const validEvents = response.data.filter((e) => {
        return new Date(e.eventDate) < new Date() || authorizationLevel > 1;
      });
      setCompleteOptionsEvent(
        validEvents.map((r) => {
          return {
            ...r,
            label: `${r.title}`,
          };
        })
      );
    } catch (error) {
      alert(formatError(error));
    }
  }, [authorizationLevel]);

  return (
    <React.Fragment>
      {showIcon ? (
        <Tooltip title="Pridaj viacero výkonov">
          <IconButton aria-label="uprav" size="small" onClick={handleClickOpen}>
            <DynamicFeedIcon fontSize="medium" color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          sx={{ m: 1 }}
          fullWidth
          variant="contained"
          onClick={handleClickOpen}
        >
          Pridaj viacero výkonov
        </Button>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Pridaj viacero výkonov</DialogTitle>
          <DialogContent>
            <FormularOutputMulti
              values={values}
              error={error}
              completeOptionsUser={completeOptionsUser}
              completeOptionsEvent={completeOptionsEvent}
              role={authorizationLevel}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default AddOutputMulti;
