import React from "react";

import { TextField, MenuItem, Alert } from "@mui/material";
import { activityBase } from "../../helpers";

const FormularInput = ({ values = {}, error }) => {
  return (
    <React.Fragment>
      <TextField
        autoFocus
        required
        margin="normal"
        id="base"
        name="base"
        label="Zaradenie aktivity"
        fullWidth
        defaultValue={values.base || 1}
        select
      >
        {activityBase.map((item) => {
          return (
            <MenuItem key={item.level} value={item.level}>
              {item.text}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        margin="normal"
        required
        id="type"
        name="type"
        label="Typ aktivity"
        fullWidth
        defaultValue={values.type || ""}
      />
      <TextField
        margin="normal"
        required
        id="example"
        name="example"
        label="Príklady aktivity"
        fullWidth
        defaultValue={values.example || ""}
      />
      <TextField
        margin="normal"
        required
        id="coefficient"
        name="coefficient"
        label="Koeficient aktivity"
        type="number"
        fullWidth
        defaultValue={values.coefficient || 1}
      />
      <TextField
        margin="normal"
        id="description"
        name="description"
        label="Opis koeficientu"
        fullWidth
        defaultValue={values.description || ""}
      />
      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
    </React.Fragment>
  );
};

export default FormularInput;
