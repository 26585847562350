import React from "react";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import api from "../../api/api";
import { formatError } from "../../helpers";
import ComponentCategoryStack from "./ComponentCategoryStack";

const ManageCategories = ({ values = {}, catId, onAdd, showIcon }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await api.post(`/fakulta/warehouse/${catId}/category`, {
        category: event.target.category.value,
      });
      if (response.status === 200) {
        event.target.category.value = "";
        onAdd(500);
      }
    } catch (error) {
      setError(formatError(error));
    }
  };
  const handleEditSubmit = async (oldValue, newValue) => {
    try {
      const response = await api.put(`/fakulta/warehouse/${catId}/category`, {
        oldValue,
        newValue,
      });
      if (response.status === 200) {
        onAdd(500);
      }
    } catch (error) {
      setError(formatError(error));
    }
  };
  const handleDeleteSubmit = async (oldVal) => {};

  return (
    <React.Fragment>
      {showIcon ? (
        <Tooltip title="Pridaj kategóriu">
          <IconButton aria-label="uprav" size="small" onClick={handleClickOpen}>
            <AddCircleIcon fontSize="medium" color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button sx={{ m: 1 }} variant="contained" onClick={handleClickOpen}>
          Spravuj kategórie
        </Button>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Spravuj kategórie</DialogTitle>
        <DialogContent>
          <Stack
            component="form"
            direction="row"
            onSubmit={handleSubmit}
            mb={2}
          >
            {/* <Typography>Pridaj kategóriu</Typography> */}
            <TextField
              margin="normal"
              required
              id="category"
              name="category"
              label="Pridaj kategorie"
              fullWidth
            />
            <Button type="submit">Pridaj</Button>
          </Stack>
          {values.map((item, index) => {
            return (
              <ComponentCategoryStack
                key={index}
                values={item}
                onSubmit={handleEditSubmit}
                onDelete={handleDeleteSubmit}
              />
            );
          })}
          {error && (
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Zavri
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ManageCategories;
