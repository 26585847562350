import {
  Box,
  Card,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import api from "api/api";
import { defaultTableOptions } from "constants/tableTexts";
import MUIDataTable from "mui-datatables";
import React, { useCallback, useEffect, useState } from "react";
import DialogEditAuthor from "./DialogEditAuthor";

const AuthorHandler = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "name",
      label: "Meno",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "lastName",
      label: "Priezvisko",
      options: {
        filter: false,
        sort: true,
        setCellProps: (value) => {
          return {
            style: { minWidth: 150 },
          };
        },
      },
    },
    {
      name: "affiliation",
      label: "Katedra",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "orgId",
      label: "Opac ID autora",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "btns",
      label: "Akcie",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const data = records[dataIndex];
          return (
            <Stack direction="row">
              <DialogEditAuthor data={data} onEdit={load} />
            </Stack>
          );
        },
      },
    },
  ];

  const load = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get("/admin/opacAuthors");
      setRecords(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Card>
      <Grid py={1} px={1} container spacing={1}>
        <Grid item>
          <Typography mr={2} variant="h5" sx={{ mt: 1 }}>
            Správa autorov knižnice
          </Typography>
        </Grid>
      </Grid>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <MUIDataTable
        data={records}
        columns={columns}
        options={defaultTableOptions({
          //   sortOrder: {
          //     name: "affiliation",
          //     direction: "desc",
          //   },
        })}
      />
    </Card>
  );
};

export default AuthorHandler;
