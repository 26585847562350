import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";

const MovePlanPublication = ({ title, secondaryText, onConfirm }) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    onConfirm();
    handleClose();
  };

  return (
    <React.Fragment>
      <Tooltip title="Presuň publikáciu do nasledovného obdobia">
        <IconButton aria-label="uprav" size="small" onClick={handleClickOpen}>
          <DriveFileMoveIcon fontSize="medium" color="primary" />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{secondaryText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button onClick={handleSubmit}>Potvrď</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default MovePlanPublication;
