import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import FormularInput from "./FormularInput";
import api from "../../api/api";
import { formatError } from "../../helpers";

const EditInput = ({ values, onEdit }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const updateData = {
        type: event.target.type.value,
        base: parseInt(event.target.base.value),
        coefficient: parseFloat(event.target.coefficient.value),
        description: event.target.description.value,
        example: event.target.example.value,
      };
      const response = await api.put(
        "/dekan/activity/" + values._id,
        updateData
      );
      if (response.status === 200) {
        handleClose();
        onEdit(500);
      }
    } catch (error) {
      alert(formatError(error));
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Uprav">
        <IconButton aria-label="uprav" size="small" onClick={handleClickOpen}>
          <EditIcon fontSize="medium" color="primary" />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Uprav aktivitu</DialogTitle>
          <DialogContent>
            <FormularInput values={values} error={error} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default EditInput;
