import React, { useState } from "react";
import { Card, Container, Stack, Typography } from "@mui/material";

import api from "../api/api";
import { formatError } from "../helpers";
import SingUpFormular from "../components/manageUsers/SingUpFormular";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";

const SignUp = () => {
  const [error, setError] = React.useState(null);
  const [disableUsernameCheck, setDisableUsernameCheck] = useState(false);
  const [showSwitch, setShowSwitch] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    try {
      if (!disableUsernameCheck) {
        await api.post("/public/checkUsername", {
          name: event.target.name.value,
          surname: event.target.surname.value,
        });
      }

      let response = await api.post("/public/signup", {
        email: event.target.email.value,
        password: event.target.password.value,
        password_repeat: event.target.password_repeat.value,
        department: event.target.department.value,
        name: `${event.target.surname.value} ${event.target.name.value}`,
      });
      alert(
        `Účet bol úspešne vytvorený pre email: ${response.data.email}. Účet nie je ešte aktivovaný. Pre aktivovanie kontaktujte administrátora systému (Peter Brída, Slavomír Matúška).`
      );
      navigate("/sigin");
    } catch (err) {
      setError(formatError(err));
      if (err.response.status === 409) {
        setShowSwitch(true);
      } else {
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };
  return (
    <Container component="main" maxWidth="sm">
      <Card sx={{ mt: "10%", mb: "20%" }}>
        {showSwitch && (
          <Stack
            sx={{ px: 3, pt: 1 }}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography>Preskočiť kontrolu mena a vytvoriť účet</Typography>
            <Checkbox
              checked={disableUsernameCheck}
              onChange={(event) => {
                setDisableUsernameCheck(event.target.checked);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Stack>
        )}
        <SingUpFormular handleSubmit={handleSubmit} error={error} />
      </Card>
    </Container>
  );
};

export default SignUp;
