import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  Button,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { translateAuthLevel, translatePersonCategoty } from "../../helpers";
import { useNavigate } from "react-router-dom";

const UserInfo = ({ userData }) => {
  const navigate = useNavigate();
  return (
    //sx={{ minWidth: 350 }}
    <Card sx={{ height: "100%" }}>
      <CardHeader
        avatar={<AccountCircleIcon color="primary" sx={{ fontSize: 40 }} />}
        title={userData.email}
        subheader={`${userData.department} - ${translateAuthLevel(
          userData.authorizationLevel
        )}`}
        action={
          <Button
            onClick={() => {
              navigate("/user/" + userData._id);
            }}
          >
            Uprav
          </Button>
        }
      />
      <CardContent>
        <Typography>{userData.name}</Typography>
        <Typography>
          Kategória: {translatePersonCategoty(userData.personCategory)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default UserInfo;
