import React, { useCallback, useState } from "react";
import api from "../../api/api";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from "@mui/material";
import { formatDate, formatError, translateActivityBase } from "../../helpers";
import Table from "./Table";

const ClosedEventList = () => {
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    load();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const load = useCallback(async () => {
    setLoading(true);
    try {
      const url = "/user/closedEvents";
      const response = await api.get(url);
      setRows(response.data);
    } catch (error) {
      alert(formatError(error));
    }
    setLoading(false);
  }, []);

  const settings = {
    rows: rows,
    loading: loading,
    colOrder: {
      order: "desc",
      orderBy: "eventDate",
    },
    tableId: "closedEventListTable",
    headCells: [
      {
        id: "isOpen",
        numeric: false,
        label: "Stav aktivity",
        render: (data) => {
          let bgColor = "green";
          const now = new Date();
          if (new Date(data.eventDate) < now) {
            bgColor = "orange";
          }
          if (!data.isOpen) {
            bgColor = "red";
          }
          return (
            <Box
              sx={{
                borderRadius: 4,
                width: 25,
                height: 25,
                bgcolor: bgColor,
              }}
            ></Box>
          );
        },
      },
      {
        id: "title",
        numeric: false,
        label: "Názov aktivity",
        search: true,
      },
      {
        id: "eventDate",
        numeric: false,
        label: "Dátum začiatok",
        render: (data) => {
          return formatDate(data.eventDate);
        },
      },
      {
        id: "eventEndDate",
        numeric: false,
        label: "Dátum koniec",
        render: (data) => {
          return formatDate(data.eventEndDate);
        },
      },
      {
        id: "outputDeadline",
        numeric: false,
        label: "Uzávierka výkonov",
        render: (data) => {
          return formatDate(data.outputDeadline);
        },
      },
      {
        id: "base",
        numeric: false,
        label: "Zaradenie aktivity",
        search: true,
        render: (data) => {
          return translateActivityBase(data.base);
        },
      },
      {
        id: "type",
        numeric: false,
        label: "Typ aktivity",
      },
      {
        id: "coefficient",
        numeric: true,
        label: "Koeficient",
      },
    ],
  };

  return (
    <div>
      <Button sx={{ m: 1 }} variant="contained" onClick={handleClickOpen}>
        Zobraz uzatvorené aktivity
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle>Uzatvorené aktivity</DialogTitle>
        <DialogContent>
          <Table settings={settings} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Zavri
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClosedEventList;
