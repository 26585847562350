import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  formatError,
  translateAuthLevel,
  translatePersonCategoty,
} from "../helpers";
import api from "../api/api";
import EditUserData from "../components/universal/EditUserData";
import { authAction } from "../store/auth-slice";

const UserEditScreen = () => {
  const authData = useSelector((state) => {
    return state.auth;
  });
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [errorPwd, setErrorPwd] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCurrentUser = useCallback(async () => {
    try {
      const response = await api.get("/user/current");
      dispatch(authAction.setUserData(response.data));
    } catch (err) {
      alert(formatError(err));
    }
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorPwd(null);
    try {
      const response = await api.post("/user/change-password", {
        password: event.target.newPassword.value,
        password_repeat: event.target.newPasswordRepeat.value,
      });
      if (response.status === 200) {
        alert(response.data.message);
      }
    } catch (error) {
      setErrorPwd(formatError(error));
    }
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  //alignSelf: "center",
  // Heslo1234
  const dataChangeElement = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Card sx={{ mt: 2, maxWidth: 400, p: 2 }}>
        <CardHeader title="Podrobnosti užívateľa" />
        <CardContent>
          <Typography variant="h6">Meno: {authData.name}</Typography>
          <Typography variant="h6">Email: {authData.email}</Typography>
          <Typography variant="h6">
            Úroveň účtu: {translateAuthLevel(authData.authorizationLevel)}
          </Typography>
          <Typography variant="h6">
            Zaradenie (katedra): {authData.department}
          </Typography>
          <Typography variant="h6">
            Kategória: {translatePersonCategoty(authData.personCategory)}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <EditUserData values={authData} onEdit={getCurrentUser} />
        </CardActions>
      </Card>
    </Box>
  );
  const passwordChangeElement = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Card sx={{ mt: 2, maxWidth: 400 }}>
        <CardHeader title="Formular zmeny hesla" />
        <CardContent component="form" onSubmit={handleSubmit}>
          <TextField
            sx={{ mt: 2 }}
            required
            autoComplete="new-password"
            type="password"
            id="newPassword"
            name="newPassword"
            variant="outlined"
            label="Nove heslo"
            fullWidth
          />
          <TextField
            sx={{ mt: 2 }}
            required
            autoComplete="new-password"
            inputProps={{
              autoComplete: "new-password",
            }}
            type="password"
            id="newPasswordRepeat"
            name="newPasswordRepeat"
            variant="outlined"
            label="Nove heslo - overenie"
            fullWidth
          />
          {errorPwd && (
            <Alert variant="filled" severity="error" sx={{ mt: 2 }}>
              {errorPwd}
            </Alert>
          )}
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained">
              Potvrd zmenu
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Box>
  );

  // const AuthorSettingElement = () => {
  //   const [errorAuthor, setErrorAuthor] = React.useState(null);
  //   const [authors, setAuthors] = React.useState([]);
  //   const [author, setAuthor] = React.useState(null);

  //   useEffect(() => {
  //     const init = async () => {
  //       try {
  //         const response = await api.get("/user/authors");
  //         let authors = response.data.map((item) => {
  //           item.label = `${item.name} ${item.lastName} - ${item.orgId}`;
  //           return item;
  //         });
  //         setAuthors(authors);

  //         let author = authors.find((a) => a._id === authData.authorId);
  //         if (author) {
  //           setAuthor(author.label);
  //         }
  //       } catch (error) {
  //         alert("Chyba: ", error.message);
  //       }
  //     };
  //     init();
  //   }, []);

  //   const handleSubmitAuthor = async (event) => {
  //     event.preventDefault();
  //     let author = authors.find((a) => a.label === event.target.authorId.value);
  //     try {
  //       const response = await api.put("/user", {
  //         authorId: author._id,
  //       });
  //       getCurrentUser();
  //       if (response.status === 200) {
  //         alert(response.data.message);
  //       }
  //     } catch (error) {
  //       setErrorAuthor(formatError(error));
  //     }
  //   };

  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         flexDirection: "column",
  //       }}
  //     >
  //       <Card sx={{ mt: 2, width: "100%", maxWidth: 400 }}>
  //         <CardHeader title="Nastavenie autora" />
  //         <CardContent component="form" onSubmit={handleSubmitAuthor}>
  //           <Autocomplete
  //             disablePortal
  //             id="authorId"
  //             options={authors}
  //             fullWidth
  //             value={author}
  //             onChange={(event, newValue) => setAuthor(newValue)}
  //             renderInput={(params) => (
  //               <TextField required {...params} label="Vyber autora" />
  //             )}
  //           />
  //           {errorAuthor && (
  //             <Alert variant="filled" severity="error" sx={{ mt: 2 }}>
  //               {errorAuthor}
  //             </Alert>
  //           )}
  //           <CardActions sx={{ justifyContent: "flex-end" }}>
  //             <Button type="submit" variant="contained">
  //               Potvrd zmenu
  //             </Button>
  //           </CardActions>
  //         </CardContent>
  //       </Card>
  //     </Box>
  //   );
  // };
  const tabPages = (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="Nastavenia" {...a11yProps(0)} />
        <Tab label="Zmena hesla" {...a11yProps(1)} />
        {/* <Tab label="Nastavenie autora" {...a11yProps(2)} /> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        {dataChangeElement}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {passwordChangeElement}
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <AuthorSettingElement />
      </TabPanel> */}
    </Box>
  );

  const topPanelDirection = useMediaQuery((theme) => theme.breakpoints.up("md"))
    ? "row"
    : "column";
  if (topPanelDirection) {
  }

  return (
    <Box sx={{ mt: 2, flexDirection: "column" }}>
      <Typography sx={{ mb: 2 }} variant="h4">
        Nastavenia používateľa
      </Typography>
      {tabPages}
    </Box>
  );
};

export default UserEditScreen;
