import {
  Card,
  CardContent,
  CardHeader,
  Box,
  CircularProgress,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import api from "api/api";
import ChartBarComponent from "components/scienceOutput/ChartBarComponent";

const MrktOutputChart = () => {
  const [loading, setLoading] = useState(true);
  const [gdata, setGdata] = useState([]);

  const barData = [
    {
      value: "sum",
      name: "Spolu [človeko dni]",
      color: "#F1416C",
      showLabelList: false,
    },
  ];

  let cardContent = <ChartBarComponent chartData={gdata} barData={barData} />;

  if (loading) {
    cardContent = (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress size={48} />
      </Box>
    );
  }

  const load = useCallback(async () => {
    const response = await api.get("/user/output");
    const tmpGData = [];

    const year = new Date().getFullYear();
    for (let i = year - 4; i <= year; i++) {
      tmpGData.push({ name: i, sum: 0 });
    }
    for (const p of response.data) {
      for (const g of tmpGData) {
        if (g.name === new Date(p.outputDate).getFullYear()) {
          g.sum += +p.output;
        }
      }
    }
    setGdata(tmpGData);
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    setTimeout(() => {
      load();
    }, 100);
  }, [load]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title="Výkony z marketingových aktivít" />
      <CardContent sx={{ height: 200 }}>{cardContent}</CardContent>
    </Card>
  );
};

export default MrktOutputChart;
