import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { utils, writeFile } from "xlsx";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import deLocale from "date-fns/locale/de";
import { translatePersonCategoty } from "helpers";

const OutputsExportDialog = ({ sourceData }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [exportStartDate, setExportStartDate] = React.useState(
    new Date(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000))
  );
  const [exportEndDate, setExportEndDate] = React.useState(new Date());
  const [useOnlyApproved, setUseOnlyApproved] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    if (exportStartDate > exportEndDate) {
      setError("Neplatný rozsah dátumov");
      return;
    }
    const validData = [];
    const activities = [];
    const usersData = [];
    for (const record of sourceData) {
      if (useOnlyApproved) {
        if (!record.approved) {
          continue;
        }
      }
      if (
        new Date(record.outputDate) >= exportStartDate &&
        new Date(record.outputDate) <= exportEndDate
      ) {
        validData.push(record);
        let addUser = true;
        for (const user of usersData) {
          if (user.name === record.name) {
            addUser = false;
            break;
          }
        }
        if (addUser) {
          usersData.push({
            name: record.name,
            department: record.department,
            personCategory: translatePersonCategoty(record.personCategory),
            outputs: [],
          });
        }
        let addActivity = true;
        for (const activity of activities) {
          if (activity.title === record.title) {
            addActivity = false;
            break;
          }
        }
        if (addActivity) {
          activities.push({
            coefficient: record.coefficient,
            type: record.type,
            title: record.title,
          });
        }
      }
    }
    activities.sort((a, b) => {
      if (a.type > b.type) {
        return 1;
      } else if (a.type < b.type) {
        return -1;
      } else {
        return a.coefficient > b.coefficient ? 1 : -1;
      }
    });
    usersData.sort((a, b) => (b.name > a.name ? -1 : 1));
    for (const user of usersData) {
      user.outputs = Array(activities.length).fill(0);
    }

    for (const vd of validData) {
      for (let i = 0; i < activities.length; i++) {
        if (activities[i].title === vd.title) {
          for (const user of usersData) {
            if (user.name === vd.name) {
              user.outputs[i] += parseFloat(vd.output);
            }
          }
        }
      }
    }

    exportFile(usersData, activities);
  };

  const exportFile = (data, activities) => {
    const ws1 = utils.json_to_sheet(
      [
        {
          A: `Výkony za marketing za obdobie od ${exportStartDate.toLocaleDateString(
            "de"
          )} do ${exportEndDate.toLocaleDateString("de")}`,
        },
      ],
      {
        skipHeader: true,
        origin: "D1",
      }
    );
    const userStarts = 7;

    const numbers = [];
    for (let i = 0; i < activities.length; i++) {
      numbers.push(i + 1);
    }
    numbers.push("Spolu");

    utils.sheet_add_json(ws1, [{ A: "Aktivita" }], {
      skipHeader: true,
      origin: "A3",
    });
    utils.sheet_add_json(ws1, [{ A: "Koeficient" }], {
      skipHeader: true,
      origin: "A4",
    });
    utils.sheet_add_json(ws1, [{ A: "Meno", B: "Katedra", C: "Úroveň" }], {
      skipHeader: true,
      origin: "A6",
    });

    utils.sheet_add_json(ws1, [{ ...numbers }], {
      skipHeader: true,
      origin: "D3",
    });
    utils.sheet_add_json(ws1, [{ ...new Array(activities.length).fill(1) }], {
      skipHeader: true,
      origin: "D4",
    });

    utils.sheet_add_json(
      ws1,
      data.map((item) => {
        return {
          A: item.name,
          B: item.department,
          C: item.personCategory,
        };
      }),
      {
        skipHeader: true,
        origin: "A" + userStarts,
      }
    );
    utils.sheet_add_json(
      ws1,
      data.map((item) => {
        const formulas = [];
        for (let i = 0; i < item.outputs.length; i++) {
          formulas.push({
            t: "n",
            f: `=${utils.encode_col(i + 3)}4*${item.outputs[i]}`,
          });
        }
        return {
          ...formulas,
        };
      }),
      {
        skipHeader: true,
        origin: "D" + userStarts,
      }
    );

    utils.sheet_add_json(
      ws1,
      data.map((item, index) => {
        return {
          a: {
            t: "n",
            f: `=SUM(D${userStarts + index}:${utils.encode_col(
              activities.length + 2
            )}${userStarts + index})`,
          },
        };
      }),
      {
        skipHeader: true,
        origin: `${utils.encode_col(activities.length + 3)}${userStarts}`,
      }
    );
    const sums = numbers.map((item, index) => {
      return {
        t: "n",
        f: `=sum(${utils.encode_col(3 + index)}${userStarts}:${utils.encode_col(
          3 + index
        )}${userStarts + data.length - 1})`,
      };
    });

    utils.sheet_add_json(ws1, [{ ...sums }], {
      skipHeader: true,
      origin: `D${userStarts + data.length}`,
    });

    utils.sheet_add_json(
      ws1,
      activities.map((a, index) => {
        return {
          A: index + 1,
          B: a.coefficient,
          C: a.title,
          C1: "",
          C2: "",
          C3: "",
          C4: "",
          D: a.type,
        };
      }),
      {
        skipHeader: true,
        origin: `A${userStarts + data.length + 3}`,
      }
    );

    ws1["!cols"] = [
      { width: 20 },
      { width: 8 },
      { width: 13 },
      ...numbers.map((item) => {
        return {
          width: 5,
        };
      }),
    ];

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws1, "vykony");
    writeFile(wb, `vykony_mrkt_feit.xlsx`);
  };

  return (
    <div>
      <Button
        sx={{ m: 1 }}
        variant="contained"
        color="secondary"
        fullWidth
        onClick={handleClickOpen}
      >
        Exportuj výkony
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <form onSubmit={handleSubmit}>
          <DialogTitle sx={{ pl: matches ? 10 : 4, pr: matches ? 10 : 4 }}>
            Export výkonov
          </DialogTitle>
          <DialogContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useOnlyApproved}
                  onChange={(event) => {
                    setUseOnlyApproved(event.target.checked);
                  }}
                />
              }
              label="Exportuj len schválené výkony"
            />

            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={deLocale}
            >
              <DatePicker
                label="Výkony od"
                value={exportStartDate}
                onChange={(newValue) => {
                  setExportStartDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    id="exportStartDate"
                    {...params}
                    sx={{ mt: 2 }}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={deLocale}
            >
              <DatePicker
                label="Výkony do"
                value={exportEndDate}
                onChange={(newValue) => {
                  setExportEndDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    id="exportStartDate"
                    {...params}
                    sx={{ mt: 2, mb: 2 }}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
            {error && (
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default OutputsExportDialog;
