import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Stack,
  Typography,
  Card,
  Box,
  Tooltip,
  IconButton,
  CardMedia,
} from "@mui/material";
import Table from "../components/universal/Table";
import api from "../api/api";
import { formatDate, formatError } from "../helpers";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteItemDialog from "../components/universal/DeleteItemDialog";
import AddBalance from "../components/warehouse/AddBalance";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ManageCategories from "../components/warehouse/ManageCategories";
import EditBalance from "../components/warehouse/EditBalance";

const WarehouseItemScreen = () => {
  const [rows, setRows] = useState({
    balance: [],
    categories: [],
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const eventId = location.pathname.split("/").pop();

  const hasProperty = (obj, prop) => {
    for (const key in obj) {
      if (key === prop || key === "undefined") {
        return true;
      }
    }
    return false;
  };

  const calcBalance = (rowsData) => {
    const items = {};
    for (const row of rowsData) {
      for (const c of row.count) {
        if (hasProperty(items, c.category)) {
          items[c.category] += c.count;
        } else {
          items[c.category] = c.count;
        }
      }
    }
    let sum = 0;
    let res =
      rows.title + ", " + rows.note + " - aktuálny zostatok na sklade: ";
    for (const key in items) {
      if (key === "undefined") {
        res += items[key];
      } else {
        res += key + ": " + items[key] + ", ";
        sum += items[key];
      }
    }
    if (sum) {
      res += " spolu: " + sum;
    }
    return res;
  };

  const onDeleteHandler = async (warehouseId, balanceId, reload = true) => {
    try {
      const url = "/fakulta/warehouse/";
      const response = await api.delete(
        url + warehouseId + "/balance/" + balanceId
      );
      if (response.status === 200) {
        reload && load(200);
      } else {
        alert("Chyba pri mazani položky.", response.status);
      }
    } catch (error) {
      alert(formatError(error));
    }
  };

  const load = useCallback(
    async (delay) => {
      setLoading(true);
      setTimeout(async () => {
        try {
          const response = await api.get("/fakulta/warehouse/" + eventId);

          setRows(response.data);
        } catch (err) {
          alert(formatError(err));
        }
        setLoading(false);
      }, delay || 10);
    },
    [eventId]
  );

  const settings = {
    rows: rows.balance,
    loading: loading,
    colOrder: {
      order: "desc",
      orderBy: "date",
    },
    headCells: [
      {
        id: "note",
        numeric: false,
        label: "Poznámka",
        search: true,
      },
      {
        id: "date",
        numeric: false,
        label: "Dátum",
        render: (data) => {
          return formatDate(data.date);
        },
      },
    ],
  };
  if (rows.categories.length > 0) {
    for (const cat of rows.categories) {
      settings.headCells.push({
        id: `count${cat}`,
        numeric: false,
        label: `Pohyb - ${cat}`,
        render: (data) => {
          for (const d of data.count) {
            if (d.category === cat) {
              return d.count;
            }
          }
          return 0;
        },
      });
    }
  } else {
    settings.headCells.push({
      id: "count",
      numeric: false,
      label: "Pohyb",
      render: (data) => {
        return data.count[0].count;
      },
    });
  }

  settings.headCells.push({
    id: "buttons",
    numeric: false,
    label: "Akcie",
    render: (data) => {
      return (
        <Stack direction="row" alignItems="center">
          <EditBalance
            onEdit={load}
            values={{
              categories: rows.categories,
              data,
              warehouseId: rows._id,
              deleteHandler: onDeleteHandler,
            }}
          />
          <DeleteItemDialog
            title="Potvrdenie zmazanie pohybu."
            secondaryText={`pohyb s poznámkou: ${data.note}`}
            onDelete={() => {
              onDeleteHandler(rows._id, data._id);
            }}
          />
        </Stack>
      );
    },
  });

  useEffect(() => {
    load();
  }, [load]);

  const headerText = "Detail položky";
  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item>
          <Tooltip title="Naspäť">
            <IconButton
              aria-label="uprav"
              size="small"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIosIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Typography variant="h4">{headerText}</Typography>
        </Grid>
        <Grid item>
          <AddBalance onAdd={load} showIcon={false} values={rows} />
        </Grid>
        <Grid item>
          <ManageCategories
            onAdd={load}
            showIcon={false}
            values={rows.categories}
            catId={rows._id}
          />
        </Grid>
        <Grid item>
          <Card sx={{ maxWidth: 345 }}>
            <CardMedia
              component="img"
              width={200}
              // height={200}
              src={`data:image/png;base64, ${rows.imageData}`}
              alt=""
            />
          </Card>
        </Grid>
      </Grid>
      <Card sx={{ mt: 1 }}>
        <Table settings={settings} callbackExtraText={calcBalance} />
      </Card>
    </Box>
  );
};

export default WarehouseItemScreen;
