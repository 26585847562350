import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Autocomplete,
  TextField,
  Alert,
} from "@mui/material";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import api from "../../api/api";
import { useSelector } from "react-redux";
import { formatError, getUrl } from "../../helpers";

const MoveOutput = ({
  values,
  onEdit,
  multiEdit = false,
  isEnabled = true,
}) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [completeOptionsEvent, setCompleteOptionsEvent] = React.useState([]);
  const { authorizationLevel } = useSelector((state) => {
    return state.auth;
  });

  const handleClickOpen = async () => {
    try {
      let url = "/user/events";
      if (authorizationLevel > 1) {
        url = getUrl("/fakulta/event");
      }
      const response = await api.get(url);
      const validEvents = response.data.filter((e) => {
        return new Date(e.eventDate) < new Date() || authorizationLevel > 1;
      });
      setCompleteOptionsEvent(validEvents);
    } catch (error) {
      alert(formatError(error));
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    let eventId = "";
    for (const e of completeOptionsEvent) {
      if (e.title === event.target.eventId.value) {
        eventId = e._id;
      }
    }
    if (eventId !== "") {
      try {
        const url = "/katedra/moveOutput/";
        if (multiEdit) {
          for (const row of values) {
            if (row.selected) {
              await api.put(url + row._id, { eventId });
            }
          }
        } else {
          await api.put(url + values._id, { eventId });
        }
      } catch (error) {
        alert(formatError(error));
      }
      onEdit();
      handleClose();
    } else {
      setError("Aktivita neexistuje: " + event.target.eventId.value);
    }
  };

  return (
    <div>
      <Tooltip title={multiEdit ? "Preraď výkony hromadne" : "Preraď výkon"}>
        <span>
          <IconButton
            disabled={!isEnabled}
            aria-label="uprav"
            size="small"
            onClick={handleClickOpen}
          >
            <DriveFileMoveIcon
              fontSize="small"
              color={isEnabled ? "primary" : "#EBEBE4"}
            />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Preradenie výkonu do inej aktivity</DialogTitle>
          <DialogContent sx={{ minHeight: 300 }}>
            <Autocomplete
              disablePortal
              id="eventId"
              options={completeOptionsEvent.map((r) => {
                return {
                  ...r,
                  label: `${r.title}`,
                };
              })}
              fullWidth
              renderInput={(params) => (
                <TextField
                  id="eventId"
                  required
                  {...params}
                  label="Aktivita"
                  margin="normal"
                />
              )}
            />
            {error && (
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default MoveOutput;
