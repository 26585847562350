import {
  TextField,
  Alert,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import deLocale from "date-fns/locale/de";

import React, { useEffect } from "react";

const FormularOutput = ({
  values = {},
  error,
  role = 0,
  completeOptionsUser = [],
  completeOptionsEvent = [],
}) => {
  const [approved, setApproved] = React.useState(false);
  const [event, setEvent] = React.useState(null);
  const [outputDate, setOutputDate] = React.useState(new Date());

  useEffect(() => {
    if (!error) {
      if (values.approved !== undefined) {
        setApproved(values.approved);
      }
      if (values.outputDate !== undefined) {
        setOutputDate(values.outputDate);
      }
      for (const c of completeOptionsEvent) {
        if (c.label === values.title) {
          setEvent(c);
          break;
        }
      }
    }
  }, [
    values.approved,
    values.title,
    completeOptionsEvent,
    values.outputDate,
    error,
  ]);

  return (
    <React.Fragment>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={deLocale}
      >
        <DatePicker
          label="Dátum"
          value={outputDate}
          onChange={(newValue) => {
            setOutputDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              required
              id="outputDate"
              {...params}
              sx={{ mt: 2 }}
              fullWidth
            />
          )}
        />
      </LocalizationProvider>
      {role > 0 && !values._id && (
        <Autocomplete
          disablePortal
          id="user"
          options={completeOptionsUser}
          fullWidth
          renderInput={(params) => (
            <TextField
              id="user"
              required
              {...params}
              label="Pracovník"
              margin="normal"
            />
          )}
        />
      )}
      {!values._id && (
        <Autocomplete
          disablePortal
          id="eventId"
          options={completeOptionsEvent}
          fullWidth
          value={event}
          onChange={(event, newValue) => {
            setEvent(newValue);
          }}
          renderInput={(params) => (
            <TextField
              id="eventId"
              required
              {...params}
              label="Aktivita"
              margin="normal"
            />
          )}
        />
      )}
      <TextField
        margin="normal"
        id="note"
        name="note"
        label="Poznámka"
        fullWidth
        defaultValue={values.note || ""}
      />
      <TextField
        margin="normal"
        required
        id="menDay"
        name="menDay"
        label="Človeko/deň (pracovný deň)"
        inputProps={{ step: "0.1" }}
        fullWidth
        defaultValue={values.menDay || 1}
      />
      <TextField
        margin="normal"
        id="menDayWeekend"
        name="menDayWeekend"
        label="Človeko/deň (víkendový deň)"
        type="number"
        inputProps={{ step: "0.1" }}
        fullWidth
        defaultValue={values.menDayWeekend || 0}
      />
      {role > 1 && (
        <FormControlLabel
          control={
            <Checkbox
              checked={approved}
              onChange={(value) => {
                setApproved(value.target.checked);
              }}
            />
          }
          label="Schválené"
          id="approved"
          name="approved"
          labelPlacement="start"
          sx={{ justifyContent: "space-between", display: "flex" }}
        />
      )}
      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
    </React.Fragment>
  );
};

export default FormularOutput;
