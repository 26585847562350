import {
  Card,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  LinearProgress,
  Stack,
  Grid,
  Switch,
  FormControlLabel,
  Chip,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import api from "api/api";
import DialogPubInfo from "./DialogPubInfo";
import { utils, writeFile } from "xlsx";
import { calcDiffJournals, calcPubTypes } from "util/publicationUtil";
import ChartBarComponent from "./ChartBarComponent";
import { barDataJournal } from "constants/pubChartType";
import { defaultTableOptions } from "constants/tableTexts";
import SetForcedPoints from "./SetForcedPoints";

const FacultyPublication = ({ authData }) => {
  const [publications, setPublications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const currentyear = new Date().getFullYear();
  const [gdata, setGdata] = React.useState([]);
  const [journaldata, setJournalData] = React.useState([]);
  const [showZeroPubs, setShowZeroPubs] = useState(false);
  const years = Array(currentyear - (currentyear - 5))
    .fill("")
    .map((v, idx) => currentyear - idx);
  const handleChange = (event) => {
    setLoading(true);
    setYear(event.target.value);
  };

  const editColumnData = (item) => {
    if (item === "undefined" || typeof item === "undefined") {
      item = " ";
    }
    if (typeof item === "boolean") {
      item = item ? "Áno" : "Nie";
    }
    if (Array.isArray(item)) {
      item = item.length;
    }
    if (typeof item === "number") {
      if (item > 1622505600) {
        item = new Date(item).toLocaleDateString("sk");
      }
    }
    return item;
  };

  const exportExcel = (columns, data) => {
    const rows = [];
    for (const row of data) {
      const obj = {};
      for (let i = 0; i < columns.length; i++) {
        if (columns[i].name === "btns") {
          continue;
        }
        obj[columns[i].label] = editColumnData(row.data[i]);
      }
      rows.push(obj);
    }
    const ws = utils.json_to_sheet(rows);
    const wb = utils.book_new();
    ws["!cols"] = [
      { width: 10 },
      { width: 10 },
      { width: 20 },
      { width: 30 },
      { width: 30 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
    ];
    utils.book_append_sheet(wb, ws, "publikacie");
    writeFile(wb, `feit-publikacie-${new Date().getTime()}.xlsx`);
  };

  const filteredPubs = publications.filter((item) => {
    return showZeroPubs ? true : item.points !== 0;
  });

  const columns = [
    {
      name: "epcaKod",
      label: "Kategória publikácie",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "departments",
      label: "Katedry",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "customAuthorsList",
      label: "Autori",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "title",
      label: "Názov",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "sourceName",
      label: "Zdrojovy dokument",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "quartil",
      label: "Kvartil",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value === "undefined" ? "" : value;
        },
        filterType: "checkbox",
        filterOptions: {
          names: ["Q1", "Q2", "Q3", "Q4"],
          logic(quartil, filterVal) {
            const show = filterVal.indexOf(quartil) >= 0;
            return !show;
          },
        },
      },
    },
    {
      name: "isCurrentContent",
      label: "CCC",
      options: {
        filter: true,
        sort: true,
        filterType: "checkbox",
        filterOptions: {
          names: ["CCC-Ano", "CCC-Nie"],
          logic(isCurrentContent, filterVal) {
            const show =
              (filterVal.indexOf("CCC-Ano") >= 0 && isCurrentContent) ||
              (filterVal.indexOf("CCC-Nie") >= 0 && !isCurrentContent);
            return !show;
          },
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <CheckIcon /> : <ClearIcon />;
        },
      },
    },
    {
      name: "isWOS",
      label: "WOS",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const data = filteredPubs[dataIndex];
          return data.isWOS ? <CheckIcon /> : <ClearIcon />;
        },
      },
    },
    {
      name: "isScopus",
      label: "Scopus",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const data = filteredPubs[dataIndex];
          return data.isScopus ? <CheckIcon /> : <ClearIcon />;
        },
      },
    },
    {
      name: "points",
      label: "Body",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const data = filteredPubs[dataIndex];
          return (
            <Chip
              sx={{ minWidth: 50 }}
              label={data.points}
              color={data.isForced ? "error" : undefined}
            />
          );
        },
      },
    },
    {
      name: "citations",
      label: "Počet citácií",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return value.length;
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            return (
              (obj1.data.length - obj2.data.length) * (order === "asc" ? 1 : -1)
            );
          };
        },
      },
    },
    {
      name: "timestamp",
      label: "Dátum pridania",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return new Date(value).toLocaleDateString("sk");
        },
      },
    },
    {
      name: "record",
      label: "Record",
      options: {
        filter: false,
        sort: false,
        display: false,
        setCellProps: () => ({
          style: {
            minWidth: "300px",
          },
        }),
      },
    },
    {
      name: "btns",
      label: "Akcie",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const data = filteredPubs[dataIndex];
          return (
            <Stack direction="row">
              {authData.isScienceManager && (
                <SetForcedPoints onEdit={load} recordId={data._id} />
              )}
              <DialogPubInfo props={data} />
            </Stack>
          );
        },
      },
    },
  ];

  const load = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.post("/user/getFacultyYearPublicaitons", {
        year: year,
        departmentShortcut: "KMIKT",
      });
      setPublications(
        response.data.publications.map((r) => {
          return {
            ...r,
            record: `${r.customAuthorsList}: ${r.title}, ${r.sourceName}, ${
              r.country
            }, ${r.epcaRok}, ${r.epcaKod},${r.sourceIdentification} ${
              r.quartil !== "undefined" ? ", " + r.quartil : ""
            }`,
          };
        })
      );
      setGdata(calcPubTypes(response.data.publications));
      setJournalData(calcDiffJournals(response.data.publications));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  }, [setPublications, setLoading, year, setGdata]);

  useEffect(() => {
    load();
  }, [load]);
  return (
    <Card>
      <Grid py={1} px={1} container spacing={1}>
        <Grid item>
          <Stack>
            <Typography mr={2} variant="h5" sx={{ mt: 1 }}>
              Publikácie fakulty
            </Typography>
            <FormControlLabel
              sx={{ m: 0, p: 0 }}
              control={
                <Switch
                  checked={showZeroPubs}
                  onChange={(event) => {
                    setShowZeroPubs(event.target.checked);
                  }}
                />
              }
              label="Zobraziť nulové záznamy"
              labelPlacement="start"
            />
          </Stack>
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel id="simple-select-label">Rok</InputLabel>
            <Select
              labelId="simple-select-label"
              value={year}
              label="Rok"
              onChange={handleChange}
            >
              {years.length > 0 ? (
                years.map((d, i) => (
                  <MenuItem key={i} value={d}>
                    {d}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={year}>{year}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} sx={{ minHeight: 200 }}>
          <ChartBarComponent chartData={gdata} barData={barDataJournal} />
        </Grid>
        <Grid item xs={6} sx={{ minHeight: 200 }}>
          <ChartBarComponent
            chartData={journaldata}
            barData={barDataJournal}
            chartWidth={700}
          />
        </Grid>
      </Grid>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <MUIDataTable
        // title={"Employee List"}
        data={filteredPubs}
        columns={columns}
        options={defaultTableOptions({
          sortOrder: {
            name: "points",
            direction: "desc",
          },
          onDownload: (buildHead, buildBody, columns, data) => {
            exportExcel(columns, data);
            return false;
          },
        })}
      />
    </Card>
  );
};

export default FacultyPublication;
