import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
} from "@mui/material";
import api from "api/api";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Table from "components/universal/Table";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { formatError } from "helpers";
import DeleteItemDialog from "components/universal/DeleteItemDialog";
import EditIcon from "@mui/icons-material/Edit";
import { Container } from "@mui/system";

function FormDialog({ dialogProperties, open, onClose, ...rest }) {
  const [error, setError] = useState(null);

  const onSubmit = async (event) => {
    event.preventDefault();

    const data = {
      title: event.target.title.value,
      shortcut: event.target.shortcut.value,
      active: event.target.active.checked,
      affiliation: event.target.affiliation.value.split(","),
      researcherCount: parseFloat(event.target.researcherCount.value),
    };

    try {
      if (dialogProperties.neww) {
        await api.post("/science/department", data);
      } else {
        await api.put(
          "/science/department/" + dialogProperties.values._id,
          data
        );
      }
      onClose();
    } catch (error) {
      setError(formatError(error));
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle>
          {dialogProperties.neww
            ? "Nová katedra"
            : "Editácia " + dialogProperties.values.title}
        </DialogTitle>
        <DialogContent
          sx={{
            "& .MuiFormControl-root": { mt: 1 },
          }}
        >
          {error ? (
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          ) : null}
          <TextField
            autoFocus
            name="title"
            label="Názov"
            defaultValue={dialogProperties.values.title}
            fullWidth
          />
          <TextField
            name="shortcut"
            label="Skratka"
            defaultValue={dialogProperties.values.shortcut}
            fullWidth
          />
          <TextField
            name="affiliation"
            label="Príslušnosť"
            helperText="Jednotlivé hodnoty oddelte čiarkou"
            defaultValue={
              dialogProperties.values.affiliation
                ? dialogProperties.values.affiliation.join(",")
                : null
            }
            fullWidth
          />
          <TextField
            name="researcherCount"
            label="Počet výskumníkov"
            type="text"
            defaultValue={dialogProperties.values.researcherCount}
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                name="active"
                defaultChecked={dialogProperties.values.active}
              />
            }
            label="Aktívny"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="error">
            Zruš
          </Button>
          <Button type="submit">Potvrď</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default function DepartmentList({ authData, ...rest }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogProperties, setDialogProperties] = useState({});
  const [open, setOpen] = React.useState(false);

  const handleDialogClick = (neww = false, values = {}) => {
    if (open) {
      fetchData();
    }
    setOpen(!open);
    if (!open) {
      setDialogProperties({ data: data, neww: neww, values: values });
    } else {
      setDialogProperties({});
    }
  };

  const fetchData = useCallback(async () => {
    const response = await api.get("/user/department");
    setData(response.data);
    setLoading(false);
  }, [setData, setLoading]);

  const onDelete = async (data) => {
    await api.delete("/science/department/" + data._id);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const settings = {
    rows: data,
    loading: loading,
    dense: true,
    colOrder: {
      order: "desc",
      orderBy: "title",
    },
    headCells: [
      {
        id: "active",
        numeric: false,
        label: "Aktívna",
        search: false,
        render: (data) => (data.active ? <CheckIcon /> : <ClearIcon />),
      },
      {
        id: "title",
        numeric: false,
        label: "Názov",
        search: true,
      },
      {
        id: "shortcut",
        numeric: false,
        label: "Skratka",
        search: true,
      },
      {
        id: "researcherCount",
        numeric: false,
        label: "Počet výskumníkov",
      },
      {
        id: "affiliation",
        numeric: false,
        label: "Príslušnoť",
        search: true,
        render: (data) => data.affiliation.join(", "),
      },
      {
        id: "btns",
        numeric: false,
        search: false,
        hide: !(authData.isScienceManager || authData.isAdmin),
        render: (data) => (
          <Stack direction="row">
            <IconButton
              color="primary"
              onClick={() => handleDialogClick(false, data)}
            >
              <EditIcon />
            </IconButton>
            <DeleteItemDialog
              title={"Vymazať katedru " + data.title + "?"}
              onDelete={() => onDelete(data)}
            />
          </Stack>
        ),
      },
    ],
  };

  return (
    <Container maxWidth="lg">
      <Card>
        {authData.isScienceManager || authData.isAdmin ? (
          <Box pt={2} pl={2}>
            <Button variant="contained" onClick={() => handleDialogClick(true)}>
              Pridaj katedru
            </Button>
          </Box>
        ) : null}
        <Table settings={settings} />
        {open ? (
          <FormDialog
            dialogProperties={dialogProperties}
            open={open}
            onClose={handleDialogClick}
          />
        ) : null}
      </Card>
    </Container>
  );
}
