import { MenuItem, TextField } from "@mui/material";
import { publicationStatus } from "constants/planConstants";
import React from "react";

const UpdatePublicationStatusFormular = ({ values = {}, error }) => {
  return (
    <React.Fragment>
      <TextField
        autoFocus
        margin="normal"
        id="publicationState"
        name="publicationState"
        label="Zmeň stav publikácia"
        required
        fullWidth
        defaultValue={values.publicationStatus}
        // value={pubType}
        // onChange={(event) => {
        //     event.target.value
        // }}
        select
      >
        {publicationStatus.map((item) => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </TextField>
    </React.Fragment>
  );
};

export default UpdatePublicationStatusFormular;
