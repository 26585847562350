exports.impacts = ["V3", "ADC", "ADM", "ADN", "ADD"];
exports.monografie = ["AAB", "AAA", "ABA", "ACA", "ACB", "BCI", "BAA"];
exports.conference = ["V2", "AFC", "AFD"];
exports.patenty = ["AGJ", "D1"];

exports.restPubs = [
  "ADF",
  "AFG",
  "AFH",
  "ADE",
  "AEC",
  "AED",
  "FAI",
  "DAI",
  "GII",
];
