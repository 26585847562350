import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import api from "../api/api";
import Table from "../components/universal/Table";
import {
  newVersionAvailable,
  formatDate,
  formatError,
  getUrl,
} from "../helpers";
import { useNavigate } from "react-router-dom";
import SourceIcon from "@mui/icons-material/Source";
import EditOutput from "../components/manageOutputs/EditOutput";
import AddOutput from "../components/manageOutputs/AddOutput";
import DeleteItemDialog from "../components/universal/DeleteItemDialog";
import { useSelector } from "react-redux";
import YearExportDialog from "../components/universal/YearExportDialog";
import YearDetails from "../components/manageOutputs/YearDetails";
import MoveOutput from "../components/manageOutputs/MoveOutput";
import AddOutputMulti from "../components/manageOutputs/AddOutputMulti";
import EditOutputMulti from "../components/manageOutputs/EditOutputMulti";
import ShowOutputHistory from "../components/manageOutputs/ShowOutputHistory";
import ForceRefreshInfo from "../components/universal/ForceRefreshInfo";
import OutputsExportDialog from "../components/universal/OutputsExportDialog";

const ManageOutputsScreen = () => {
  const navigate = useNavigate();
  const authorizationLevel = useSelector((state) => {
    return state.auth.authorizationLevel;
  });
  const name = useSelector((state) => {
    return state.auth.name;
  });
  let counterSelected = 0;

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectMulti, setSelectMulti] = useState(false);
  const [showYearDetails, setShowYearDetails] = useState(false);
  const [forceReload, setForceReload] = useState(0);
  const [showForceRefresh, setShowForceRefresh] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const load = useCallback(async () => {
    setLoading(true);
    try {
      let url = "";
      if (authorizationLevel === 0) {
        url = "/user/output";
      } else if (authorizationLevel < 2) {
        url = getUrl("/katedra/output");
      } else {
        url = getUrl("/fakulta/output");
      }
      if (showAll) {
        url = getUrl("/user/allOutput");
      }
      const response = await api.get(url);
      setRows(
        response.data.map((r) => {
          return { ...r, selected: false };
        })
      );
      setForceReload(new Date().getTime());
      sessionStorage.setItem("lastSeenTimestamp", new Date().getTime());
    } catch (error) {
      alert(formatError(error));
    }
    setLoading(false);
  }, [authorizationLevel, showAll]);

  const checkUnseen = useCallback(async () => {
    try {
      const response = await api.get(
        "/user/unseen/" + sessionStorage.getItem("lastSeenTimestamp")
      );
      setShowForceRefresh(newVersionAvailable(response.data));
      if (
        response.data.indexOf("events") !== -1 ||
        response.data.indexOf("outputs") !== -1
      ) {
        await load();
      }
    } catch (error) {
      console.error("mos-95:", error.message);
    }
  }, [load]);

  const onDeleteHandler = async (record_id) => {
    try {
      let url = "/user/output/";
      if (authorizationLevel > 0) {
        url = "/katedra/output/";
      }
      const response = await api.delete(url + record_id);
      if (response.status === 200) {
        load();
      } else {
        alert("Chyba pri mazani vykonu.", response.status);
      }
    } catch (error) {
      alert(formatError(error));
    }
  };

  const handleSelectChange = (e, data) => {
    setRows(
      rows.map((r) => {
        if (data._id === r._id) {
          r.selected = e.target.checked;
        }
        return r;
      })
    );
  };

  const onMultiClickHandler = () => {
    if (selectMulti) {
      setRows(
        rows.map((r) => {
          r.selected = false;
          return r;
        })
      );
    }
    setSelectMulti(!selectMulti);
  };
  const onAllClickHandler = (rowsRendered) => {
    for (const row of rowsRendered) {
      row.selected = counterSelected > 0 ? false : true;
    }
    setForceReload(new Date().getTime());
  };

  for (const row of rows) {
    if (row.selected) {
      counterSelected++;
    }
  }

  const head = [];
  if (selectMulti) {
    head.push({
      id: "selected",
      numeric: false,
      label: "Označenie",
      render: (data) => {
        return (
          <Checkbox
            checked={data.selected}
            onChange={(e) => handleSelectChange(e, data)}
          />
        );
      },
    });
  }

  head.push({
    id: "title",
    numeric: false,
    label: "Názov aktivity",
    search: true,
  });
  if (authorizationLevel) {
    head.push({
      id: "name",
      numeric: false,
      label: "Osoba",
      search: true,
    });
  }
  const rest = [
    {
      id: "note",
      numeric: false,
      label: "Poznámka",
      search: true,
    },
    {
      id: "eventDate",
      numeric: false,
      label: "Dátum aktivity",
      render: (data) => {
        return formatDate(data.eventDate);
      },
    },
    {
      id: "outputDate",
      numeric: false,
      label: "Dátum výkonu",
      render: (data) => {
        return formatDate(data.outputDate);
      },
    },
    {
      id: "department",
      numeric: false,
      label: "Zaradenie - katedra",
      search: true,
    },
    {
      id: "menDay",
      numeric: false,
      label: "Človeko/deň (víkendový deň)",
      render: (data) => {
        return `${data.menDay} (${data.menDayWeekend})`;
      },
    },
    {
      id: "output",
      numeric: false,
      label: "Výkon",
    },
    {
      id: "type",
      numeric: false,
      label: "Typ aktivity",
      search: true,
    },
    {
      id: "approved",
      numeric: false,
      label: "Schválené",
      search: false,
      render: (data) => {
        const text = data.approved ? "Áno" : "Nie";
        const color = data.approved ? "green" : "red";
        return (
          <Box sx={{ bgcolor: color, borderRadius: 2 }}>
            <Typography color="white" align="center">
              {text}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: "approvedBy",
      numeric: false,
      label: "Schvalovatel",
      search: true,
    },
    {
      id: "approvedAt",
      numeric: false,
      label: "Schválené dátum",
      render: (data) => {
        let res = "";
        if (new Date(data.approvedAt).getTime() !== 0) {
          res = formatDate(data.approvedAt);
        }
        return res;
      },
    },
  ];
  head.push(...rest);
  if (!selectMulti && !showAll) {
    head.push({
      id: "buttons",
      numeric: false,
      label: "Akcie",
      render: (data) => {
        return (
          <Stack direction="row" alignItems="center">
            {authorizationLevel > 1 && (
              <Tooltip title={"Prejdi na zdrojovú aktivitu"}>
                <IconButton
                  onClick={() => {
                    navigate("/manageEvents/" + data.eventId);
                  }}
                >
                  <SourceIcon fontSize="small" color={"primary"} />
                </IconButton>
              </Tooltip>
            )}
            <ShowOutputHistory values={data.history} />
            {!data.approved && authorizationLevel >= 0 && (
              <MoveOutput values={data} onEdit={load} />
            )}
            {(!data.approved || authorizationLevel > 1) && (
              <EditOutput
                values={data}
                onEdit={load}
                role={authorizationLevel}
                name={name}
              />
            )}
            {(!data.approved || authorizationLevel > 1) && (
              <DeleteItemDialog
                title="Potvrdenie zmazanie výkonu"
                secondaryText={`Vykon pre pouzivatela: ${data.name} na aktivitu: ${data.title}`}
                onDelete={() => {
                  onDeleteHandler(data._id);
                }}
              />
            )}
          </Stack>
        );
      },
    });
  }

  const settings = {
    rows: rows,
    loading: loading,
    colOrder: {
      order: "desc",
      orderBy: "eventDate",
    },
    dense: true,
    headCells: head,
    tableId: "manageOutputsScreenTable",
  };

  const calcOutputs = (rowData) => {
    let sum = 0;
    for (const row of rowData) {
      sum += parseFloat(row.output);
    }
    return `Suma zobrazených výkonov: ${sum.toFixed(2)} človeko/deň`;
  };

  useEffect(() => {
    load();
    window.addEventListener("focus", checkUnseen);
    return () => {
      window.removeEventListener("focus", checkUnseen);
    };
  }, [load, checkUnseen]);

  const yearRestriction = localStorage.getItem("yearRestriction");
  const headerText =
    "Správa výkonov " + (yearRestriction !== "0" ? `(${yearRestriction})` : "");

  return (
    <Box sx={{ mt: 2, flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item>
          <Typography variant="h4">
            {authorizationLevel === 0 ? "Moje výkony" : headerText}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={"auto"}>
          <AddOutput onAdd={load} />
        </Grid>
        {authorizationLevel > 0 && (
          <Grid item xs={12} sm={"auto"}>
            <AddOutputMulti onAdd={load} />
          </Grid>
        )}
        {authorizationLevel === 1 && (
          <Grid item xs={12} sm={"auto"}>
            <Stack direction="row" sx={{ ml: 2, pt: 0.8 }}>
              <Box sx={{ alignSelf: "center", display: "flex" }}>
                <Typography>Zobraz všetky výkony</Typography>
              </Box>
              <Checkbox
                label="Zobraz všetky výkony"
                checked={showAll}
                onChange={(event) => {
                  setShowAll(event.target.checked);
                }}
              />
            </Stack>
          </Grid>
        )}
        {authorizationLevel > 1 && (
          <Grid item xs={12} sm={"auto"}>
            <Button
              sx={{ m: 1 }}
              variant="contained"
              onClick={onMultiClickHandler}
              fullWidth
            >
              {!selectMulti ? "Označ viacej záznamov" : "Zruš označenie"}
            </Button>
          </Grid>
        )}
        {authorizationLevel > 0 && (
          <Grid item xs={12} sm={"auto"}>
            <Button
              sx={{ m: 1 }}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => setShowYearDetails(!showYearDetails)}
            >
              {showYearDetails ? "Schovaj ročné údaje" : "Zobraz ročné údaje"}
            </Button>
          </Grid>
        )}
        {authorizationLevel > 1 && (
          <Grid item xs={12} sm={"auto"}>
            <YearExportDialog />
          </Grid>
        )}
        {authorizationLevel > 1 && (
          <Grid item xs={12} sm={"auto"}>
            <OutputsExportDialog sourceData={rows} />
          </Grid>
        )}
      </Grid>
      {selectMulti && (
        <Stack direction="row" alignItems="center">
          <Typography sx={{ mr: 2 }} variant="h6">
            Vykonaj akciu s označennými súbormi (označené: {counterSelected}):{" "}
          </Typography>
          <MoveOutput
            values={rows}
            onEdit={load}
            multiEdit={true}
            isEnabled={counterSelected > 0}
          />
          <EditOutputMulti
            values={rows}
            onEdit={load}
            isEnabled={counterSelected > 0}
            name={name}
          />
        </Stack>
      )}
      {showYearDetails && (
        <YearDetails isVisible={showYearDetails} forceReload={forceReload} />
      )}

      <Card>
        <Table
          settings={settings}
          callbackExtraText={calcOutputs}
          callbackChangeMultiSelect={
            selectMulti ? onAllClickHandler : undefined
          }
          counterSelected={counterSelected}
        />
      </Card>
      <ForceRefreshInfo open={showForceRefresh} />
    </Box>
  );
};

export default ManageOutputsScreen;
