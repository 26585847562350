import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  LinearProgress,
  Grid,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import api from "api/api";
import { defaultTableOptions } from "constants/tableTexts";
import MUIDataTable from "mui-datatables";
import ChartLineComponent from "./ChartLineComponent";
import { toFixedFloat } from "helpers";

const HistoryPoints = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [chartDataType, setChartDataType] = useState("0");

  const currentyear = new Date().getFullYear();
  const years = Array(currentyear - (currentyear - 5))
    .fill("")
    .map((v, idx) => currentyear - idx);

  const handleChange = (event) => {
    setLoading(true);
    setYear(event.target.value);
  };

  const handleCharTypeChange = (event) => {
    setChartDataType(event.target.value);
  };

  const load = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get("/user/historyPointsData/" + year);
      setData(
        response.data
          .filter((item) => item.department.shortcut !== "KMAE")
          .map((item) => {
            return {
              ...item,
              historyData: item.historyData.map((e) => {
                return {
                  ...e,
                  month: e.month + 1,
                };
              }),
            };
          })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  }, [setLoading, year]);

  const rows = [];
  const columns = [
    {
      name: "shortcut",
      label: "Katedra",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];
  for (const d of data) {
    const rowItem = {
      shortcut: d.department.shortcut,
    };
    for (const item of d.historyData.sort((a, b) => a.month - b.month)) {
      let itemVal = item.publicationPoints;
      if (chartDataType === "1") {
        itemVal = item.citationPoint;
      }
      if (chartDataType === "2") {
        itemVal +=
          item.citationPoint > item.publicationPoints
            ? item.citationPoint
            : item.citationPoint;
      }
      rowItem[item.month] = itemVal;
      if (columns.find((e) => e.name === item.month.toString()) === undefined) {
        columns.push({
          name: item.month.toString(),
          label: item.month === 13 ? "Aktuálne" : item.month.toString(),
          options: {
            filter: false,
            sort: true,
            customBodyRender: (value) => toFixedFloat(value),
          },
        });
      }
    }
    rows.push(rowItem);
  }

  rows.sort((a, b) => b["13"] - a["13"]);

  useEffect(() => {
    load();
  }, [load]);
  return (
    <Card>
      <Grid py={1} px={1} container spacing={1}>
        <Grid item>
          <Typography mr={2} variant="h5" sx={{ mt: 1 }}>
            Historické dáta
          </Typography>
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel id="simple-select-label">Rok</InputLabel>
            <Select
              labelId="simple-select-label"
              value={year}
              label="Rok"
              onChange={handleChange}
            >
              {years.length > 0 ? (
                years.map((d, i) => (
                  <MenuItem key={i} value={d}>
                    {d}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={year}>{year}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sx={{ ml: 2 }}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Zobraziť body za
            </FormLabel>
            <RadioGroup
              onChange={handleCharTypeChange}
              value={chartDataType}
              row
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="Publikácie"
              />
              <FormControlLabel value="1" control={<Radio />} label="Citácie" />
              <FormControlLabel value="2" control={<Radio />} label="Celkovo" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ minHeight: 400 }}>
          <ChartLineComponent data={rows} />
        </Grid>
      </Grid>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <MUIDataTable
        data={rows}
        columns={columns}
        options={defaultTableOptions({
          sortOrder: {
            name: "13",
            direction: "desc",
          },
          //   onDownload: (buildHead, buildBody, columns, data) => {
          //     exportExcel(columns, data);
          //     return false;
          //   },
        })}
      />
    </Card>
  );
};

export default HistoryPoints;
