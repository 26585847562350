import React from "react";

import { Stack, Button, TextField } from "@mui/material";

const ComponentCategoryStack = ({ values, onSubmit, onDelete }) => {
  const [value, setValue] = React.useState(values);

  return (
    <Stack direction="row">
      <TextField
        margin="dense"
        required
        id="category"
        name="category"
        label="Kategória"
        fullWidth
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      />
      <Button type="button" color="error" onClick={() => onDelete(values)}>
        Zmaž
      </Button>
      <Button
        disabled={values === value}
        onClick={() => onSubmit(values, value)}
      >
        Uprav
      </Button>
    </Stack>
  );
};

export default ComponentCategoryStack;
