import React from "react";

import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import api from "../../api/api";
import { formatError } from "../../helpers";

const HandleUserMerge = ({ users, onEdit }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
    setSuccess(null);
  };
  const handleMerge = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(null);
    let mainUserId = "";
    let mergedUserId = "";
    const [, mainUser] = event.target.mainUser.value.split("-");
    const [, mergedUser] = event.target.mergedUser.value.split("-");
    if (mainUser === mergedUser) {
      setError(
        "Nie je možné spojiť rovnakého používateľa: " +
          event.target.mainUser.value
      );
      return;
    }
    try {
      for (const u of users) {
        if (u.email === mainUser) {
          mainUserId = u._id;
        }
        if (u.email === mergedUser) {
          mergedUserId = u._id;
        }
      }
      await api.post("/fakulta/mergeUsers", {
        mainUserId,
        mergedUserId,
      });
      setSuccess(
        "Používatelia boli úspešne spojený, spájaný používateľ bol vymazaný zo systému. Počkajte chviľku, toto okno sa samo zavrie."
      );
      setTimeout(() => {
        handleClose();
        onEdit();
      }, 2000);
    } catch (error) {
      setError(formatError(error));
    }
  };

  return (
    <div>
      <Button sx={{ m: 1 }} variant="contained" onClick={handleClickOpen}>
        Spoj používateľov
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
        <form onSubmit={handleMerge}>
          <DialogContent>
            <DialogTitle>Formulár spájania používateľov</DialogTitle>
            <Stack
              spacing={5}
              direction={"row"}
              justifyContent={"space-between"}
              sx={{ minHeight: 400 }}
            >
              <Stack spacing={2} sx={{ minWidth: 400 }}>
                <Typography variant="h5">Hlavný používateľ</Typography>
                <Autocomplete
                  disablePortal
                  id="mainUser"
                  options={users.map((item) => {
                    return {
                      ...item,
                      label: `${item.name}-${item.email}`,
                    };
                  })}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      required
                      id="mainUser"
                      {...params}
                      size="small"
                      label="Hlavný používateľ"
                      margin="normal"
                    />
                  )}
                />
              </Stack>
              <Stack spacing={2} sx={{ minWidth: 400 }}>
                <Typography variant="h5">Používateľ na zlúčenie</Typography>
                <Autocomplete
                  disablePortal
                  id="mergedUser"
                  options={users.map((item) => {
                    return {
                      ...item,
                      label: `${item.name}-${item.email}`,
                    };
                  })}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      required
                      id="mergedUser"
                      {...params}
                      size="small"
                      label="Používateľ na zlúčenie"
                      margin="normal"
                    />
                  )}
                />
              </Stack>
            </Stack>
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}
            <DialogActions>
              <Button onClick={handleClose} color="error">
                Zruš
              </Button>
              <Button type="submit">Spoj! (Používaj s rozvahou)</Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
};

export default HandleUserMerge;
