import { Box, CircularProgress, Skeleton, TableContainer } from "@mui/material";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { toFixedFloat } from "helpers";

const PointsTable = ({
  userPoints = {},
  showPerResearcher = false,
  isLoading = true,
}) => {
  let sum = "";
  if (
    typeof userPoints.pub !== "undefined" &&
    typeof userPoints.cit !== "undefined"
  ) {
    sum = (+userPoints.pub + +userPoints.cit).toFixed(2);
  }

  let content = (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={48} />
    </Box>
  );

  content = <Skeleton variant="rectangular" width={300} height={120} />;

  if (!isLoading) {
    content = (
      <TableContainer>
        <MuiTable
          sx={{ width: "auto" }}
          size="small"
          aria-label="a dense table"
        >
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {!showPerResearcher
                  ? "Body za publikácie"
                  : "Body za publikácie/výskumník"}
              </TableCell>
              <TableCell align="right">{`${toFixedFloat(userPoints.pub)}${
                showPerResearcher
                  ? " / " +
                    toFixedFloat(userPoints.pub / userPoints.researcherCount)
                  : ""
              }`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {!showPerResearcher
                  ? "Body za citácie"
                  : "Body za citácie/výskumník"}
              </TableCell>
              <TableCell align="right">
                {`${toFixedFloat(userPoints.cit)}${
                  showPerResearcher
                    ? " / " +
                      toFixedFloat(userPoints.cit / userPoints.researcherCount)
                    : ""
                }`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {!showPerResearcher ? "Body celkovo" : "Body celkovo/výskumník"}
              </TableCell>
              <TableCell align="right">
                {`${sum}${
                  showPerResearcher
                    ? " / " + (sum / userPoints.researcherCount).toFixed(2)
                    : ""
                }`}
              </TableCell>
            </TableRow>
          </TableBody>
        </MuiTable>
      </TableContainer>
    );
  }
  return content;
};

export default PointsTable;
