export const chartTypes = [
  {
    name: "Q1",
  },
  {
    name: "Q2",
  },
  {
    name: "Q3",
  },
  {
    name: "Q4",
  },
  {
    name: "WOS",
  },
  {
    name: "Scopus",
  },
];

export const barDataJournal = [
  {
    value: "value",
    name: "Počet záznamov",
    color: "#8884d8",
    showLabelList: true,
  },
];
