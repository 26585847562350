import React, { useCallback, useEffect, useState } from "react";
import { Grid, Stack, Typography, Card, Box } from "@mui/material";
import Table from "../components/universal/Table";
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import {
  newVersionAvailable,
  formatDate,
  formatError,
  translateActivityBase,
  getUrl,
} from "../helpers";

import AddOutput from "../components/manageOutputs/AddOutput";
import AddOutputMulti from "../components/manageOutputs/AddOutputMulti";
import ClosedEventList from "../components/universal/ClosedEventList";
import { useSelector } from "react-redux";
import ForceRefreshInfo from "../components/universal/ForceRefreshInfo";

const EventsListScreen = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const authorizationLevel = useSelector((state) => {
    return state.auth.authorizationLevel;
  });
  const [showForceRefresh, setShowForceRefresh] = useState(false);

  const load = useCallback(
    async (delay) => {
      setLoading(true);
      setTimeout(async () => {
        try {
          let url = "/user/events";
          if (authorizationLevel > 0) {
            url = getUrl("/user/events");
          }
          const response = await api.get(url);
          setRows(response.data);
          sessionStorage.setItem("lastSeenTimestamp", new Date().getTime());
        } catch (err) {
          alert(formatError(err));
        }
        setLoading(false);
      }, delay || 10);
    },
    [authorizationLevel]
  );

  const onAdd = () => {
    navigate("/manageOutputs");
  };
  const checkUnseen = useCallback(async () => {
    try {
      const response = await api.get(
        "/user/unseen/" + sessionStorage.getItem("lastSeenTimestamp")
      );
      setShowForceRefresh(newVersionAvailable(response.data));
      if (response.data.indexOf("events") !== -1) {
        await load();
      }
    } catch (error) {}
  }, [load]);

  const settings = {
    rows: rows,
    loading: loading,
    colOrder: {
      order: "asc",
      orderBy: "eventDate",
    },
    headCells: [
      {
        id: "isOpen",
        numeric: false,
        label: "Stav aktivity",
        render: (data) => {
          let bgColor = "green";
          const now = new Date();
          if (new Date(data.eventDate) < now) {
            bgColor = "orange";
          }
          if (!data.isOpen) {
            bgColor = "red";
          }
          return (
            <Box
              sx={{
                borderRadius: 4,
                width: 25,
                height: 25,
                bgcolor: bgColor,
              }}
            ></Box>
          );
        },
      },
      {
        id: "title",
        numeric: false,
        label: "Názov aktivity",
        search: true,
      },
      {
        id: "eventDate",
        numeric: false,
        label: "Dátum začiatok",
        render: (data) => {
          return formatDate(data.eventDate);
        },
      },
      {
        id: "eventEndDate",
        numeric: false,
        label: "Dátum koniec",
        render: (data) => {
          return formatDate(data.eventEndDate);
        },
      },
      {
        id: "outputDeadline",
        numeric: false,
        label: "Uzávierka výkonov",
        render: (data) => {
          return formatDate(data.outputDeadline);
        },
      },
      {
        id: "base",
        numeric: false,
        label: "Zaradenie aktivity",
        search: true,
        render: (data) => {
          return translateActivityBase(data.base);
        },
      },
      {
        id: "type",
        numeric: false,
        label: "Typ aktivity",
      },
      {
        id: "coefficient",
        numeric: true,
        label: "Koeficient",
      },
      {
        id: "buttons",
        numeric: false,
        label: "Akcie",
        render: (data) => {
          const now = new Date();
          return new Date(data.eventDate) < now ? (
            <Stack direction="row" alignItems="center">
              {data.isOpen && (
                <AddOutput
                  values={{ title: data.title }}
                  onAdd={onAdd}
                  showIcon={true}
                />
              )}
              {authorizationLevel > 0 && data.isOpen && (
                <AddOutputMulti
                  values={{ title: data.title }}
                  onAdd={onAdd}
                  showIcon={true}
                />
              )}
            </Stack>
          ) : null;
        },
      },
    ],
  };

  useEffect(() => {
    load();
    window.addEventListener("focus", checkUnseen);
    return () => {
      window.removeEventListener("focus", checkUnseen);
    };
  }, [load, checkUnseen]);

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h4">Zoznam aktivít</Typography>
        </Grid>
        <Grid item>
          <ClosedEventList />
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Card>
        <Table settings={settings} />
      </Card>
      <ForceRefreshInfo open={showForceRefresh} />
    </Box>
  );
};

export default EventsListScreen;
