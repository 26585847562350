import {
  TextField,
  Alert,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Box,
  Button,
  Typography,
  IconButton,
} from "@mui/material";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import deLocale from "date-fns/locale/de";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import React, { useEffect } from "react";

const FormularOutputMulti = ({
  values = {},
  error,
  role = 0,
  completeOptionsUser = [],
  completeOptionsEvent = [],
  selectedUsers,
  setSelectedUsers,
}) => {
  const [approved, setApproved] = React.useState(false);
  const [useSameManDay, setUseSameManDay] = React.useState(true);
  const [event, setEvent] = React.useState(null);
  const [outputDate, setOutputDate] = React.useState(new Date());
  const [autoUser, setAutoUser] = React.useState(null);

  const handleUserPicked = (event) => {
    event.preventDefault();
    setSelectedUsers([
      ...selectedUsers,
      { ...autoUser, menDay: 1, menDayWeekend: 0 },
    ]);
    setAutoUser(null);
  };

  const completeOptionsUserLeft = completeOptionsUser.filter((user) => {
    for (const selected of selectedUsers) {
      if (selected._id === user._id) {
        return false;
      }
    }
    return true;
  });

  useEffect(() => {
    for (const c of completeOptionsEvent) {
      if (c.label === values.title) {
        setEvent(c);
        break;
      }
    }
  }, [values.approved, values.title, completeOptionsEvent, values.outputDate]);

  return (
    <React.Fragment>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={deLocale}
      >
        <DatePicker
          label="Dátum"
          value={outputDate}
          onChange={(newValue) => {
            setOutputDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              required
              id="outputDate"
              {...params}
              sx={{ mt: 2 }}
              fullWidth
            />
          )}
        />
      </LocalizationProvider>
      {role > 0 && (
        <FormControlLabel
          control={
            <Checkbox
              checked={useSameManDay}
              onChange={(value) => {
                setUseSameManDay(value.target.checked);
              }}
            />
          }
          label="Rovnaké výkony"
          id="useSameManDay"
          name="useSameManDay"
          labelPlacement="start"
          sx={{ justifyContent: "space-between", display: "flex" }}
        />
      )}
      {role > 0 && !values._id && (
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Autocomplete
              disablePortal
              id="user"
              options={completeOptionsUserLeft}
              value={autoUser}
              onChange={(event, newValue) => {
                setAutoUser(newValue);
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  id="user"
                  {...params}
                  size="small"
                  label="Pracovník"
                  margin="normal"
                />
              )}
            />
            <Button onClick={handleUserPicked} disabled={!autoUser}>
              Pridaj
            </Button>
          </Box>
          {selectedUsers.map((item) => {
            return (
              <Box
                key={item._id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    pr: 5,
                  }}
                >
                  {item.name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {!useSameManDay && (
                    <TextField
                      sx={{ mr: 1 }}
                      margin="dense"
                      required
                      size="small"
                      type="number"
                      label="Č/d (pracovný deň)"
                      inputProps={{ step: "0.1" }}
                      value={item.menDay}
                      onChange={(event) => {
                        setSelectedUsers(
                          selectedUsers.map((s) => {
                            if (s._id === item._id) {
                              s.menDay = parseFloat(event.target.value);
                            }
                            return s;
                          })
                        );
                      }}
                    />
                  )}
                  {!useSameManDay && (
                    <TextField
                      margin="dense"
                      required
                      size="small"
                      type="number"
                      label="Č/d (víkendový deň)"
                      inputProps={{ step: "0.1" }}
                      value={item.menDayWeekend}
                      onChange={(event) => {
                        setSelectedUsers(
                          selectedUsers.map((s) => {
                            if (s._id === item._id) {
                              s.menDayWeekend = parseFloat(event.target.value);
                            }
                            return s;
                          })
                        );
                      }}
                    />
                  )}
                  <IconButton
                    aria-label="uprav"
                    size="small"
                    onClick={() => {
                      setSelectedUsers(
                        selectedUsers.filter((s) => {
                          return s._id !== item._id;
                        })
                      );
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" color="primary" />
                  </IconButton>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
      {!values._id && (
        <Autocomplete
          disablePortal
          id="eventId"
          options={completeOptionsEvent}
          fullWidth
          value={event}
          onChange={(event, newValue) => {
            setEvent(newValue);
          }}
          renderInput={(params) => (
            <TextField
              id="eventId"
              required
              {...params}
              label="Aktivita"
              size="small"
              margin="normal"
            />
          )}
        />
      )}
      <TextField
        margin="normal"
        size="small"
        id="note"
        name="note"
        label="Poznámka"
        fullWidth
        defaultValue={values.note || ""}
      />
      {useSameManDay && (
        <TextField
          margin="normal"
          required
          id="menDay"
          size="small"
          name="menDay"
          type="number"
          label="Človeko/deň (pracovný deň)"
          inputProps={{ step: "0.1" }}
          fullWidth
          defaultValue={values.menDay || 1}
        />
      )}
      {useSameManDay && (
        <TextField
          margin="normal"
          size="small"
          id="menDayWeekend"
          name="menDayWeekend"
          label="Človeko/deň (víkendový deň)"
          type="number"
          inputProps={{ step: "0.1" }}
          fullWidth
          defaultValue={values.menDayWeekend || 0}
        />
      )}
      {role > 1 && (
        <FormControlLabel
          control={
            <Checkbox
              checked={approved}
              onChange={(value) => {
                setApproved(value.target.checked);
              }}
            />
          }
          label="Schválené"
          id="approved"
          name="approved"
          labelPlacement="start"
          sx={{ justifyContent: "space-between", display: "flex" }}
        />
      )}
      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
    </React.Fragment>
  );
};

export default FormularOutputMulti;
