import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Box,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  Button,
  Stack,
  Autocomplete,
  Tab,
  Grid,
  Switch,
  FormControlLabel,
  Tooltip as MuiTooltip,
  IconButton,
  Chip,
  Typography,
} from "@mui/material";
import api from "api/api";
import Table from "components/universal/Table";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { pubHasExtAuthors, toFixedFloat } from "helpers";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import WarningIcon from "@mui/icons-material/Warning";

import DialogExtAuthorClaim from "./DialogExtAuthorClaim";
import PointsTable from "./PointsTable";
import DialogPubInfo from "./DialogPubInfo";
import { calcPubTypes } from "util/publicationUtil";
import {
  handleDepartnemtDataExport,
  handleUserScienceOutputs,
} from "util/excelExportUtil";
import ChartBarComponent from "./ChartBarComponent";
import { barDataJournal } from "constants/pubChartType";
import ForceSciCitation from "./ForceSciCitation";

export default function DepartmentPublications({ authData, ...rest }) {
  const [publications, setPublications] = React.useState([]);
  const [citations, setCitations] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const currentyear = new Date().getFullYear();
  const years = Array(currentyear - (currentyear - 5))
    .fill("")
    .map((v, idx) => currentyear - idx);
  const [departments, setDepartments] = React.useState([]);
  const [selectedDep, setSelectedDep] = React.useState(null);
  const [points, setPoints] = React.useState({ citations: 0, publications: 0 });
  const [tab, setTab] = React.useState("1");
  const [gdata, setGdata] = React.useState([]);
  const [planChartData, setPlanChartData] = React.useState([]);
  //const [journaldata, setJournalData] = React.useState([]);
  const [showZeroPubs, setShowZeroPubs] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChange = (event) => {
    setLoading(true);
    setYear(event.target.value);
  };

  useEffect(() => {
    if (departments.some((item) => item.label === authData.department)) {
      setSelectedDep(authData.department);
    }
  }, [authData.department, setSelectedDep, departments]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get("/user/department");
        setDepartments(
          response.data.map((item) => {
            return {
              ...item,
              label: item.shortcut,
            };
          })
        );
      } catch (error) {
        alert("Chyba: ", error.message);
      }
    }
    fetchData();
  }, [setDepartments]);

  const load = useCallback(async () => {
    if (selectedDep) {
      setLoading(true);
      try {
        const response = await api.post("/user/getYearReportByDep", {
          year: year,
          departmentShortcut: selectedDep,
        });
        response.data.publications.map((data) => {
          data.departmentPoints = toFixedFloat(
            data.points * (data.proportion / 100)
          );

          return data;
        });
        setPublications(response.data.publications);
        setCitations(
          response.data.citations.map((c) => {
            return {
              ...c,
              myPub: c.authorsList + "; " + c.sourcePublication,
            };
          })
        );
        setGdata(calcPubTypes(response.data.publications));
        // setJournalData(calcDiffJournals(response.data.publications));
        setLoading(false);
        setPoints({
          cit: +response.data.citationPointCount,
          pub: +response.data.publicationPoints,
          researcherCount: +response.data.researcherCount,
        });
      } catch (error) {
        alert(error.message);
        setGdata([]);
        setLoading(false);
      }
    }
  }, [setPublications, setLoading, setCitations, year, selectedDep, setGdata]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    const loadPlan = async () => {
      try {
        if (!selectedDep || !year) {
          return;
        }
        let url = `/user/publicationPlan?year=${year}&userDepartment=${selectedDep}`;
        let response = await api.get(url);
        const accepted = response.data
          .filter((item) => item.publicationStatus === "Akceptované")
          .map((item) => ({
            ...item,
            quartil: item.quartile || "undefined",
          }));
        setPlanChartData(calcPubTypes(accepted));
      } catch (err) {
        alert(err.message);
      }
    };
    loadPlan();
  }, [year, selectedDep]);

  const filteredPubs = publications.filter((item) => {
    return showZeroPubs ? true : item.points !== 0;
  });

  const settings = {
    rows: filteredPubs,
    loading: loading,
    dense: true,
    colOrder: {
      order: "asc",
      orderBy: "points",
    },
    headCells: [
      {
        id: "epcaKod",
        numeric: false,
        label: "Kategória publikácie",
        search: true,
      },
      {
        id: "customAuthorsList",
        numeric: false,
        label: "Autori",
        search: true,
      },
      {
        id: "title",
        numeric: false,
        label: "Názov",
        search: true,
      },
      {
        id: "sourceName",
        numeric: false,
        label: "Zdrojovy dokument",
        search: true,
      },
      {
        id: "quartil",
        numeric: false,

        label: "Kvartil",
        search: true,
        render: (data) => (data.quartil === "undefined" ? "" : data.quartil),
      },
      {
        id: "isWOS",
        numeric: false,
        label: "WOS",
        search: false,
        render: (data) => (data.isWOS ? <CheckIcon /> : <ClearIcon />),
      },
      {
        id: "isScopus",
        numeric: false,
        label: "Scopus",
        search: false,
        render: (data) => (data.isScopus ? <CheckIcon /> : <ClearIcon />),
      },
      {
        id: "points",
        numeric: true,
        label: "Body za článok",
        search: false,
        render: (data) => {
          return (
            <Chip
              sx={{ minWidth: 50 }}
              label={data.points}
              color={data.isForced ? "error" : undefined}
            />
          );
        },
      },
      {
        id: "proportion",
        numeric: true,
        label: "Podiel katedry",
        search: false,
        render: (data) => data.proportion + "%",
      },
      {
        id: "departmentPoints",
        numeric: true,
        label: "Body pre katedru",
        search: false,
      },
      {
        id: "timestamp",
        numeric: false,
        label: "Dátum pridania",
        search: false,
        render: (data) => new Date(data.timestamp).toLocaleDateString("de"),
      },
      {
        id: "btns",
        label: "Akcie",
        render: (data) => (
          <Stack direction="row">
            {(data.onlineWOSIndexed && !data.isWOS) ||
            (data.onlineScopusIndexed && !data.isScopus) ? (
              <MuiTooltip
                title={
                  data.onlineWOSIndexed && !data.isWOS
                    ? "Publikácia je evidovaná v databázach WOS, ale v knižnici nie"
                    : "Publikácia je evidovaná v databázach Scopus, ale v knižnici nie"
                }
              >
                <IconButton color="error" size="small" variant="outlined">
                  <WarningIcon />
                </IconButton>
              </MuiTooltip>
            ) : null}
            {pubHasExtAuthors(data) && data.canEdit && (
              <DialogExtAuthorClaim props={data} load={load} />
            )}
            <DialogPubInfo props={data} />
          </Stack>
        ),
      },
    ],
  };

  const settingsCitations = {
    rows: citations,
    loading: loading,
    dense: true,
    colOrder: {
      order: "asc",
      orderBy: "points",
    },
    headCells: [
      {
        id: "points",
        numeric: true,
        label: "Body",
        search: false,
      },
      {
        id: "myPub",
        numeric: false,
        label: "Citovaný dokument",
        search: true,
      },
      {
        id: "source",
        numeric: false,
        label: "Zdrojový dokument",
        search: true,
      },
      {
        id: "index",
        numeric: false,
        label: "Index",
        search: true,
        render: (data) => {
          if (data.fullPoints === 30) {
            return "SCI";
          }
          return data.index;
        },
      },
      {
        id: "posibleSCI",
        numeric: false,
        label: "Možné SCI",
        search: false,
        render: (data) => {
          if (data.posibleSCI) {
            let bgcolor = "orange";
            if (data.forcedSci === 1) {
              bgcolor = "red";
            }
            if (data.forcedSci === 2) {
              bgcolor = "green";
            }
            return (
              <Stack direction={"row"}>
                <CheckIcon
                  sx={{
                    mt: 0.8,
                    p: 0.2,
                    px: 0.8,
                    bgcolor,
                    borderRadius: 4,
                  }}
                />
                {(authData.isScienceDepManager ||
                  authData.isScienceManager) && (
                  <ForceSciCitation citation={data} onEdit={load} />
                )}
              </Stack>
            );
          }
          return null;
        },
      },
    ],
  };

  return (
    <Card>
      <Box py={2} pl={2} display="flex">
        <FormControl>
          <InputLabel id="simple-select-label">Rok</InputLabel>
          <Select
            labelId="simple-select-label"
            value={year}
            label="Rok"
            onChange={handleChange}
          >
            {years.length > 0 ? (
              years.map((d, i) => (
                <MenuItem key={i} value={d}>
                  {d}
                </MenuItem>
              ))
            ) : (
              <MenuItem value={year}>{year}</MenuItem>
            )}
          </Select>
        </FormControl>
        <Autocomplete
          disablePortal
          id="activitySource"
          options={departments}
          sx={{ minWidth: 170, pl: 1 }}
          value={selectedDep}
          onChange={(event, newValue) => {
            if (newValue) {
              setLoading(true);
              setSelectedDep(newValue.label);
            }
          }}
          renderInput={(params) => (
            <TextField
              id="activitySource"
              required
              {...params}
              label="Vyber katedru"
            />
          )}
        />
        <FormControlLabel
          control={
            <Switch
              checked={showZeroPubs}
              onChange={(event) => {
                setShowZeroPubs(event.target.checked);
              }}
            />
          }
          label="Zobraziť nulové záznamy"
          labelPlacement="start"
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            onClick={() => {
              handleDepartnemtDataExport(
                publications,
                citations,
                selectedDep,
                year
              );
            }}
          >
            Exportovať dáta
          </Button>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            disabled={year <= 2020}
            onClick={() => {
              let depData = null;
              for (const dep of departments) {
                if (dep.shortcut === selectedDep) {
                  depData = dep;
                  break;
                }
              }
              handleUserScienceOutputs(publications, citations, depData, year);
            }}
          >
            Exportovať výkony
          </Button>
        </Box>
      </Box>
      <Grid py={1} px={1} container spacing={1}>
        <Grid item>
          <Typography>Počet výskumníkov: {points.researcherCount}</Typography>
          <PointsTable
            userPoints={points}
            showPerResearcher={true}
            isLoading={loading}
          />
        </Grid>
        <Grid item xs={11} md={4} sx={{ minHeight: 250 }}>
          <ChartBarComponent chartData={gdata} barData={barDataJournal} />
        </Grid>
        {/* <Grid item xs={11} md={5} xl={6} sx={{ minHeight: 250 }}>
          <ChartBarComponent chartData={journaldata} barData={barDataJournal} />
        </Grid> */}
        <Grid item xs={11} md={4} xl={5} sx={{ minHeight: 250 }}>
          <ChartBarComponent
            chartData={planChartData}
            barData={[
              {
                ...barDataJournal[0],
                name: "Počet akceptovaných záznamov z plánu",
                color: "#92ef9a",
              },
            ]}
          />
        </Grid>
      </Grid>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            <Tab label="Publikácie" value="1" />
            <Tab label="Citácie" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Table settings={settings} />
        </TabPanel>
        <TabPanel value="2">
          <Table settings={settingsCitations} />
        </TabPanel>
      </TabContext>
    </Card>
  );
}
