import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import api from "../../api/api";
import { checkDates, formatError } from "../../helpers";
import FormularEvent from "./FormularEvent";

const EditEvent = ({ values, completeOptions, onEdit }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [emails, setEmails] = React.useState([]);
  const [autoCompleteEmails, setAutoCompleteEmails] = React.useState([]);

  const handleClickOpen = async () => {
    try {
      const response = await api.get("/admin/getAllUser");
      setAutoCompleteEmails(
        response.data.map((r) => {
          return {
            ...r,
            label: `${r.name}`,
          };
        })
      );
      const selectedEmails = [];
      for (const e of values?.emailListNotifiers) {
        for (const r of response.data) {
          if (e === r.email) {
            selectedEmails.push(r);
          }
        }
      }
      setEmails(selectedEmails);
    } catch (err) {
      alert(formatError(err));
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const datesErr = checkDates([
      event.target.eventDate.value,
      event.target.eventEndDate.value,
      event.target.outputDeadline.value,
    ]);
    if (datesErr.length > 0) {
      setError(datesErr.join(", "));
      return;
    }
    try {
      const data = {
        isOpen: true,
        title: event.target.title.value,
        eventDate: event.target.eventDate.value,
        eventEndDate: event.target.eventEndDate.value,
        outputDeadline: event.target.outputDeadline.value,
        coefficient: values.coefficient,
        type: values.type,
        base: values.base,
        emailListNotifiers: emails.map((e) => e.email),
      };
      for (const co of completeOptions) {
        if (co.label === event.target.activitySource.value) {
          data.coefficient = co.coefficient;
          data.type = co.type;
          data.base = co.base;
        }
      }
      if (new Date() > new Date(data.outputDeadline)) {
        data.isOpen = false;
      }

      const response = await api.put("/fakulta/event/" + values._id, data);
      if (response.status === 200) {
        handleClose();
        onEdit(500);
      }
    } catch (error) {
      setError(formatError(error));
    }
  };

  return (
    <div>
      <Tooltip title="Uprav">
        <IconButton aria-label="uprav" size="small" onClick={handleClickOpen}>
          <EditIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Úprava aktivity</DialogTitle>
          <DialogContent>
            <FormularEvent
              completeOptions={completeOptions}
              values={values}
              error={error}
              autoCompleteEmails={autoCompleteEmails}
              emails={emails}
              setEmails={setEmails}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default EditEvent;
