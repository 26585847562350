import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";

import api from "../../api/api";
import { formatError } from "../../helpers";
import FormularBalance from "./FormularBalance";
import Edit from "@mui/icons-material/Edit";

const EditBalance = ({ values = {}, onEdit }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const isMinus = event.target.isMinus.checked ? -1 : 1;
      const data = {
        date: event.target.date.value,
        note: event.target.note.value,
        count: [],
      };
      if (values.categories.length > 0) {
        for (const cat of values.categories) {
          data.count.push({
            category: cat,
            count: isMinus * parseInt(event.target[`count${cat}`].value),
          });
        }
      } else {
        data.count.push({
          count: isMinus * parseInt(event.target.count.value),
        });
      }

      await values.deleteHandler(values.warehouseId, values.data._id, false);

      const response = await api.post(
        `/fakulta/warehouse/${values.warehouseId}/balance`,
        data
      );
      if (response.status === 200) {
        handleClose();
        onEdit(500);
      }
    } catch (error) {
      setError(formatError(error));
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Uprav pohyb">
        <IconButton aria-label="uprav" size="small" onClick={handleClickOpen}>
          <Edit fontSize="medium" color="primary" />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Uprav pohyb</DialogTitle>
          <DialogContent>
            <FormularBalance values={values} error={error} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default EditBalance;
