import { Box, Card, Grid, LinearProgress, Typography } from "@mui/material";
import api from "api/api";
import { defaultTableOptions } from "constants/tableTexts";
import MUIDataTable from "mui-datatables";
import React, { useCallback, useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const ThirdPartyRequests = () => {
  const [records, setRecords] = useState([]);
  const [dbCounters, setDbCounter] = useState([]);
  const [loading, setLoading] = useState(false);

  const translateRequestType = (type) => {
    const requestTypes = [
      {
        type: 1,
        text: "1- Scopus record check",
      },
      {
        type: 2,
        text: "2- Scopus record citation base on scopusID",
      },
      {
        type: 10,
        text: "10- Wos journal search base on ISSN",
      },
      {
        type: 11,
        text: "11- Wos citation check",
      },
      {
        type: 12,
        text: "12 - Wos record check",
      },
    ];
    for (const item of requestTypes) {
      if (item.type === type) {
        return item.text;
      }
    }
    return "";
  };

  const columns = [
    {
      name: "createdAt",
      label: "Čas vytvorenia",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return new Date(value).toLocaleString("sk");
        },
        setCellProps: () => {
          return {
            style: { minWidth: 130 },
          };
        },
      },
    },
    {
      name: "runAt",
      label: "Čas vykonania",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          if (!value) {
            return "";
          }
          return new Date(value).toLocaleString("sk");
        },
        setCellProps: () => {
          return {
            style: { minWidth: 130 },
          };
        },
      },
    },
    {
      name: "type",
      label: "Typ požiadavky",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return translateRequestType(value);
        },
        setCellProps: () => {
          return {
            style: { minWidth: 150 },
          };
        },
      },
    },
    {
      name: "searchStr",
      label: "Text vyhľadávania",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "sourceRecordId",
      label: "ID zdroja",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "finished",
      label: "finished",
      options: {
        filter: true,
        sort: true,
        filterType: "checkbox",
        filterOptions: {
          names: ["finished-Ano", "finished-Nie"],
          logic(finished, filterVal) {
            const show =
              (filterVal.indexOf("finished-Ano") >= 0 && finished) ||
              (filterVal.indexOf("finished-Nie") >= 0 && !finished);
            return !show;
          },
        },
        customBodyRender: (value) => {
          return value ? <CheckIcon /> : <ClearIcon />;
        },
      },
    },
    {
      name: "processed",
      label: "processed",
      options: {
        filter: true,
        sort: true,
        filterType: "checkbox",
        filterOptions: {
          names: ["processed-Ano", "processed-Nie"],
          logic(processed, filterVal) {
            const show =
              (filterVal.indexOf("processed-Ano") >= 0 && processed) ||
              (filterVal.indexOf("processed-Nie") >= 0 && !processed);
            return !show;
          },
        },
        customBodyRender: (value) => {
          return value ? <CheckIcon /> : <ClearIcon />;
        },
      },
    },
    {
      name: "result",
      label: "Výsledok",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          if (Object.keys(value).length === 0) {
            return "";
          }
          return JSON.stringify(value, {}, 2);
        },
      },
    },
  ];

  const load = useCallback(async () => {
    setLoading(true);
    try {
      let response = await api.get("/admin/requestQueue");
      setRecords(response.data);
      response = await api.get("/admin/dbCounter");
      setDbCounter(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Card>
      <Grid py={1} px={1} container spacing={1}>
        <Grid item>
          <Typography mr={2} variant="h5" sx={{ mt: 1 }}>
            Požiadavky na API tretích strán
          </Typography>
        </Grid>
        <Grid sx={{ mx: 1, mt: 2.6 }}>
          <Typography>Limit pre jednotlivé databázy:</Typography>
        </Grid>
        {dbCounters.map((item, index) => {
          return (
            <Grid key={index} sx={{ mx: 1, mt: 2.6 }}>
              <Typography>
                {item.type}: {item.counter}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <MUIDataTable
        // title={"Employee List"}
        data={records}
        columns={columns}
        options={defaultTableOptions({
          sortOrder: {
            name: "finished",
            direction: "desc",
          },
          // onDownload: (buildHead, buildBody, columns, data) => {
          //   exportExcel(columns, data);
          //   return false;
          // },
        })}
      />
    </Card>
  );
};

export default ThirdPartyRequests;
