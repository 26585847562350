export const publicationPlanBaseTypes = ["Časopis", "Konferencia", "Iné"];

export const publicationStatus = [
  "Rozpracované / v príprave",
  "V recenznom konaní",
  "Akceptované",
  "Registrované v knižnici",
];

export const displayTypes = ["Môj plán", "Plán katedry"];
