import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const colours = [
  "#006494",
  "#e9c46a",
  "#f4a261",
  "#386641",
  "#582f0e",
  "#d62828",
  "#231942",
];

const ChartLineComponent = ({ data = [] }) => {
  const data2 = [];
  if (data.length > 0) {
    for (const key in data[0]) {
      if (key === "shortcut") {
        continue;
      }
      let keyName = key;
      if (keyName === "13") {
        keyName = "aktualne";
      }
      data2.push({
        name: keyName,
      });
    }
  }
  for (const item of data) {
    for (const key in item) {
      let keyName = key;
      if (keyName === "13") {
        keyName = "aktualne";
      }
      const currentData = data2.find((e) => e.name === keyName);
      if (currentData !== undefined) {
        currentData[item.shortcut] = item[key];
      }
    }
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data2}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {data.map((item, index) => {
          return (
            <Line
              key={index}
              type="monotone"
              dataKey={item.shortcut}
              stroke={colours[index]}
              activeDot={{ r: 6 }}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ChartLineComponent;
