import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Box,
  Alert,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import api from "../../api/api";
import { useTheme } from "@mui/material/styles";
import { formatError } from "../../helpers";
import AddPlanItemFormular from "./AddPlanItemFormular";

const AddPlanItem = ({
  load,
  authData,
  year,
  showIcon = false,
  values = {},
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);

  const [selectedAuthors, setSelectedAuthors] = React.useState([]);
  const [selectedJournal, setSelectedJournal] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    setSelectedJournal(null);
    if (showIcon) {
      setSelectedAuthors(values.authors);
    } else {
      setSelectedAuthors([]);
    }
    setError(null);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
    setError(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    let allProportion = 0;
    let isAuthorIncluded = false;
    selectedAuthors.forEach((item) => {
      allProportion += parseInt(item.proportion);
      if (authData.authorId === item.authorId) {
        isAuthorIncluded = true;
      }
    });
    if (!isAuthorIncluded && !authData.isScienceDepManager) {
      setError("Zadávateľ musí byť aj autorom publikácie");
      return;
    }
    if (allProportion !== 100) {
      setError("Súčet podielov všetkých autorov musí byť 100");
      return;
    }
    const formData = new FormData(event.target);
    let data = Object.fromEntries(formData);
    data.isWOS = event.target.isWOS.checked;
    data.isScopus = event.target.isScopus.checked;
    data.isCurrentContent = event.target.isCurrentContent
      ? event.target.isCurrentContent.checked
      : false;
    data.isForeighnLanguage = event.target.isForeighnLanguage.checked;
    data.authors = selectedAuthors.map((item) => {
      return {
        authorId: item.authorId,
        proportion: item.proportion,
        affiliation: item.affiliation,
      };
    });
    if (event.target.conferenceName) {
      data.conferenceName = event.target.conferenceName.value;
    }
    if (selectedJournal) {
      data.sourceId = selectedJournal._id;
    }

    data.epcaRok = year;

    try {
      if (showIcon) {
        await api.put("user/publicationPlan/" + values._id, data);
      } else {
        await api.post("user/publicationPlan/", data);
      }
      load();
      handleClose();
    } catch (err) {
      setError(formatError(err));
    }
  };

  return (
    <div>
      {showIcon ? (
        <Tooltip title="Uprav publikáciu">
          <IconButton
            aria-label="uprav"
            size="normal"
            onClick={handleClickOpen}
          >
            <EditIcon fontSize="normal" color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button variant="contained" onClick={handleClickOpen}>
          Pridaj publikáciu
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
        <DialogContent sx={{ minWidth: matches ? 400 : 300 }}>
          <Box onSubmit={handleSubmit} component="form">
            <DialogTitle>Formulár pridania publikácie</DialogTitle>
            <AddPlanItemFormular
              authData={authData}
              selectedAuthors={selectedAuthors}
              setSelectedAuthors={setSelectedAuthors}
              selectedJournal={selectedJournal}
              setSelectedJournal={setSelectedJournal}
              values={values}
            />
            {error && (
              <Alert sx={{ m: 1, mb: 0, width: "100%" }} severity="error">
                {error}
              </Alert>
            )}
            <DialogActions>
              <Button onClick={handleClose} color="error">
                Zruš
              </Button>
              <Button type="submit">
                {showIcon ? "Uprav publikáciu" : "Pridaj publikáciu"}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddPlanItem;
