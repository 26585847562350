import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
//import api from "../../api/api";
import { formatError } from "../../helpers";
import FormularOutput from "./FormularOutput";

import api from "../../api/api";

const EditOutput = ({ values, role, name, onEdit }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        menDay: event.target.menDay.value,
        menDayWeekend: event.target.menDayWeekend.value,
        note: event.target.note.value,
        userId: values.userId,
        outputDate: event.target.outputDate.value,
      };
      if (role > 1) {
        data.approved = event.target.approved.checked;
        if (data.approved) {
          data.approvedBy = name;
        }
      }
      let url = "/user/output/";
      if (role > 0) {
        url = "/katedra/output/";
      }
      const response = await api.put(url + values._id, data);
      if (response.status === 200) {
        handleClose();
        onEdit(500);
      }
    } catch (error) {
      setError(formatError(error));
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Uprav">
        <IconButton aria-label="uprav" size="small" onClick={handleClickOpen}>
          <EditIcon fontSize="medium" color="primary" />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Uprav výkon</DialogTitle>
          <DialogContent>
            {values._id && (
              <DialogContentText>{`Osoba: ${values.name} na aktivite: ${values.title}`}</DialogContentText>
            )}
            <FormularOutput
              values={values}
              error={error}
              role={role}
              completeOptionsEvent={[]}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default EditOutput;
