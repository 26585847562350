import React from "react";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import api from "../../api/api";
import { formatError } from "../../helpers";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import skLocale from "date-fns/locale/sk";
import { handleWarehouseBalanceExport } from "util/excelExportUtil";

const ExportDialog = () => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [exportStartDate, setExportStartDate] = React.useState(
    new Date(`${new Date().getFullYear()}-01-01`)
  );
  const [exportEndDate, setExportEndDate] = React.useState(new Date());

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    if (exportEndDate < exportStartDate) {
      setError("Dátum pohyby do nemôže byť väčší ako dátum pohyby od!");
      return;
    }
    try {
      const response = await api.post("/fakulta/warehouseBalanceExport", {
        exportStartDate,
        exportEndDate,
      });
      handleWarehouseBalanceExport(
        response.data,
        `sklad-pohyby-${exportStartDate.toLocaleDateString(
          "sk"
        )}-${exportEndDate.toLocaleDateString("sk")}`
      );
    } catch (error) {
      setError(formatError(error));
    }
  };

  return (
    <React.Fragment>
      <Button sx={{ m: 1 }} variant="contained" onClick={handleClickOpen}>
        Exportuj pohyby
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Exportuj pohyby</DialogTitle>
          <DialogContent>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={skLocale}
            >
              <DatePicker
                label="Pohyby od"
                value={exportStartDate}
                onChange={(newValue) => {
                  setExportStartDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    id="exportStartDate"
                    {...params}
                    sx={{ mt: 2 }}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={skLocale}
            >
              <DatePicker
                label="Pohyby do"
                value={exportEndDate}
                onChange={(newValue) => {
                  setExportEndDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    id="exportStartDate"
                    {...params}
                    sx={{ mt: 2, mb: 2 }}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
            {error && (
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default ExportDialog;
