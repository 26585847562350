import React, { useCallback, useEffect, useState } from "react";
import { Grid, Stack, Typography, Card, Box, IconButton } from "@mui/material";
import Table from "../components/universal/Table";
import api from "../api/api";
import { formatError, newVersionAvailable } from "../helpers";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useNavigate } from "react-router-dom";
import DeleteItemDialog from "../components/universal/DeleteItemDialog";
import AddItem from "../components/warehouse/AddItem";
import EditItem from "../components/warehouse/EditItem";
import AddBalance from "../components/warehouse/AddBalance";
import ForceRefreshInfo from "../components/universal/ForceRefreshInfo";
import ExportDialog from "components/warehouse/ExportDialog";

const WarehouseScreen = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showForceRefresh, setShowForceRefresh] = useState(false);

  const navigate = useNavigate();

  const onDeleteHandler = async (eventId) => {
    try {
      const url = "/fakulta/warehouse/";
      const response = await api.delete(url + eventId);
      if (response.status === 200) {
        load();
      } else {
        alert("Chyba pri mazani položky.", response.status);
      }
    } catch (error) {
      alert(formatError(error));
    }
  };

  const checkUnseen = useCallback(async () => {
    try {
      const response = await api.get(
        "/user/unseen/" + sessionStorage.getItem("lastSeenTimestamp")
      );
      setShowForceRefresh(newVersionAvailable(response.data));
    } catch (error) {}
  }, []);

  const load = useCallback(async (delay) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        const response = await api.get("/fakulta/warehouse");
        setRows(response.data);
      } catch (err) {
        alert(formatError(err));
      }
      setLoading(false);
    }, delay || 10);
  }, []);

  const settings = {
    rows: rows,
    loading: loading,
    tableId: "warehouseScreenTable",
    colOrder: {
      order: "desc",
      orderBy: "title",
    },
    headCells: [
      {
        id: "_id",
        numeric: false,
        label: "Identifikátor",
        search: true,
      },
      {
        id: "title",
        numeric: false,
        label: "Názov položky",
        search: true,
      },
      {
        id: "note",
        numeric: false,
        label: "Poznámka",
        search: true,
      },
      {
        id: "price",
        numeric: true,
        label: "Predajná cena",
      },
      {
        id: "categories",
        numeric: false,
        label: "Kategórie",
        render: (data) => {
          return data.categories.join(", ");
        },
      },
      {
        id: "currentState",
        numeric: true,
        label: "Zostatok na sklade",
      },
      {
        id: "buttons",
        numeric: false,
        label: "Akcie",
        render: (data) => {
          return (
            <Stack direction="row" alignItems="center">
              <AddBalance onAdd={load} showIcon={true} values={data} />
              <EditItem values={data} onEdit={load} />
              <DeleteItemDialog
                title="Potvrdenie zmazanie položky."
                secondaryText={`Položka s názvom: ${data.title}`}
                onDelete={() => {
                  onDeleteHandler(data._id);
                }}
              />
              <IconButton
                onClick={() => {
                  navigate("/warehouse/" + data._id);
                }}
              >
                <KeyboardDoubleArrowRightIcon color="primary" />
              </IconButton>
            </Stack>
          );
        },
      },
    ],
  };

  useEffect(() => {
    load();
    checkUnseen();
    window.addEventListener("focus", checkUnseen);
    return () => {
      window.removeEventListener("focus", checkUnseen);
    };
  }, [load, checkUnseen]);

  const headerText = " Správa skladových zásob";
  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h4">{headerText}</Typography>
        </Grid>
        <Grid item>
          <AddItem onAdd={load} />
        </Grid>
        <Grid item>
          <ExportDialog />
        </Grid>
      </Grid>
      <Card>
        <Table settings={settings} />
      </Card>
      <ForceRefreshInfo open={showForceRefresh} />
    </Box>
  );
};

export default WarehouseScreen;
