import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Alert,
  IconButton,
  Tooltip,
  Stack,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import api from "../../api/api";

import { formatError } from "../../helpers";

const ForceSciCitation = ({ citation, onEdit }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
    setError(null);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (forcedSci) => {
    setError(null);
    try {
      await api.put(
        "user/publication/setCitationForcedSci/" + citation.sourcePubId,
        {
          citation_id: citation._id,
          isSci: forcedSci,
        }
      );
      onEdit();
      handleClose();
    } catch (err) {
      setError(formatError(err));
    }
  };

  return (
    <div>
      <Tooltip title="Nastavenie SCI citacie">
        <IconButton aria-label="Nastav" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Box onSubmit={handleSubmit} component="form">
            <DialogTitle>Nastavenie SCI citacie</DialogTitle>
            <Stack direction={"row"} spacing={2}>
              <Button
                color="error"
                variant="contained"
                onClick={() => handleSubmit(1)}
              >
                NIE je SCI citácia
              </Button>
              <Button variant="contained" onClick={() => handleSubmit(2)}>
                Potvrď SCI citáciu
              </Button>
            </Stack>
            {error && (
              <Alert sx={{ m: 1, mb: 0, width: "100%" }} severity="error">
                {error}
              </Alert>
            )}
            <DialogActions>
              <Button onClick={handleClose} color="error">
                Zruš
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ForceSciCitation;
