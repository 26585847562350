import React from "react";

import {
  Card,
  Container,
  TextField,
  Typography,
  Box,
  Alert,
  Button,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import api from "../api/api";
import { formatError } from "../helpers";

const ResetPasswordFinalScreen = () => {
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const params = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    try {
      const response = await api.post(
        `/public/reset-password/${params.email}/${params.hash}`,
        {
          password: event.target.password.value,
          password_repeat: event.target.password_repeat.value,
        }
      );
      setSuccess(response.data.message);
    } catch (error) {
      setSuccess(null);
      setError(formatError(error));
    }
  };
  return (
    <Container component="main" maxWidth="sm">
      <Card sx={{ mt: "20%", mb: "20%" }}>
        <Box
          sx={{
            m: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h4 " sx={{ mb: 2 }}>
            Zadajte nové heslo
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            {success ? (
              <Alert variant="filled" severity="success">
                {success}
              </Alert>
            ) : (
              <React.Fragment>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Heslo"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password_repeat"
                  label="Overenie hesla"
                  type="password"
                  id="password_repeat"
                  autoComplete="current-password_repeat"
                />
                {error && (
                  <Alert variant="filled" severity="error">
                    {error}
                  </Alert>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Zmeň heslo
                </Button>
              </React.Fragment>
            )}
            <Box
              sx={{
                mt: 4,
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Link component={RouterLink} to="/login" variant="body2">
                Prihlásenie
              </Link>
              <Link component={RouterLink} to="/signup" variant="body2">
                Registruj sa
              </Link>
            </Box>
          </Box>
        </Box>
      </Card>
    </Container>
  );
};

export default ResetPasswordFinalScreen;
