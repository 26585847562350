import { utils, writeFile } from "xlsx";
import {
  conference,
  impacts,
  monografie,
  patenty,
  restPubs,
} from "constants/scienceExportConstants";
import { toFixedFloat } from "helpers";

export const handleUserDataExport = async (
  yearsRange,
  publications,
  author
) => {
  const pubs = [];
  let pubsCount = 0;
  const citations = [];
  let citationsCount = 0;
  const codesOrder = [
    ...monografie,
    ...impacts,
    ...patenty,
    ...conference,
    ...restPubs,
  ];

  if (publications) {
    pubs.push({
      A: "Kategória/rok",
      B: "Autori",
      C: "Názov publikácie",
      C1: "Zdrojový dokument",
      C2: "Kvartil",
      D: "Počet citácií",
      E: "Záznam",
      F: "Databázy",
    });
    citations.push({
      A: "Zdrojový dokument",
      B: "Rok publikácie / citácie",
      C: "Citácia",
      D: "Databázy",
    });
    for (const codes of codesOrder) {
      const tmpPubs = [];
      for (const record of publications) {
        if (yearsRange !== undefined) {
          if (yearsRange.indexOf(record.epcaRok) === -1) {
            continue;
          }
        }
        if (codes === record.epcaKod) {
          pubsCount++;
          let C1 = "";
          if (record.sourceName !== "") {
            C1 = `${record.sourceName}, ${record.sourceIdentification}`;
          }
          let authorsLabel = "";
          for (const au of record.authors) {
            const splits = au.name.split(" ");
            if (splits.length < 1) {
              continue;
            }
            authorsLabel += splits[0] + " " + splits[1][0] + ".,";
          }
          let dbNames = "";
          if (record.isWOS) {
            dbNames += "WOS";
          }
          if (record.isScopus) {
            if (dbNames !== "") {
              dbNames += ", ";
            }
            dbNames += "SCOPUS";
          }

          tmpPubs.push({
            A: record.epcaRok,
            B: record.customAuthorsList,
            C: record.title,
            C1,
            C2: record.quartil !== "undefined" ? record.quartil : "",
            D: record.citations.length,
            E: `${authorsLabel}: ${record.title}, ${C1}, ${record.epcaRok}`,
            F: dbNames,
          });
          record.added = true;
        }
      }
      if (tmpPubs.length > 0) {
        pubs.push({
          A: codes,
          B: tmpPubs.length,
        });
        pubs.push(...tmpPubs);
        pubs.push({});
      }
    }
    const citetHighest = publications.sort(
      (a, b) => a.citations.length < b.citations.length
    );

    for (const record of citetHighest) {
      const yearRangeCitation = [];
      if (yearsRange !== undefined) {
        yearRangeCitation.push(
          ...record.citations.filter((item) => {
            return yearsRange.indexOf(item.year) !== -1;
          })
        );
      } else {
        yearRangeCitation.push(...record.citations);
      }

      if (yearRangeCitation.length > 0) {
        citationsCount += yearRangeCitation.length;
        citations.push({
          A: record.title,
          B: record.epcaRok,
          C: yearRangeCitation.length,
        });
        const sortedCitation = yearRangeCitation.sort((a, b) => {
          return b.year - a.year;
        });
        citations.push(
          ...sortedCitation.map((cit) => {
            return {
              A: "",
              B: cit.year,
              C: cit.source,
              D: cit.index,
            };
          })
        );
      }
    }

    pubs.push({});
    pubs.push({
      A: "Spolu publikácií",
      B: pubsCount,
    });
    pubs.push({
      A: "Spolu citácií",
      B: citationsCount,
    });

    const ws = utils.json_to_sheet(pubs, { skipHeader: true });
    const ws2 = utils.json_to_sheet(citations, { skipHeader: true });
    ws["!cols"] = [
      { width: 10 },
      { width: 20 },
      { width: 40 },
      { width: 30 },
      { width: 10 },
    ];
    ws2["!cols"] = [{ width: 25 }, { width: 10 }, { width: 50 }];
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "publikacie");
    utils.book_append_sheet(wb, ws2, "citacie");
    let titleYear = "";
    if (yearsRange !== undefined) {
      if (yearsRange.length === 1) {
        titleYear = `-${yearsRange[0]}`;
      } else {
        titleYear = `-${yearsRange.length}-rokov`;
      }
    }
    writeFile(wb, `pub${titleYear}-${author.label}.xlsx`);
  }
};
const formatCreatedAt = (createdAt) => {
  const str = createdAt.toString();
  return `${str.substr(6, 2)}.${str.substr(4, 2)}.${str.substr(0, 4)}`;
};
const getRowObj = (row, index) => {
  let proportion = 0;
  if (row.pointsShare !== 0) {
    proportion = (100 * row.pointsShare) / row.points;
  }
  let databases = "";
  if (row.isScopus) {
    databases = "Scopus";
  }
  if (row.isWOS) {
    databases += ",WOS";
  }

  return {
    A: row.epcaKod,
    B: index + 1,
    C: row.customAuthorsList,
    D: row.title,
    E: row.sourceName,
    F: databases,
    G: row.quartil !== "undefined" ? row.quartil : "",
    H: row.points,
    I: proportion,
    J: row.pointsShare,
    K: formatCreatedAt(row.cratedAt),
  };
};
const getRowCitataion = (row, writeSourcePubData) => {
  let A = "";
  if (writeSourcePubData) {
    A = `${row.authorsList}: ${row.sourcePublication}`;
  }
  return {
    A,
    B: row.source,
    C: row.proportion / 100,
    D: row.points,
    E: row.posibleSCI ? "Možné SCI, over ISSN" : "",
  };
};

const isDepAuthor = (depData, author) => {
  for (const aff of depData.affiliation) {
    if (author.affiliation.indexOf(aff) !== -1) {
      return true;
    }
  }
  return false;
};

export const calcAuthorDepPointShare = (pub) => {
  let extShare = 0;
  let allShare = 0;
  for (const a of pub.authors) {
    allShare += a.proportion;
    if (
      !a.claimAuthorShare &&
      (a.affiliation === "EXT" || a.affiliation === "N/A")
    ) {
      extShare += a.proportion;
    }
  }
  if (allShare !== 100) {
    pub.authorProportion += (100 - allShare) / 100;
  }
  extShare = extShare / 100;
  const localShare = 1 - extShare - pub.authorProportion;
  const pp = pub.authorProportion / (pub.authorProportion + localShare);

  pub.authorPoints = toFixedFloat(
    pub.points * (pp * extShare + pub.authorProportion)
  );
};

export const handleUserScienceOutputs = async (
  publications,
  citations,
  depData,
  year
) => {
  const authors = [];
  for (const pub of publications) {
    for (const a of pub.authors) {
      if (!isDepAuthor(depData, a)) {
        continue;
      }
      let add = true;
      for (const author of authors) {
        if (a.authorId === author.authorId) {
          add = false;
          break;
        }
      }
      if (add) {
        authors.push({ ...a, pubPoints: 0, citPoints: 0 });
      }
    }
  }
  for (const author of authors) {
    for (const pub of publications) {
      let validPub = false;
      for (const a of pub.authors) {
        if (a.authorId === author.authorId) {
          pub.authorProportion = a.proportion / 100;
          validPub = true;
          break;
        }
      }
      if (validPub) {
        calcAuthorDepPointShare(pub);
        author.pubPoints += pub.authorPoints;
      }
    }
    for (const cit of citations) {
      for (const a of cit.authors) {
        if (a.authorId === author.authorId) {
          const pointsShare = (cit.fullPoints * a.proportion) / 100;
          author.citPoints += pointsShare;
          break;
        }
      }
    }
    author.sum = author.pubPoints + author.citPoints;
  }
  let sortedAuthors = authors.sort((a, b) => {
    return b.pubPoints - a.pubPoints;
  });

  const ws = utils.json_to_sheet(
    [
      {
        A: "Osoba",
        B: "Body publikácie",
        C: "Body citácie",
        D: "Body spolu",
      },
    ],
    { skipHeader: true }
  );
  utils.sheet_add_json(
    ws,
    sortedAuthors.map((item) => {
      return {
        A: item.name,
        B: item.pubPoints,
        C: item.citPoints,
        D: item.sum,
      };
    }),
    { skipHeader: true, origin: `A2` }
  );
  ws["!cols"] = [{ width: 30 }, { width: 15 }, { width: 15 }, { width: 10 }];
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Vedecké výstupy");
  // return;
  writeFile(wb, `vedecke-vystupy-${depData.shortcut}-${year}.xlsx`);
};

export const handleDepartnemtDataExport = (
  publications,
  citations,
  selectedDep,
  year
) => {
  let row_counter = 2;
  let tmp_row = 2;
  const rowSpace = 5;

  const ws = utils.json_to_sheet(
    [
      {
        A: "Kategoria",
        B: "P.C",
        C: "Autori",
        D: "Nazov",
        E: "Zdrojovy dokument",
        F: "Databazy",
        G: "Kvartil",
        H: "Planovane body",
        I: "Podiel katedry",
        J: "Body",
        K: "Datum pridania do kniznice",
      },
    ],
    { skipHeader: true }
  );
  tmp_row = row_counter;

  utils.sheet_add_json(
    ws,
    publications
      .filter((item) => impacts.indexOf(item.epcaKod) !== -1)
      .map((row, index) => {
        row_counter++;
        return getRowObj(row, index);
      }),
    { skipHeader: true, origin: `A${tmp_row}` }
  );
  row_counter += rowSpace;
  tmp_row = row_counter;

  utils.sheet_add_json(
    ws,
    publications
      .filter((item) => monografie.indexOf(item.epcaKod) !== -1)
      .map((row, index) => {
        row_counter++;
        return getRowObj(row, index);
      }),
    { skipHeader: true, origin: `A${tmp_row}` }
  );
  row_counter += rowSpace;
  tmp_row = row_counter;

  utils.sheet_add_json(
    ws,
    publications
      .filter((item) => conference.indexOf(item.epcaKod) !== -1)
      .map((row, index) => {
        row_counter++;
        return getRowObj(row, index);
      }),
    { skipHeader: true, origin: `A${tmp_row}` }
  );
  row_counter += rowSpace;
  tmp_row = row_counter;

  utils.sheet_add_json(
    ws,
    publications
      .filter((item) => patenty.indexOf(item.epcaKod) !== -1)
      .map((row, index) => {
        row_counter++;
        return getRowObj(row, index);
      }),
    { skipHeader: true, origin: `A${tmp_row}` }
  );
  row_counter += rowSpace;
  tmp_row = row_counter;

  utils.sheet_add_json(
    ws,
    publications
      .filter((item) => restPubs.indexOf(item.epcaKod) !== -1)
      .map((row, index) => {
        row_counter++;
        return getRowObj(row, index);
      }),
    { skipHeader: true, origin: `A${tmp_row}` }
  );

  utils.sheet_add_json(
    ws,
    [
      {
        J: {
          t: "n",
          f: `SUM(J2:J${row_counter - 1})`,
        },
      },
    ],
    { skipHeader: true, origin: `J${row_counter}` }
  );

  row_counter += rowSpace * 2;

  /// new sheet for citations

  row_counter = 1;

  const ws2 = utils.json_to_sheet(
    [
      {
        A: "Citacie SCI - koeficient 30",
      },
    ],

    { skipHeader: true, origin: `A${row_counter}` }
  );
  row_counter++;

  utils.sheet_add_json(
    ws2,
    [
      {
        A: "Názov, autori",
        B: "Citované v ",
        C: "Autorský podiel",
        D: "Body",
      },
    ],
    { skipHeader: true, origin: `A${row_counter}` }
  );
  let citSumStr = `SUM(D${row_counter + 1}:D`;

  const sci = [];
  const wos = [];
  const scopus = [];
  const sortedCitation = citations.sort((a, b) => a.source.localeCompare(b));
  let tmpSrcSci = "";
  let tmpSrcWos = "";
  let tmpSrcScopus = "";
  for (const cit of sortedCitation) {
    if (cit.sciVerified || cit.forcedSci === 2) {
      if (tmpSrcSci !== cit.sourcePublication) {
        sci.push(getRowCitataion(cit, true));
        tmpSrcSci = cit.sourcePublication;
      } else {
        sci.push(getRowCitataion(cit, false));
      }
    } else {
      if (cit.index.toLowerCase() === "wos") {
        if (tmpSrcWos !== cit.sourcePublication) {
          wos.push(getRowCitataion(cit, true));
          tmpSrcWos = cit.sourcePublication;
        } else {
          wos.push(getRowCitataion(cit, false));
        }
      } else {
        if (tmpSrcScopus !== cit.sourcePublication) {
          scopus.push(getRowCitataion(cit, true));
          tmpSrcScopus = cit.sourcePublication;
        } else {
          scopus.push(getRowCitataion(cit, false));
        }
      }
    }
  }

  utils.sheet_add_json(ws2, sci, {
    skipHeader: true,
    origin: `A${++row_counter}`,
  });
  row_counter += sci.length;
  if (sci.length > 0) {
    citSumStr = `${citSumStr}${row_counter - 1})`;
  } else {
    citSumStr = "0";
  }
  let citationTogether = `=D${row_counter}`;

  utils.sheet_add_json(
    ws2,
    [
      {
        A: "Spolu SCI",
        J: {
          t: "n",
          f: citSumStr,
        },
      },
    ],
    { skipHeader: true, origin: `C${row_counter}` }
  );
  row_counter += 5;

  /// Wos citacie
  utils.sheet_add_json(
    ws2,
    [
      {
        A: "Citacie WoS - koeficient 20",
      },
      {
        A: "Názov, autori",
        B: "Citované v ",
        C: "Autorský podiel",
        D: "Body",
      },
    ],
    { skipHeader: true, origin: `A${row_counter}` }
  );
  citSumStr = `SUM(D${row_counter + 1}:D`;

  utils.sheet_add_json(ws2, wos, {
    skipHeader: true,
    origin: `A${++row_counter}`,
  });
  row_counter += wos.length;
  citSumStr = `${citSumStr}${row_counter - 1})`;
  citationTogether += `+D${row_counter}`;

  utils.sheet_add_json(
    ws2,
    [
      {
        A: "Spolu WoS",
        J: {
          t: "n",
          f: citSumStr,
        },
      },
    ],
    { skipHeader: true, origin: `C${row_counter}` }
  );

  row_counter += 5;

  /// SCOPUS citacie
  utils.sheet_add_json(
    ws2,
    [
      {
        A: "Citacie Scopus - koeficient 10",
      },
      {
        A: "Názov, autori",
        B: "Citované v ",
        C: "Autorský podiel",
        D: "Body",
      },
    ],
    { skipHeader: true, origin: `A${row_counter}` }
  );
  citSumStr = `SUM(D${row_counter + 1}:D`;

  utils.sheet_add_json(ws2, scopus, {
    skipHeader: true,
    origin: `A${++row_counter}`,
  });
  row_counter += scopus.length;
  citSumStr = `${citSumStr}${row_counter - 1})`;
  citationTogether += `+D${row_counter}`;

  utils.sheet_add_json(
    ws2,
    [
      {
        A: "Spolu Scopus",
        J: {
          t: "n",
          f: citSumStr,
        },
      },
    ],
    { skipHeader: true, origin: `C${row_counter}` }
  );
  row_counter += 2;

  utils.sheet_add_json(
    ws2,
    [
      {
        A: "Spolu za citacie",
        J: {
          t: "n",
          f: citationTogether,
        },
      },
    ],
    { skipHeader: true, origin: `C${row_counter}` }
  );

  ws["!cols"] = [
    { width: 8 },
    { width: 6 },
    { width: 25 },
    { width: 30 },
    { width: 50 },
    { width: 10 },
    { width: 10 },
    { width: 15 },
    { width: 15 },
    { width: 10 },
    { width: 15 },
  ];
  ws2["!cols"] = [
    { width: 35 },
    { width: 50 },
    { width: 15 },
    { width: 10 },
    { width: 20 },
  ];
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "publikacie");
  utils.book_append_sheet(wb, ws2, "citacie");
  writeFile(wb, `pub-${selectedDep}-${year}.xlsx`);
};

export const handleWarehouseBalanceExport = (data, filename) => {
  const ws = utils.json_to_sheet(
    [
      {
        A: "Názov položky",
        B: "Cena položky [Eur]",
        C: "Pohyb",
        D: "Celkovo [Eur]",
      },
    ],
    { skipHeader: true }
  );
  utils.sheet_add_json(
    ws,
    data.map((item, index) => {
      return {
        A: item.title,
        B: item.price,
        C: item.count,
        D: {
          t: "n",
          f: `=B${index + 2}*C${index + 2}`,
        },
      };
    }),
    { skipHeader: true, origin: `A2` }
  );
  utils.sheet_add_json(
    ws,
    [{ A: { t: "n", f: `=sum(D2:D${data.length + 1})` } }],
    {
      skipHeader: true,
      origin: `D${data.length + 2}`,
    }
  );
  ws["!cols"] = [{ width: 45 }, { width: 20 }, { width: 15 }, { width: 15 }];
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Pohyb skladových zásob");
  // return;
  writeFile(wb, `${filename}.xlsx`);
};
