import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Stack,
  Typography,
  Checkbox,
} from "@mui/material";

import api from "../../api/api";
import SingUpFormular from "./SingUpFormular";
import { useTheme } from "@mui/material/styles";
import { formatError } from "../../helpers";

const AddUser = ({ onEdit }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [disableUsernameCheck, setDisableUsernameCheck] = useState(false);
  const [showSwitch, setShowSwitch] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!disableUsernameCheck) {
        await api.post("/public/checkUsername", {
          name: event.target.name.value,
          surname: event.target.surname.value,
        });
      }
      await api.post("/public/signup", {
        email: event.target.email.value,
        password: event.target.password.value,
        password_repeat: event.target.password_repeat.value,
        department: event.target.department.value,
        name: `${event.target.surname.value} ${event.target.name.value}`,
      });
      handleClose();
      onEdit(500);
    } catch (err) {
      setError(formatError(err));
      if (err.response.status === 409) {
        setShowSwitch(true);
      }
    }
  };

  return (
    <div>
      <Button sx={{ m: 1 }} variant="contained" onClick={handleClickOpen}>
        Pridaj používateľa
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ minWidth: matches ? 400 : 300 }}>
          <DialogTitle>Pridaj používateľa</DialogTitle>
          {showSwitch && (
            <Stack
              sx={{ px: 3, pt: 1 }}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography>Preskočiť kontrolu mena a vytvoriť účet</Typography>
              <Checkbox
                checked={disableUsernameCheck}
                onChange={(event) => {
                  setDisableUsernameCheck(event.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
          )}
          <SingUpFormular
            handleSubmit={handleSubmit}
            error={error}
            addingUser={true}
          />
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddUser;
