import React from "react";

import { TextField, Alert, Checkbox, FormControlLabel } from "@mui/material";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import deLocale from "date-fns/locale/de";

const FormularBalance = ({ values = {}, error }) => {
  const [outputDate, setOutputDate] = React.useState(new Date());
  const [isMinus, setIsMinus] = React.useState(true);
  const [note, setNote] = React.useState("");
  let countContent = (
    <TextField
      type="number"
      margin="normal"
      required
      id="count"
      name="count"
      label="Počet kusov"
      inputProps={{ step: "1" }}
      fullWidth
      defaultValue={Math.abs(values.data?.count[0].count) || 1}
      InputProps={{ inputProps: { min: 1 } }}
    />
  );
  if (values.categories?.length > 0) {
    countContent = values.categories.map((cat, index) => {
      let defVal = 0;
      if (values.data) {
        for (const c of values.data.count) {
          if (c.category === cat) {
            defVal = c.count;
            break;
          }
        }
      }
      return (
        <TextField
          key={index}
          type="number"
          margin="normal"
          required
          id={`count${cat}`}
          name={`count${cat}`}
          label={`Počet kusov ${cat}`}
          inputProps={{ step: "1" }}
          InputProps={{ inputProps: { min: 0 } }}
          fullWidth
          defaultValue={Math.abs(defVal)}
        />
      );
    });
  }
  React.useEffect(() => {
    if (values.data) {
      setOutputDate(values.data.date);
      setNote(values.data.note);
    }
  }, [values]);
  return (
    <React.Fragment>
      <FormControlLabel
        control={
          <Checkbox
            checked={isMinus}
            onChange={(value) => {
              setIsMinus(value.target.checked);
            }}
          />
        }
        label="Výdaj položiek"
        id="isMinus"
        name="isMinus"
        labelPlacement="start"
        sx={{ justifyContent: "space-between", display: "flex" }}
      />
      <TextField
        required
        margin="normal"
        id="note"
        name="note"
        label="Poznámka"
        fullWidth
        value={note}
        onChange={(event) => {
          setNote(event.target.value);
        }}
      />
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={deLocale}
      >
        <DatePicker
          label="Dátum"
          value={outputDate}
          onChange={(newValue) => {
            setOutputDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              required
              id="date"
              {...params}
              sx={{ mt: 2 }}
              fullWidth
            />
          )}
        />
      </LocalizationProvider>
      {countContent}
      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
    </React.Fragment>
  );
};

export default FormularBalance;
