import React, { useRef } from "react";

import { TextField, Alert, Button, Box, Typography } from "@mui/material";

const FormularItem = ({ values = {}, error }) => {
  const [pickedFile, setPickedFile] = React.useState("");
  const refInputFile = useRef();
  const imageChangeHandler = (event) => {
    setPickedFile(event.target.files[0].name);
  };
  return (
    <React.Fragment>
      <TextField
        margin="normal"
        required
        id="title"
        name="title"
        label="Názov položky"
        fullWidth
        defaultValue={values.title || ""}
      />
      <TextField
        type="number"
        margin="normal"
        required
        id="price"
        name="price"
        label="Predajná cena"
        inputProps={{ step: "0.01" }}
        fullWidth
        defaultValue={values.price || 1}
      />
      {/* <TextField type="file" /> */}
      <input
        type="file"
        accept="image/*"
        id="image"
        name="image"
        ref={refInputFile}
        style={{ display: "none" }}
        onChange={imageChangeHandler}
      />
      <Box display={"flex"} flexDirection={"row"}>
        <Button
          variant="contained"
          color="info"
          sx={{ mr: 1 }}
          onClick={() => {
            refInputFile.current.click();
          }}
        >
          Načítaj obrázok
        </Button>
        <Typography sx={{ mt: 1 }}>{pickedFile}</Typography>
      </Box>
      <TextField
        margin="normal"
        id="note"
        name="note"
        label="Poznámka"
        fullWidth
        defaultValue={values.note || ""}
      />

      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
    </React.Fragment>
  );
};

export default FormularItem;
