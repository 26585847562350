import React, { useCallback, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import api from "../../api/api";
import { formatError } from "../../helpers";
import FormularOutput from "./FormularOutput";
import { useSelector } from "react-redux";

const AddOutput = ({ values, onAdd, showIcon = false }) => {
  const { authorizationLevel, department, name } = useSelector((state) => {
    return state.auth;
  });

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [completeOptionsUser, setCompleteOptionsUser] = useState([]);
  const [completeOptionsEvent, setCompleteOptionsEvent] = useState([]);

  const handleClickOpen = () => {
    getUsers();
    getEvents();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let userId = undefined;
      let eventId = undefined;
      if (authorizationLevel > 0) {
        for (const c of completeOptionsUser) {
          if (c.name === event.target.user.value) {
            userId = c._id;
            break;
          }
        }
      }
      for (const c of completeOptionsEvent) {
        if (c.title === event.target.eventId.value) {
          eventId = c._id;
          break;
        }
      }
      const data = {
        eventId,
        note: event.target.note.value,
        menDay: event.target.menDay.value,
        menDayWeekend: event.target.menDayWeekend.value,
        outputDate: event.target.outputDate.value,
        approved:
          authorizationLevel < 2 ? false : event.target.approved.checked,
      };
      if (data.approved) {
        data.approvedBy = name;
      }

      let url = "/user/output";
      if (authorizationLevel > 0) {
        url = "/katedra/output/" + userId;
      }
      const response = await api.post(url, data);
      if (response.status === 200) {
        handleClose();
        onAdd(500);
      }
    } catch (error) {
      setError(formatError(error));
    }
  };

  const getUsers = useCallback(async () => {
    try {
      let url = "";
      if (authorizationLevel === 1) {
        url = "/katedra/users/" + department;
      } else if (authorizationLevel > 1) {
        url = "/fakulta/users";
      }
      if (url !== "") {
        const response = await api.get(url);
        setCompleteOptionsUser(
          response.data.map((r) => {
            return {
              ...r,
              label: `${r.name}`,
            };
          })
        );
      }
    } catch (error) {
      alert(formatError(error));
    }
  }, [authorizationLevel, department]);

  const getEvents = useCallback(async () => {
    try {
      let url = "/user/events";
      if (authorizationLevel > 1) {
        url = "/fakulta/event";
      }
      const response = await api.get(url);
      const validEvents = response.data.filter((e) => {
        return new Date(e.eventDate) < new Date() || authorizationLevel > 1;
      });
      setCompleteOptionsEvent(
        validEvents.map((r) => {
          return {
            ...r,
            label: `${r.title}`,
          };
        })
      );
    } catch (error) {
      alert(formatError(error));
    }
  }, [authorizationLevel]);
  return (
    <React.Fragment>
      {showIcon ? (
        <Tooltip title="Pridaj výkon">
          <IconButton aria-label="uprav" size="small" onClick={handleClickOpen}>
            <AddCircleIcon fontSize="medium" color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          fullWidth
          sx={{ m: 1 }}
          variant="contained"
          onClick={handleClickOpen}
        >
          Pridaj výkon
        </Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Pridaj výkon</DialogTitle>
          <DialogContent>
            <FormularOutput
              values={values}
              error={error}
              completeOptionsUser={completeOptionsUser}
              completeOptionsEvent={completeOptionsEvent}
              role={authorizationLevel}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default AddOutput;
