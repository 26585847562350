import { Box, Typography } from "@mui/material";
import { generalText1, generalText2, generalText3 } from "./TextConstants";

const GeneralInfo = () => {
  const fontSize = "1.2rem";
  return (
    <Box sx={{ backgroundColor: "white", p: 2, borderRadius: "8px" }}>
      <Typography variant="h3" textAlign={"center"}>
        Všeobecné informácie o MAVE systéme
      </Typography>
      <Typography fontSize={fontSize} mt={1} textAlign={"justify"}>
        {generalText1}
      </Typography>
      <ul
        style={{
          fontSize: fontSize,
          padding: 0,
          paddingLeft: "2.5rem",
          margin: 5,
        }}
      >
        <li>Spravovanie výkonov publikačnej činnosti.</li>
        <li>Spravovanie výkonov marketingovej činnosti.</li>
      </ul>
      <Typography fontSize={fontSize} textAlign={"justify"}>
        {generalText2}
      </Typography>
      <Box sx={{ textAlign: "center", my: 1 }}>
        <Box
          component="img"
          src="/assets/images/veda.png"
          sx={{ width: "80%", height: "auto" }}
        />
      </Box>
      <Typography fontSize={fontSize} mt={1}>
        Aktualizovanie jednotlivých častí systému prebieha pravidlne v
        nasledovných intervaloch:
      </Typography>
      <ul
        style={{
          fontSize: fontSize,
          padding: 0,
          paddingLeft: "2.5rem",
          margin: 5,
        }}
      >
        <li>
          Načítanie dát z univerzitnej knižnice - <b>každý pondelok.</b>
        </li>
        <li>
          Aktualizácia bodov pre katedry v tabuľke historických hodnôt -{" "}
          <b>každý pondelok.</b>
        </li>
        <li>
          Aktualizovanie tabuľky Body do rozpočtu - <b>každý pondelok.</b>
        </li>
        <li>
          Aktualizovanie informácii o kvartiloch žurnálov -{" "}
          <b>prvý deň v mesiaci.</b>
        </li>
        <li>
          Aktualizovanie bodov za články (v prípade zmeny indexácie, získania
          kvartilu časopisu a pod.) - <b>prvý deň v mesiaci.</b>
        </li>
        <li>
          Kontrola indexácie publikácií <b>prvý deň v mesiaci.</b> (proces
          kontroly môže trvať aj 7 dní, podľa počtu publikácií)
        </li>
      </ul>
      <Typography fontSize={fontSize} mt={1} textAlign={"justify"}>
        {generalText3}
      </Typography>
    </Box>
  );
};

export default GeneralInfo;
