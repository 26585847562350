import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import api from "api/api";
import { defaultTableOptions } from "constants/tableTexts";
import MUIDataTable from "mui-datatables";
import React, { useCallback, useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import AddPlanItem from "./AddPlanItem";
import DeleteItemDialog from "components/universal/DeleteItemDialog";
import UpdatePublicationStatus from "./UpdatePublicationStatus";
import MovePlanPublication from "./MovePlanPublication";
import { exportExcel, formatError } from "helpers";
import { publicationStatus, displayTypes } from "constants/planConstants";
import ShowPubPlanHistory from "./ShowPubPlanHistory";
import { useNavigate } from "react-router-dom";

/*
@@TODO@
sparovanie publikacie s existujucou
*/

const wholeDep = "Celý útvar";

const PublicationPlan2 = ({ authData }) => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [displayType, setDisplayType] = useState(displayTypes[0]);
  const [pickedDep, setPickedDep] = useState("");
  const [selectedAuthor, setSelectedAuthor] = useState(wholeDep);
  const [deps, setDeps] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const currentyear = new Date().getFullYear() + 1;
  const years = Array(currentyear - 2022)
    .fill("")
    .map((v, idx) => currentyear - idx);

  const navigate = useNavigate();

  let myPoints = 0;
  let departmentPoints = 0;
  let myPointsAccepted = 0;
  let departmentPointsAccepted = 0;
  let currentAff = [];
  let currentDep = authData.department;
  let currentAuthorId = authData.authorId;
  if (pickedDep !== "") {
    currentDep = pickedDep;
  }

  for (const dep of deps) {
    if (!dep.active) {
      continue;
    }
    if (dep.shortcut === currentDep) {
      currentAff = [...dep.affiliation];
      break;
    }
  }

  let validRecords = records.filter((item) => {
    if (showDelete) return true;
    return !item.deleted;
  });

  const filterAuthors = [];
  for (const r of validRecords) {
    for (const a of r.authors) {
      if (currentAff.length > 0) {
        if (currentAff.indexOf(a.affiliation.substring(3)) === -1) {
          continue;
        }
      }
      if (
        filterAuthors.find(
          (item) => item.label === `${a.name} ${a.lastName}`
        ) === undefined
      ) {
        filterAuthors.push({
          label: `${a.name} ${a.lastName}`,
          authorId: a.authorId,
        });
        if (selectedAuthor) {
          if (a.label.indexOf(selectedAuthor) !== -1) {
            currentAuthorId = a.authorId;
          }
        }
      }
    }
  }

  for (const r of validRecords) {
    let myProportion = 0;
    let depProportion = 0;
    if (r.deleted) {
      continue;
    }
    for (const a of r.authors) {
      if (a.authorId === currentAuthorId) {
        myProportion = a.proportion;
      }
      if (
        currentAff.indexOf(a.affiliation.substring(3)) !== -1 ||
        pickedDep === "all"
      ) {
        depProportion += a.proportion;
      }
    }
    myPoints += (r.pointsExpectation * myProportion) / 100;
    departmentPoints += (r.pointsExpectation * depProportion) / 100;

    if (
      r.publicationStatus === publicationStatus[2] ||
      r.publicationStatus === publicationStatus[3]
    ) {
      myPointsAccepted += (r.pointsExpectation * myProportion) / 100;
      departmentPointsAccepted += (r.pointsExpectation * depProportion) / 100;
    }
  }

  const filtered = validRecords
    .filter((item) => {
      const retVal =
        item.authors.find((a) => {
          return a.authorId === currentAuthorId;
        }) !== undefined;
      return retVal || selectedAuthor === wholeDep;
    })
    .map((item) => {
      let myProportion = 0;
      for (const a of item.authors) {
        if (a.authorId === currentAuthorId) {
          myProportion = a.proportion;
        }
      }
      item.myPointsExpectation = (item.pointsExpectation * myProportion) / 100;
      return item;
    });

  const columns = [
    {
      name: "title",
      label: "Názov",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => {
          return {
            style: { minWidth: 150 },
          };
        },
      },
    },
    {
      name: "authors",
      label: "Autori",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => {
          return {
            style: { minWidth: 150 },
          };
        },
        customBodyRender: (value) => {
          return value
            .map((item) => {
              return `${item.name} ${item.lastName} - ${
                item.proportion
              }% [${item.affiliation.substring(6)}]`;
            })
            .join(", ");
        },
        filterType: "checkbox",
        filterOptions: {
          names: filterAuthors,
          logic(authors, filterVal) {
            for (const fval of filterVal) {
              if (authors.indexOf(fval) !== -1) {
                return false;
              }
            }
            return true;
            // const show = filterVal.indexOf(quartil) >= 0;
            // return !show;
          },
        },
      },
    },
    {
      name: "epcaKod",
      label: "Kód",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "sourceId",
      label: "Cieľ",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const data = filtered[dataIndex];
          let text = "";
          if (data.sourceId) {
            text = data.sourceId.sourceName;
          } else {
            text = data.conferenceName;
          }
          return text;
        },
        setCellProps: () => {
          return {
            style: { minWidth: 250 },
          };
        },
      },
    },
  ];
  if (showDelete) {
    columns.push({
      name: "deleted",
      label: "Zmazaný záznam",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const data = filtered[dataIndex];
          return (
            <Box
              sx={{
                bgcolor: data.deleted ? "red" : "transparent",
                px: 1,
                py: 0.5,
                borderRadius: 4,
              }}
            >
              <Typography>{data.deleted ? "Zmazané" : ""}</Typography>
            </Box>
          );
        },
      },
    });
  }
  if (showAll) {
    columns.push(
      ...[
        {
          name: "isCurrentContent",
          label: "CCC",
          options: {
            filter: true,
            sort: true,
            filterType: "checkbox",
            filterOptions: {
              names: ["CCC-Ano", "CCC-Nie"],
              logic(isCurrentContent, filterVal) {
                const show =
                  (filterVal.indexOf("CCC-Ano") >= 0 && isCurrentContent) ||
                  (filterVal.indexOf("CCC-Nie") >= 0 && !isCurrentContent);
                return !show;
              },
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              return value ? <CheckIcon /> : <ClearIcon />;
            },
          },
        },
        {
          name: "isWOS",
          label: "WOS",
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const data = filtered[dataIndex];
              return data.isWOS ? <CheckIcon /> : <ClearIcon />;
            },
          },
        },
        {
          name: "isScopus",
          label: "Scopus",
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const data = filtered[dataIndex];
              return data.isScopus ? <CheckIcon /> : <ClearIcon />;
            },
          },
        },
        {
          name: "isForeighnLanguage",
          label: "Zahraničný jazyk",
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: (dataIndex) => {
              const data = filtered[dataIndex];
              return data.isForeighnLanguage ? <CheckIcon /> : <ClearIcon />;
            },
          },
        },
      ]
    );
  }
  columns.push({
    name: "pointsExpectation",
    label: "Body",
    options: {
      filter: false,
      sort: true,
    },
  });
  if (displayType === displayTypes[0]) {
    columns.push({
      name: "myPointsExpectation",
      label: "Moje body",
      options: {
        filter: false,
        sort: true,
      },
    });
  }
  columns.push(
    ...[
      {
        name: "publicationStatus",
        label: "Stav",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            let bgcolor = "#fc5f5f";
            if (value === publicationStatus[1]) {
              bgcolor = "#f0f481";
            } else if (value === publicationStatus[2]) {
              bgcolor = "#92ef9a";
            } else if (value === publicationStatus[3]) {
              bgcolor = "#78c4ed";
            }
            return (
              <Box sx={{ bgcolor, borderRadius: 2, px: 1, py: 0.3 }}>
                <Typography sx={{ fontWeight: 600 }}>{value}</Typography>
              </Box>
            );
          },
        },
      },
      {
        name: "note",
        label: "Poznámka",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "btns",
        label: "Akcie",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const data = filtered[dataIndex];
            let showMoveIcon = true;
            if (data.epcaRok >= new Date().getFullYear() + 1) {
              showMoveIcon = false;
            }
            if (
              data.publicationStatus === publicationStatus[2] ||
              data.publicationStatus === publicationStatus[3]
            ) {
              showMoveIcon = false;
            }
            return (
              <Stack direction="row">
                <AddPlanItem
                  showIcon={true}
                  authData={authData}
                  load={load}
                  year={year}
                  values={data}
                />
                <UpdatePublicationStatus values={data} onEdit={load} />
                <ShowPubPlanHistory values={data.history} />
                {showMoveIcon && (
                  <MovePlanPublication
                    title={"Presunutie publikácie do nasledovného obdobia"}
                    secondaryText={`Naozaj chcete presunúť publikáciu s názvom: ${data.title} do nasledovného obdobia?`}
                    onConfirm={() => handlePubMove(data)}
                  />
                )}
                <DeleteItemDialog
                  title={"Naozaj chcete zmazať plánovanú publikáciu?"}
                  onDelete={() => {
                    deletePublication(data._id);
                  }}
                />
              </Stack>
            );
          },
        },
      },
    ]
  );

  const handlePubMove = async (publication) => {
    try {
      await api.put("/user/publicationPlan/" + publication._id, {
        epcaRok: publication.epcaRok + 1,
      });
      load();
    } catch (error) {
      alert(formatError(error));
    }
  };

  const handleDepChange = (event) => {
    setLoading(true);
    setPickedDep(event.target.value);
  };
  const handleTypeChange = (event) => {
    setLoading(true);
    setDisplayType(event.target.value);
    setPickedDep("");
    setSelectedAuthor(wholeDep);
  };

  const handleChange = (event) => {
    setLoading(true);
    setYear(event.target.value);
  };

  const deletePublication = async (id) => {
    try {
      await api.delete("/user/publicationPlan/" + id);
      load();
    } catch (error) {
      alert(formatError(error));
    }
  };

  const load = useCallback(async () => {
    if (authData.authorId === "") {
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      let url = "/user/publicationPlan?year=" + year;
      if (displayType === displayTypes[1]) {
        url +=
          "&userDepartment=" +
          (pickedDep !== "" ? pickedDep : authData.department);
      }
      let response = await api.get(url);
      setRecords(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(formatError(error));
    }
  }, [year, displayType, authData.department, authData.authorId, pickedDep]);

  const loadDeps = useCallback(async () => {
    try {
      let url = "/user/department";
      let response = await api.get(url);
      setDeps(response.data.filter((item) => item.active));
    } catch (error) {
      alert(formatError(error));
    }
  }, []);

  useEffect(() => {
    load();
    loadDeps();
  }, [load, loadDeps]);

  let infoContent = (
    <Grid
      item
      lg
      sx={{
        justifyContent: "flex-end",
        alignSelf: "flex-end",
        display: "flex",
      }}
    >
      <Typography variant="h6" sx={{ px: 1 }}>
        {selectedAuthor === wholeDep && displayType !== displayTypes[0]
          ? `Katedra body plan: ${departmentPoints} z toho akceptované: ${departmentPointsAccepted}`
          : `Autor body plan: ${myPoints} z toho akceptované: ${myPointsAccepted}`}
      </Typography>
    </Grid>
  );
  let depPickerContent = null;
  if (authData.isScienceManager && displayType === displayTypes[1]) {
    depPickerContent = (
      <Grid item xs={1.5}>
        <FormControl fullWidth>
          <InputLabel id="simple-select-label">Vyberte katedru</InputLabel>
          <Select
            fullWidth
            labelId="simple-select-label"
            value={pickedDep}
            label="Vyberte katedru"
            onChange={handleDepChange}
          >
            <MenuItem key={33} value={"all"}>
              Všetky
            </MenuItem>
            {deps.map((d, i) => (
              <MenuItem key={i} value={d.shortcut}>
                {d.shortcut}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  }
  let authorPickerContent = null;
  if (displayType === displayTypes[1]) {
    authorPickerContent = (
      <Grid item xs={2}>
        <Autocomplete
          disablePortal
          id="simple-select-label"
          options={[{ label: wholeDep }, ...filterAuthors]}
          value={selectedAuthor}
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedAuthor(newValue.label);
            }
          }}
          renderInput={(params) => (
            <TextField
              id="selectedAuthor"
              required
              {...params}
              label="Vyberte autora"
            />
          )}
        />
      </Grid>
    );
  }

  let content = (
    <Card>
      <Grid py={1} px={1} container spacing={1}>
        <Grid item>
          <Typography mr={2} variant="h5" sx={{ mt: 1 }}>
            Publikačný plán
          </Typography>
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel id="simple-select-label">Rok</InputLabel>
            <Select
              labelId="simple-select-label"
              value={year}
              label="Rok"
              onChange={handleChange}
            >
              {years.length > 0 ? (
                years.map((d, i) => (
                  <MenuItem key={i} value={d}>
                    {d}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={year}>{year}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={1.5}>
          <FormControl fullWidth>
            <InputLabel id="simple-select-label">Typ zobrazenia</InputLabel>
            <Select
              fullWidth
              labelId="simple-select-label"
              value={displayType}
              label="Typ zobrazenia"
              onChange={handleTypeChange}
            >
              {displayTypes.map((d, i) => (
                <MenuItem key={i} value={d}>
                  {d}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {authorPickerContent}
        {depPickerContent}
        {infoContent}
      </Grid>
      <Grid py={1} px={1} container spacing={1}>
        {(authData.isScienceDepManager || authData.isScienceManager) && (
          <Grid item sx={{ alignSelf: "center" }}>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/scienceOutputs/manageAuthors");
              }}
            >
              Spravuj autorov
            </Button>
          </Grid>
        )}
        <Grid item sx={{ alignSelf: "center" }}>
          <AddPlanItem authData={authData} load={load} year={year} />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={showAll}
                onChange={(event) => {
                  setShowAll(event.target.checked);
                }}
              />
            }
            label="Zobraziť všetky podrobnosti publikácií"
            labelPlacement="start"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={showDelete}
                onChange={(event) => {
                  setShowDelete(event.target.checked);
                }}
              />
            }
            label="Zobraziť zmazané publikácií"
            labelPlacement="start"
          />
        </Grid>
      </Grid>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <MUIDataTable
        data={filtered}
        columns={columns}
        options={defaultTableOptions({
          sortOrder: {
            name: "pointsExpectation",
            direction: "desc",
          },
          onDownload: (buildHead, buildBody, columns, data) => {
            const wsCols = [40, 35, 8, 30, 8, 30, 30];
            exportExcel(
              "publiackny plan",
              "publiackny plan.xlsx",
              columns,
              data,
              wsCols.map((item) => {
                return { width: item };
              })
            );
            return false;
          },
        })}
      />
    </Card>
  );

  if (authData.authorId === "" && !authData.isScienceManager) {
    content = (
      <Card sx={{ p: 2, maxWidth: "40rem", margin: "0px auto" }}>
        <Typography variant="h6">
          Pre aktivovanie publikačného plánu je potrebné mať ku svojmu účtu
          priradeného autora z knižnice. Pre priradenie kontaktujte svojich
          katedrových koordinátorov pre publikačnú činnosť.
          <Typography variant="h6" color={"red"}>
            Po priradení autora je potrebné sa odhlásiť a prihlásiť do systému.
          </Typography>
        </Typography>
      </Card>
    );
  }

  return content;
};

export default PublicationPlan2;
