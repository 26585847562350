import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Card,
  TextField,
  InputAdornment,
  IconButton,
  CardContent,
  List,
  LinearProgress,
  Grid,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";

import api from "../api/api";
import {
  formatError,
  replaceDiacritics,
  translateActivityBase,
} from "../helpers";
import DelegateInput from "../components/manageInputs/DelegateInput";
import AddInput from "../components/manageInputs/AddInput";
import Loader from "../components/universal/Loader";

const ManageInputs = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const load = useCallback(async (delay) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        const response = await api.get("/dekan/getActivities");
        setRows(response.data);
      } catch (err) {
        alert(formatError(err));
      }
      setLoading(false);
    }, delay || 10);
  }, []);

  const filteredRows = rows.filter((row) => {
    const searchStr = `${replaceDiacritics(row.example)} ${replaceDiacritics(
      row.type
    )} ${row.coefficient}  ${replaceDiacritics(
      row.description
    )} ${replaceDiacritics(translateActivityBase(row.base))}`;
    return searchStr.includes(replaceDiacritics(searchText));
  });

  let activityContent = (
    <List>
      {filteredRows.map((item) => {
        return <DelegateInput key={item._id} delegateData={item} load={load} />;
      })}
    </List>
  );

  let content = (
    <Box>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Card>
        <Box
          align="center"
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <TextField
            id="outlined-basic"
            label="Vyhľadaj"
            variant="outlined"
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setSearchText("");
                    }}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <CardContent>{activityContent}</CardContent>
      </Card>
    </Box>
  );

  useEffect(() => {
    load();
  }, [load]);
  return (
    <Box sx={{ mt: 2, mb: 10 }}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h4">Šablóny aktivít</Typography>
        </Grid>
        <Grid item>
          <AddInput onAdd={load} />
        </Grid>
      </Grid>
      <Card>
        {content}
        {false && <Loader open={loading} />}
      </Card>
    </Box>
  );
};

export default ManageInputs;
