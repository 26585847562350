import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditUserForm from "./EditUserForm";

import api from "../../api/api";
import { formatError } from "../../helpers";

const EditUser = ({ values, onEdit }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.put("/admin/user/" + values._id, {
        personCategory: event.target.personCategory.value,
        department: event.target.department.value,
        authorizationLevel: event.target.authorizationLevel.value,
        name: `${event.target.surname.value} ${event.target.name.value}`,
      });
      handleClose();
      onEdit(500);
    } catch (err) {
      alert(formatError(err));
    }
  };

  return (
    <div>
      <Tooltip title="Uprav">
        <IconButton aria-label="uprav" size="small" onClick={handleClickOpen}>
          <EditIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <DialogTitle>Úprava používateľa</DialogTitle>
            <DialogContent>
              <EditUserForm values={values} error={error} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="error">
                Zruš
              </Button>
              <Button type="submit">Potvrď</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditUser;
