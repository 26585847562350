import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Container } from "@mui/material";
import api from "api/api";
import Table from "components/universal/Table";
import { Stack } from "@mui/system";
import AddJournal from "./AddJournal";

export default function JournalList({ authData, ...rest }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const updatePoints = async () => {
    try {
      const response = await api.get("/science/recalculatePubPoints");
      if (response.status === 202) {
        alert("Prepočítavanie bodov bolo spustené na pozadí");
      } else {
        alert("Chyba pri zadavaní požiadavky");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const fetchData = useCallback(async () => {
    const response = await api.get("/user/journal");
    response.data = response.data.map((data) => {
      if (data.quartils.length > 0) {
        data.lastQuartil = data.quartils[data.quartils.length - 1].q;
        data.quartils = data.quartils
          .map((d) => (d = d.year + " " + d.q))
          .join(", ");
      } else {
        data.quartils = "";
        data.lastQuartil = "";
      }
      return data;
    });
    setData(response.data);
    setLoading(false);
  }, [setData, setLoading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const showEditJournal = () => {
    return authData.isScienceManager || authData.isAdmin;
  };

  const settings = {
    rows: data,
    loading: loading,
    dense: true,
    colOrder: {
      order: "desc",
      orderBy: "sourceName",
    },
    headCells: [
      {
        id: "sourceName",
        numeric: false,
        label: "Názov",
        search: true,
      },
      {
        id: "sourceIdentification",
        numeric: false,
        label: "Identifikácia",
        search: true,
      },
      {
        id: "sourceOrgId",
        numeric: false,
        label: "Id organizácie",
        search: true,
      },
      {
        id: "quartils",
        numeric: false,
        label: "Kvartily",
        search: true,
      },
      {
        id: "lastQuartil",
        numeric: false,
        label: "Posledný kvartil",
        search: true,
      },
      {
        id: "btns",
        render: (data) => (
          <Stack direction="row">
            <AddJournal onEdit={fetchData} showIcon={true} />
          </Stack>
        ),
      },
    ],
  };

  return (
    <Container maxWidth="lg">
      <Card>
        <Stack spacing={2} direction="row" p={2}>
          <AddJournal onEdit={fetchData} />
          {showEditJournal() && (
            <Button variant="contained" onClick={updatePoints}>
              Aktualizuj body pre publikácie
            </Button>
          )}
        </Stack>
        <Table settings={settings} />
      </Card>
    </Container>
  );
}
