const textLabels = {
  body: {
    noMatch: "Žiadny záznam nevyhovuje zadaným kritériám",
    toolTip: "Zoradiť",
    columnHeaderTooltip: (column) => `Zoradené podľa ${column.label}`,
  },
  pagination: {
    next: "Nasledujúca",
    previous: "Predchádzajúca",
    rowsPerPage: "Riadky na stránku:",
    displayRows: "z",
  },
  toolbar: {
    search: "Vyhľadaj",
    downloadCsv: "Stiahni Excel súbor",
    print: "Tlačiť",
    viewColumns: "Zobrazené stĺpce",
    filterTable: "Filtrovanie tabuľky",
  },
  filter: {
    all: "All",
    title: "FILTERS",
    reset: "RESET",
  },
  viewColumns: {
    title: "Show Columns",
    titleAria: "Show/Hide Table Columns",
  },
  selectedRows: {
    text: "row(s) selected",
    delete: "Delete",
    deleteAria: "Delete Selected Rows",
  },
};

export const defaultTableOptions = (values = {}) => {
  const option = {
    filterType: "checkbox",
    density: "compact",
    size: "small",
    searchOpen: true,
    selectableRows: "none",
    rowsPerPage: 100,
    rowsPerPageOptions: [50, 100, 200],
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    // setRowProps: (row, dataIndex, rowIndex) => {
    //   return {
    //     style: {
    //       backgroundColor: "red",
    //     },
    //   };
    // },
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    textLabels,
  };
  return {
    ...option,
    ...values,
  };
};
