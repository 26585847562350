import React, { useCallback, useEffect } from "react";

import { calcOuputPerDep, departments } from "../../helpers";
import Table from "../universal/Table";

const DirectActivities = ({ data, useOnlyApproved }) => {
  const [rows, setRows] = React.useState([]);

  const prepareData = useCallback(() => {
    const sumArray = {};
    for (const d of departments) {
      if (d !== "FEIT") {
        sumArray[d] = 0;
      }
    }
    let counter = 0;
    const arr = data.activities.map((a) => {
      const perDep = calcOuputPerDep(a.outputs, useOnlyApproved);
      for (const p in perDep) {
        sumArray[p] += perDep[p];
      }
      return {
        type: a.type,
        coefficient: a.coefficient,
        coefficientDescription: a.description,
        counter: ++counter,
        ...perDep,
      };
    });

    arr.push({
      type: "Suma priamo [človek/deň]",
      counter: ++counter,
      ...sumArray,
    });
    setRows(arr);
  }, [data, useOnlyApproved]);

  const head = [
    {
      id: "type",
      numeric: false,
      label: "Typ aktivity",
      search: false,
    },
    {
      id: "coefficient",
      numeric: true,
      label: "Koeficient",
      search: false,
    },
    {
      id: "coefficientDescription",
      numeric: true,
      label: "Opis koeficientu",
      search: false,
    },
  ];
  for (const d of departments) {
    if (d !== "FEIT") {
      head.push({
        id: d,
        numeric: true,
        label: d,
        search: false,
      });
    }
  }
  head.push({
    id: "counter",
    numeric: true,
    label: "Por. číslo",
    search: false,
  });

  const settings = {
    rows: rows,
    loading: false,
    colOrder: {
      order: "desc",
      orderBy: "counter",
    },
    dense: true,
    headCells: head,
  };

  useEffect(() => {
    prepareData();
  }, [prepareData]);

  return <Table settings={settings} showPagination={false} />;
};

export default DirectActivities;
