import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
} from "@mui/material";
import React from "react";
import { formatDate, formatError, translateActivityBase } from "../../helpers";
import DeleteItemDialog from "../universal/DeleteItemDialog";
import EditEvent from "./EditEvent";
import { useNavigate } from "react-router-dom";

import api from "../../api/api";

const EventInfo = ({ eventData = {}, completeOptions, load }) => {
  const navigate = useNavigate();

  const onDeleteHandler = async (eventId) => {
    try {
      const url = "/fakulta/event/";
      const response = await api.delete(url + eventId);
      if (response.status === 200) {
        navigate(-1);
      } else {
        alert("Chyba pri mazani aktivity.", response.status);
      }
    } catch (error) {
      alert(formatError(error));
    }
  };

  return (
    <Card sx={{ maxWidth: 800 }}>
      {eventData.title ? (
        <React.Fragment>
          <CardHeader
            title={`${eventData.title} - koeficient: ${eventData.coefficient}`}
            subheader={`${translateActivityBase(eventData.base)} - ${
              eventData.type
            }`}
            action={
              <Stack direction="row">
                <EditEvent
                  values={eventData}
                  completeOptions={completeOptions}
                  onEdit={load}
                />
                <DeleteItemDialog
                  title="Potvrdenie zmazanie aktivity"
                  secondaryText={`Názov aktivity: ${eventData.title}`}
                  onDelete={() => {
                    onDeleteHandler(eventData._id);
                  }}
                />
              </Stack>
            }
          />
          <CardContent>
            <Typography>
              Dátum začiatok: {formatDate(eventData.eventDate)}
            </Typography>
            <Typography>
              Dátum koniec: {formatDate(eventData.eventEndDate)}
            </Typography>
            <Typography>
              Uzávierka aktivity: {formatDate(eventData.outputDeadline)}
            </Typography>
          </CardContent>
        </React.Fragment>
      ) : (
        <CircularProgress sx={{ p: 5 }} size={80} color="secondary" />
      )}
    </Card>
  );
};

export default EventInfo;
