import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MyPublications from "../components/scienceOutput/MyPublications";
//import PublicationPlan from "../components/scienceOutput/PublicationPlan";
import DepartmentPublications from "../components/scienceOutput/DepartmentPublications";
import DepartmentBudget from "../components/scienceOutput/DepartmentBudget";
import JournalList from "components/scienceOutput/JournalList";
import ScienceCriteria from "components/scienceOutput/ScienceCriteria";
import DepartmentList from "components/scienceOutput/DepartmentList";
import { useDispatch, useSelector } from "react-redux";
import FacultyPublication from "components/scienceOutput/FacultyPublication";
import PublicationPlan2 from "components/scienceOutput/PublicationPlan2";
import { dataAction } from "store/data-slice";
import PuHandler from "components/scienceOutput/PuHandler";
import HistoryPoints from "components/scienceOutput/HistoryPoints";

export default function LabTabs() {
  const scienceTabPosition = useSelector((state) => {
    return state.data.scienceTabPosition;
  });
  const [value, setValue] = React.useState(scienceTabPosition || "0");
  const dispatch = useDispatch();

  const authData = useSelector((state) => {
    return state.auth;
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(dataAction.setScienceTabPosition(newValue));
  };

  const tabs = [
    {
      title: "Moje publikácie",
      element: <MyPublications authData={authData} />,
    },
    {
      title: "Publikačný plán",
      element: <PublicationPlan2 authData={authData} />,
    },
    {
      title: "Publikácie katedry",
      element: <DepartmentPublications authData={authData} />,
    },
    {
      title: "Publikácie fakulty",
      element: <FacultyPublication authData={authData} />,
    },
    {
      title: "História bodov",
      element: <HistoryPoints authData={authData} />,
    },
    {
      title: "Spravovanie D1",
      element: <PuHandler authData={authData} />,
    },
    {
      title: "Body do rozpočtu",
      element: <DepartmentBudget />,
    },
    {
      title: "Zoznam časopisov",
      element: <JournalList authData={authData} />,
    },
    {
      title: "Hodnotiace kritériá",
      element: <ScienceCriteria authData={authData} />,
    },
    {
      title: "Katedry",
      element: <DepartmentList authData={authData} />,
    },
  ];

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((o, i) => (
              <Tab
                key={i}
                label={o.title}
                value={String(i)}
                sx={{
                  fontSize: 13,
                  padding: 0.9,
                }}
              />
            ))}
          </TabList>
        </Box>
        {tabs.map((o, i) => (
          <TabPanel key={i} value={String(i)}>
            {o.element}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}
