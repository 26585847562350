import React from "react";
import { Navigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import EventsListScreen from "../pages/EventsListScreen";
import HomeScreen from "../pages/HomeScreen";
import ManageEventScreen from "../pages/ManageEventScreen";
import ManageEventsScreen from "../pages/ManageEventsScreen";
import ManageInputs from "../pages/ManageInputs";
import ManageOutputsScreen from "../pages/ManageOutputsScreen";
import ManageUsersScreen from "../pages/ManageUsersScreen";
import ScienceOutput from "../pages/ScienceOutput";
import UserEditScreen from "../pages/UserEditScreen";
import WarehouseItemScreen from "../pages/WarehouseItemScreen";
import WarehouseScreen from "../pages/WarehouseScreen";
import AdminPage from "pages/AdminPage";
import ManageAuthors from "components/scienceOutput/ManageAuthors";
import AboutPage from "pages/AboutPage";

const signinRoutes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "", element: <HomeScreen /> },
      { path: "manageUsers", element: <ManageUsersScreen /> },
      { path: "manageEvents", element: <ManageEventsScreen /> },
      { path: "manageEvents/:id", element: <ManageEventScreen /> },
      { path: "eventsList", element: <EventsListScreen /> },
      { path: "manageOutputs", element: <ManageOutputsScreen /> },
      { path: "manageInputs", element: <ManageInputs /> },
      { path: "warehouse", element: <WarehouseScreen /> },
      { path: "warehouse/:id", element: <WarehouseItemScreen /> },
      { path: "scienceOutputs", element: <ScienceOutput /> },
      { path: "scienceOutputs/manageAuthors", element: <ManageAuthors /> },
      { path: "systemAdmin", element: <AdminPage /> },
      { path: "about", element: <AboutPage /> },
      { path: "user/:id", element: <UserEditScreen /> },
      {
        path: "*",
        element: <Navigate to="" replace={true} />,
      },
    ],
  },
];

export default signinRoutes;
