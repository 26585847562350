import React, { useCallback } from "react";
import Typography from "@mui/material/Typography";
import { Card, Box, TextField, Container, Link, Alert } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import api from "../api/api";
import { formatError, newVersionAvailable } from "../helpers";
import { authAction } from "../store/auth-slice";

const Signin = () => {
  const [error, setError] = React.useState(null);
  const [requestPending, setRequestPending] = React.useState(false);
  const dispatch = useDispatch();

  const getCurrentUser = useCallback(async () => {
    try {
      const response = await api.get("/user/current");
      dispatch(authAction.setUserData(response.data));
    } catch (err) {
      alert(formatError(err));
    }
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setRequestPending(true);
    try {
      const response = await api.post("/public/signin", {
        email: event.target.email.value.trim(),
        password: event.target.password.value.trim(),
      });
      dispatch(authAction.setToken(response.data.token));
      if (response.data.appVersion) {
        if (newVersionAvailable([response.data.appVersion])) {
          window.location.reload();
        }
      }
      getCurrentUser();
      setRequestPending(false);
    } catch (err) {
      setError(formatError(err));
      setRequestPending(false);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };
  return (
    <Container component="main" maxWidth="sm">
      <Card sx={{ mt: "20%", mb: "20%" }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            justifyItems: "center",
            m: 3,
          }}
        >
          <Typography component="h1" variant="h4 " sx={{ mb: 2 }}>
            Prihlásenie
          </Typography>
          <TextField
            variant="outlined"
            label="Email"
            name="email"
            id="email"
            autoComplete="email"
            fullWidth
            required
          />
          <Typography variant="h4" sx={{ mb: 2 }}></Typography>
          <TextField
            required
            type="password"
            id="password"
            autoComplete="current-password"
            variant="outlined"
            label="Heslo"
            fullWidth
          />
          {error && (
            <Alert sx={{ m: 1, mb: 0, width: "100%" }} severity="error">
              {error}
            </Alert>
          )}
          <LoadingButton
            loading={requestPending}
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 4 }}
          >
            Prihlásiť sa
          </LoadingButton>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Link component={RouterLink} to="/reset-password" variant="body2">
              Zabudnuté heslo
            </Link>
            <Link component={RouterLink} to="/signup" variant="body2">
              Registruj sa
            </Link>
          </Box>
        </Box>
      </Card>
    </Container>
  );
};

export default Signin;
