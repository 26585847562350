import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  Alert,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import api from "../../api/api";

import { formatError } from "../../helpers";

const DialogEditAuthor = ({ data, onEdit }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
    setError(null);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    try {
      await api.put("/admin/opacAuthors/" + data._id, {
        name: event.target.name.value,
        lastName: event.target.lastName.value,
        affiliation: event.target.affiliation.value,
      });
      onEdit();
      handleClose();
    } catch (err) {
      setError(formatError(err));
    }
  };

  return (
    <div>
      <Tooltip title="Uprav autora">
        <IconButton onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Box onSubmit={handleSubmit} component="form">
            <DialogTitle>Uprav autora knižnice</DialogTitle>
            <TextField
              variant="outlined"
              label="Meno"
              name="name"
              id="name"
              fullWidth
              required
              defaultValue={data.name || ""}
            />
            <TextField
              margin="normal"
              variant="outlined"
              label="Priezvisko"
              name="lastName"
              id="lastName"
              fullWidth
              required
              defaultValue={data.lastName || ""}
            />
            <TextField
              margin="normal"
              variant="outlined"
              label="Katedra"
              name="affiliation"
              id="affiliation"
              fullWidth
              required
              defaultValue={data.affiliation || ""}
            />
            {error && (
              <Alert sx={{ m: 1, mb: 0, width: "100%" }} severity="error">
                {error}
              </Alert>
            )}
            <DialogActions>
              <Button onClick={handleClose} color="error">
                Zruš
              </Button>
              <Button type="submit">Potvrď</Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DialogEditAuthor;
