import React from "react";

import {
  Typography,
  Card,
  Box,
  TextField,
  Container,
  Button,
  Link,
  Alert,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import api from "../api/api";
import { formatError } from "../helpers";

const ResetPasswordScreen = () => {
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    try {
      const response = await api.post("/public/reset-password", {
        email: event.target.email.value,
      });
      setSuccess(response.data.message);
    } catch (err) {
      setError(formatError(err));
      setSuccess(null);
    }
  };
  return (
    <Container component="main" maxWidth="sm">
      <Card sx={{ mt: "20%", mb: "20%" }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            justifyItems: "center",
            m: 3,
          }}
        >
          <Typography component="h1" variant="h4 " sx={{ mb: 2 }}>
            Formulár obnovenia hesla
          </Typography>
          {success ? (
            <Alert severity="success">{success}</Alert>
          ) : (
            <React.Fragment>
              <TextField
                variant="outlined"
                label="Email"
                name="email"
                id="email"
                autoComplete="email"
                fullWidth
                required
              />

              {error && (
                <Alert sx={{ m: 1, mb: 0, width: "100%" }} severity="error">
                  {error}
                </Alert>
              )}
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{ mt: 4 }}
              >
                Resetuj heslo
              </Button>
            </React.Fragment>
          )}
          <Box
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Link component={RouterLink} to="/login" variant="body2">
              Prihlásenie
            </Link>
            <Link component={RouterLink} to="/signup" variant="body2">
              Registruj sa
            </Link>
          </Box>
        </Box>
      </Card>
    </Container>
  );
};

export default ResetPasswordScreen;
