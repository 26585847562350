import React, { useCallback, useEffect, useState } from "react";
import { Grid, Stack, Typography, Card, Box, IconButton } from "@mui/material";
import Table from "../components/universal/Table";
import api from "../api/api";
import {
  formatDate,
  formatError,
  getUrl,
  newVersionAvailable,
  translateActivityBase,
} from "../helpers";
import AddEvent from "../components/manageEvents/AddEvent";
import EditEvent from "../components/manageEvents/EditEvent";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useNavigate } from "react-router-dom";
import DeleteItemDialog from "../components/universal/DeleteItemDialog";
import ForceRefreshInfo from "../components/universal/ForceRefreshInfo";

const ManageEventsScreen = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [completeOptions, setCompleteOptions] = React.useState([]);
  const [showForceRefresh, setShowForceRefresh] = useState(false);
  const navigate = useNavigate();

  const getCompleteOption = useCallback(async () => {
    try {
      let response = await api.get("/fakulta/activity");
      setCompleteOptions(
        response.data.map((r) => {
          return {
            ...r,
            label: `${r.type} ${r.description} ${r.coefficient} ${
              r.example
            } ${translateActivityBase(r.base)}`,
          };
        })
      );
    } catch (error) {
      alert(formatError(error));
    }
  }, []);

  const onDeleteHandler = async (eventId) => {
    try {
      const url = "/fakulta/event/";
      const response = await api.delete(url + eventId);
      if (response.status === 200) {
        load();
      } else {
        alert("Chyba pri mazani aktivity.", response.status);
      }
    } catch (error) {
      alert(formatError(error));
    }
  };

  const load = useCallback(async (delay) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        const response = await api.get(getUrl("/fakulta/event"));

        setRows(
          response.data.map((item) => {
            const bgColor = calcActivityColor(item);
            return {
              ...item,
              bgColor,
            };
          })
        );
        sessionStorage.setItem("lastSeenTimestamp", new Date().getTime());
      } catch (err) {
        alert(formatError(err));
      }
      setLoading(false);
    }, delay || 10);
  }, []);

  const checkUnseen = useCallback(async () => {
    try {
      const response = await api.get(
        "/user/unseen/" + sessionStorage.getItem("lastSeenTimestamp")
      );
      setShowForceRefresh(newVersionAvailable(response.data));
      if (response.data.indexOf("events") !== -1) {
        await load();
      }
    } catch (error) {}
  }, [load]);

  const calcActivityColor = (data) => {
    let bgColor = "green";
    const now = new Date();
    if (data.allApproved) {
      if (now < new Date(data.outputDeadline)) {
        bgColor = "green";
      } else {
        bgColor = "blue";
      }
    } else {
      if (now < new Date(data.outputDeadline)) {
        bgColor = "orange";
      } else {
        bgColor = "red";
      }
    }

    return bgColor;
  };

  const settings = {
    rows: rows,
    loading: loading,
    colOrder: {
      order: "desc",
      orderBy: "eventDate",
    },
    tableId: "manageEventsScreenTable",
    headCells: [
      {
        id: "bgColor",
        numeric: false,
        label: "Stav aktivity",
        render: (data) => {
          return (
            <Box
              sx={{
                borderRadius: 4,
                width: 25,
                height: 25,
                bgcolor: data.bgColor,
              }}
            ></Box>
          );
        },
      },
      {
        id: "title",
        numeric: false,
        label: "Názov aktivity",
        search: true,
      },
      {
        id: "eventDate",
        numeric: false,
        label: "Dátum začiatok",
        render: (data) => {
          return formatDate(data.eventDate);
        },
      },
      {
        id: "eventEndDate",
        numeric: false,
        label: "Dátum koniec",
        render: (data) => {
          return formatDate(data.eventEndDate);
        },
      },
      {
        id: "outputDeadline",
        numeric: false,
        label: "Uzávierka výkonov",
        render: (data) => {
          return formatDate(data.outputDeadline);
        },
      },
      {
        id: "base",
        numeric: false,
        label: "Zaradenie aktivity",
        search: true,
        render: (data) => {
          return translateActivityBase(data.base);
        },
      },
      {
        id: "type",
        numeric: false,
        label: "Typ aktivity",
        search: true,
      },
      {
        id: "allOutput",
        numeric: true,
        label: "Sumár",
      },
      {
        id: "coefficient",
        numeric: true,
        label: "Koeficient",
      },
      {
        id: "buttons",
        numeric: false,
        label: "Akcie",
        render: (data) => {
          return (
            <Stack direction="row" alignItems="center">
              <EditEvent
                values={data}
                completeOptions={completeOptions}
                onEdit={load}
              />
              <DeleteItemDialog
                title="Potvrdenie zmazanie aktivity, všetky výkony spojené s danou aktivitou budu vymazané!"
                secondaryText={`Aktivita s názvom: ${data.title}`}
                onDelete={() => {
                  onDeleteHandler(data._id);
                }}
              />
              <IconButton
                onClick={() => {
                  navigate("/manageEvents/" + data._id);
                }}
              >
                <KeyboardDoubleArrowRightIcon color="primary" />
              </IconButton>
            </Stack>
          );
        },
      },
    ],
  };

  useEffect(() => {
    load();
    getCompleteOption();
    window.addEventListener("focus", checkUnseen);
    return () => {
      window.removeEventListener("focus", checkUnseen);
    };
  }, [load, getCompleteOption, checkUnseen]);
  const yearRestriction = localStorage.getItem("yearRestriction");
  const headerText =
    " Správa aktivít " +
    (yearRestriction !== "0" ? `(${yearRestriction})` : "");
  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h4">{headerText}</Typography>
        </Grid>
        <Grid item>
          <AddEvent completeOptions={completeOptions} onAdd={load} />
        </Grid>
      </Grid>
      <Card>
        <Table settings={settings} />
      </Card>
      <ForceRefreshInfo open={showForceRefresh} />
    </Box>
  );
};

export default ManageEventsScreen;
