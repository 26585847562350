import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import Table from "../../components/universal/Table";
import HistoryIcon from "@mui/icons-material/History";

const ShowPubPlanHistory = ({ values }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const translateChangeType = (type) => {
    const changes = [
      { type: "create", value: "Vytvorenie" },
      { type: "edit", value: "Úprava" },
      { type: "move", value: "Presun medzi obdobiami" },
      { type: "changeState", value: "Zmena stavu" },
      { type: "pair", value: "Priradenie" },
      { type: "delete", value: "Vymazanie" },
    ];
    for (const c of changes) {
      if (c.type === type) {
        return c.value;
      }
    }

    return "";
  };

  const head = [
    {
      id: "createdAt",
      numeric: false,
      label: "Dátum zmeny",
      render: (data) => new Date(data.createdAt).toLocaleString("sk"),
    },
    {
      id: "type",
      numeric: false,
      label: "Typ zmeny",
      render: (data) => translateChangeType(data.type),
    },
    {
      id: "name",
      numeric: false,
      label: "Osoba",
    },

    {
      id: "body",
      numeric: false,
      label: "Obsah zmeny",
      render: (data) => {
        return JSON.stringify(data.body, {}, 2);
      },
    },
  ];
  const settings = {
    rows: values,
    loading: false,
    colOrder: {
      order: "asc",
      orderBy: "createdAt",
    },
    dense: true,
    headCells: head,
    tableId: "history",
  };

  return (
    <div>
      <Tooltip title={"Zobraz históriu publikacie"}>
        <span>
          <IconButton
            aria-label="uprav"
            size="normal"
            onClick={handleClickOpen}
          >
            <HistoryIcon fontSize="normal" color={"primary"} />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth={"xl"}>
        <DialogTitle>História zmien publikacie</DialogTitle>
        <DialogContent sx={{ minHeight: 300 }}>
          <Table settings={settings} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Zavri okno
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShowPubPlanHistory;
