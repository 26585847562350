import {
  Alert,
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
  Link,
} from "@mui/material";

import { Link as RouterLink } from "react-router-dom";
import { departments } from "../../helpers";

const SingUpFormular = ({ handleSubmit, error, addingUser = false }) => {
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        justifyItems: "center",
        m: 3,
      }}
    >
      {!addingUser && (
        <Typography component="h1" variant="h4 " sx={{ mb: 2 }}>
          Registrácia
        </Typography>
      )}
      <TextField
        variant="outlined"
        label="Email"
        name="email"
        id="email"
        autoComplete="email"
        fullWidth
        required
      />
      <Typography variant="h4" sx={{ mb: 2 }}></Typography>
      <TextField
        required
        type="password"
        id="password"
        name="password"
        variant="outlined"
        label="Heslo"
        fullWidth
      />
      <TextField
        sx={{ mt: 2 }}
        required
        type="password"
        id="password-repeat"
        name="password_repeat"
        variant="outlined"
        label="Heslo - overenie"
        defaultValue={"Heslo1234"}
        fullWidth
      />
      <TextField
        sx={{ mt: 2 }}
        variant="outlined"
        label="Meno"
        name="name"
        id="name"
        fullWidth
        defaultValue={"Slavomir"}
        required
      />
      <TextField
        sx={{ mt: 2 }}
        variant="outlined"
        label="Priezvisko"
        name="surname"
        id="surname"
        fullWidth
        defaultValue={"Matuska"}
        required
      />
      <TextField
        id="department"
        name="department"
        label="Katedra (zaradenie)"
        sx={{ mt: 2 }}
        fullWidth
        select
        defaultValue="FEIT"
      >
        {departments.map((dep) => {
          return (
            <MenuItem key={dep} value={dep}>
              {dep}
            </MenuItem>
          );
        })}
      </TextField>

      {error && (
        <Alert sx={{ m: 1, mb: 0, width: "100%" }} severity="error">
          {error}
        </Alert>
      )}
      <Button type="submit" variant="contained" fullWidth sx={{ mt: 4 }}>
        {addingUser ? "Vytvor účet" : "Registruj sa"}
      </Button>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        {!addingUser && (
          <Link component={RouterLink} to="/signin" variant="body2">
            Máš účet? Tak sa prihlás
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default SingUpFormular;
