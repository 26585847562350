import React from "react";

import { ListItem, Typography, Box } from "@mui/material";
import {
  formatError,
  getActivityColor,
  translateActivityBase,
} from "../../helpers";
import EditInput from "./EditInput";
import DeleteItemDialog from "../universal/DeleteItemDialog";
import api from "../../api/api";

const DelegateInput = ({ delegateData, load }) => {
  const onDeleteHandler = async () => {
    try {
      const response = await api.delete("/dekan/activity/" + delegateData._id);
      if (response.status === 200) {
        load();
      } else {
        alert("Chyba pri mazani aktivity.", response.status);
      }
    } catch (error) {
      alert(formatError(error));
    }
  };

  return (
    <ListItem divider dense sx={{ justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h6">{delegateData.type}</Typography>
        <Typography
          color={getActivityColor(delegateData.base)}
          variant="body"
          sx={{ mt: 0.5 }}
          style={{ display: "inline-block", whiteSpace: "pre-line" }}
        >
          {translateActivityBase(delegateData.base)}
        </Typography>
        <Typography
          variant="body"
          sx={{ mt: 0.5 }}
          style={{ display: "inline-block", whiteSpace: "pre-line" }}
        >
          {delegateData.example}
        </Typography>
        <Typography
          variant="body"
          sx={{ mt: 0.5 }}
          style={{ display: "inline-block", whiteSpace: "pre-line" }}
        >
          {delegateData.description} - (koeficient: {delegateData.coefficient})
        </Typography>
      </Box>
      <Box sx={{ minWidth: 70, minHeight: 50 }}>
        <EditInput values={delegateData} onEdit={load} />
        <DeleteItemDialog
          title="Potvrdenie zmazanie aktivity"
          secondaryText={`Aktivita: ${delegateData.type}, koeficient: ${delegateData.coefficient}`}
          onDelete={onDeleteHandler}
        />
      </Box>
    </ListItem>
  );
};

export default DelegateInput;
