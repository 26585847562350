import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  TextField,
  Tooltip,
  Typography,
  Box,
  Autocomplete,
} from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import api from "../../api/api";
import { formatError } from "helpers";
import { Stack } from "@mui/system";

const depAffiliatons = [
  "ZUZEITEEP",
  "ZUZEITKTF",
  "ZUZEITKME",
  "ZUZEITKMT",
  "ZUZEITRIS",
  "ZUZEITEBI",
  "ZUZEITIAS",
];

function DialogExtAuthorClaim({ props, load }) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState(null);
  const [extAuthors, setExtAuthors] = useState([]);

  const handleClick = async () => {
    setError(null);
    setOpen(!open);
    if (open) {
      setExtAuthors([]);
    } else {
      setExtAuthors(
        props.authors.filter(
          (author) =>
            author.affiliation === "EXT" || author.affiliation === "N/A"
        )
      );
    }
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    setError(null);
    try {
      await api.put("/user/publication/authors/" + props._id, {
        authors: extAuthors.map((item, index) => {
          let forcedAffiliation = "";
          if (event.target[`forcedAffiliation${index}`].value) {
            forcedAffiliation = event.target[`forcedAffiliation${index}`].value;
            item.claimAuthorShare = true;
          }
          return {
            ...item,
            forcedAffiliation,
          };
        }),
      });
      setOpen(!open);
      if (load) load();
    } catch (error) {
      setError(formatError(error));
    }
  };

  const updateClaimShare = (rowData) => {
    setExtAuthors((state) => {
      return state.map((item) => {
        let claimAuthorShare = item.claimAuthorShare;
        if (item._id === rowData._id) {
          claimAuthorShare = !claimAuthorShare;
        }
        return { ...item, claimAuthorShare };
      });
    });
  };

  const setClaimShare = (claimAuthorShare) => {
    setExtAuthors((state) => {
      return state.map((item) => {
        return { ...item, claimAuthorShare };
      });
    });
  };

  const createRow = (rowData, index) => {
    return (
      <Box
        sx={{
          py: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ mr: 3 }}>{rowData.name}</Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            mx: 1,
            alignItems: "center",
          }}
        >
          <Typography>
            {rowData.claimAuthorShare ? "Vyžaduje podiel" : "Nevyžaduje podieľ"}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              updateClaimShare(rowData);
            }}
          >
            Zmeň
          </Button>
          <Autocomplete
            disablePortal
            variant="outlined"
            options={depAffiliatons}
            name={`forcedAffiliation${index}`}
            id={`forcedAffiliation${index}`}
            defaultValue={rowData.forcedAffiliation || ""}
            renderInput={(params) => (
              <TextField
                id="activitySource"
                {...params}
                sx={{ width: 250 }}
                label="Vynútená príslusnošť"
              />
            )}
          />
        </Stack>
      </Box>
    );
  };

  let dialogContent = (
    <Typography>Záznam neobsahuje žiadnych externých autorov.</Typography>
  );

  if (extAuthors.length) {
    dialogContent = (
      <List sx={{ width: "100%" }}>
        {extAuthors.map((a, index) => {
          return (
            <React.Fragment key={index}>
              {createRow(a, index)}
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
    );
  }

  return (
    <>
      <Tooltip title="Spravuj externých autorov">
        <IconButton size="small" variant="outlined" onClick={handleClick}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Dialog onClose={handleClick} open={open} maxWidth="lg">
        <DialogTitle>Správa externých autorov publikácie</DialogTitle>
        <form onSubmit={onSubmitHandler}>
          <DialogContent>
            {error ? (
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            ) : null}
            <Stack direction={"row"} spacing={2}>
              <Button
                variant="contained"
                onClick={() => {
                  setClaimShare(true);
                }}
              >
                Všetci vyžadujú
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setClaimShare(false);
                }}
              >
                Všetci nevyžadujú
              </Button>
            </Stack>
            {dialogContent}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClick} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default DialogExtAuthorClaim;
