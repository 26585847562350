import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";

const UniversalConfirmationDialog = ({
  iconTooltipText,
  title,
  secondaryText,
  onConfirm,
  children,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (onConfirm) onConfirm();
    handleClose();
  };

  return (
    <React.Fragment>
      <Tooltip title={iconTooltipText}>
        <IconButton aria-label="uprav" size="small" onClick={handleClickOpen}>
          {children}
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{secondaryText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button onClick={handleSubmit}>Potvrď</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default UniversalConfirmationDialog;
