import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import api from "../../api/api";
import { formatError } from "../../helpers";
import FormularBalance from "./FormularBalance";

const AddBalance = ({ values = {}, onAdd, showIcon }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const isMinus = event.target.isMinus.checked ? -1 : 1;
      const data = {
        date: event.target.date.value,
        note: event.target.note.value,
        count: [],
      };
      if (values.categories.length > 0) {
        for (const cat of values.categories) {
          data.count.push({
            category: cat,
            count: isMinus * parseInt(event.target[`count${cat}`].value),
          });
        }
      } else {
        data.count.push({
          count: isMinus * parseInt(event.target.count.value),
        });
      }
      const response = await api.post(
        `/fakulta/warehouse/${values._id}/balance`,
        data
      );
      if (response.status === 200) {
        handleClose();
        onAdd(500);
      }
    } catch (error) {
      setError(formatError(error));
    }
  };

  return (
    <React.Fragment>
      {showIcon ? (
        <Tooltip title="Pridaj pohyb">
          <IconButton aria-label="uprav" size="small" onClick={handleClickOpen}>
            <AddCircleIcon fontSize="medium" color="primary" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button sx={{ m: 1 }} variant="contained" onClick={handleClickOpen}>
          Pridaj pohyb
        </Button>
      )}

      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Pridaj pohyb</DialogTitle>
          <DialogContent>
            <FormularBalance values={values} error={error} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Potvrď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default AddBalance;
