import React, { useEffect } from "react";
import {
  Card,
  MenuItem,
  TextField,
  Typography,
  CardContent,
  Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import UserInfo from "../components/universal/UserInfo";
import MrktOutputChart from "components/homePage/MrktOutputChart";
import ScienceChart from "components/homePage/ScienceChart";
import TutorialData from "components/homePage/TutorialData";

const noRestrictionStr = "Neobmedzene";

const HomeScreen = () => {
  const [years, setYears] = React.useState([]);
  const [selectedYear, setSelectedYear] = React.useState("");

  const authData = useSelector((state) => {
    return state.auth;
  });

  useEffect(() => {
    const startYear = 2021;
    let currentYear = new Date().getFullYear();
    const arr = [noRestrictionStr];
    arr.push(currentYear);
    while (currentYear - startYear) {
      arr.push(--currentYear);
    }
    setYears(arr);
    let yearRestriction = localStorage.getItem("yearRestriction");
    if (yearRestriction === null) {
      localStorage.setItem("yearRestriction", 0);
      yearRestriction = 0;
    }

    if (yearRestriction !== null) {
      if (parseInt(yearRestriction) === 0) {
        setSelectedYear(noRestrictionStr);
      } else {
        setSelectedYear(yearRestriction);
      }
    }
  }, []);

  return (
    <Grid container spacing={3} sx={{ mt: 3 }}>
      <Grid item xs={12} md={6}>
        <UserInfo userData={authData} />
      </Grid>
      {authData.authorizationLevel > 0 && (
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                Zobrazovať výkony a aktivity len pre rok:{" "}
              </Typography>
              <TextField
                autoFocus
                margin="normal"
                id="year"
                name="year"
                label="Vyber rok"
                value={selectedYear}
                fullWidth
                onChange={(event) => {
                  setSelectedYear(event.target.value);
                  let newVal = event.target.value;
                  if (newVal === noRestrictionStr) {
                    newVal = 0;
                  }
                  localStorage.setItem("yearRestriction", newVal);
                }}
                select
              >
                {years.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </CardContent>
          </Card>
        </Grid>
      )}
      {authData.isMrktMember && (
        <Grid item xs={12} md={6}>
          <MrktOutputChart />
        </Grid>
      )}
      <Grid item xs={12} md={6} sx={{ minHeight: 250 }}>
        <ScienceChart userData={authData} />
      </Grid>
      <Grid item xs={12} md={6} sx={{ minHeight: 250 }}>
        <TutorialData />
      </Grid>
    </Grid>
  );
};

export default HomeScreen;
