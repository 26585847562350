import React, { useCallback, useEffect } from "react";
import {
  Grid,
  Stack,
  Typography,
  Card,
  Checkbox,
  Box,
  Button,
} from "@mui/material";
import Table from "../components/universal/Table";
import {
  formatError,
  translateAuthLevel,
  translatePersonCategoty,
} from "../helpers";
import api from "../api/api";
import EditUser from "../components/manageUsers/EditUser";
import DeleteItemDialog from "../components/universal/DeleteItemDialog";
import AddUser from "../components/manageUsers/AddUser";
import { utils, writeFile } from "xlsx";
import HandleUserMerge from "components/manageUsers/HandleUserMerge";

const ManageUsersScreen = () => {
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);

  const getUsers = useCallback(async (delay) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        const response = await api.get("/admin/getAllUser");
        setRows(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        alert(formatError(err));
      }
    }, delay || 10);
  }, []);

  const exportFile = () => {
    const ws1 = utils.json_to_sheet(
      [
        {
          A: `Meno a priezvisko`,
          A1: "Katedra",
          B: `Registračný email`,
          C: `Člen marketingu`,
          D: `Úroveň účtu marketing`,
          E: `Veda - katedrový koordinátor`,
          F: `Veda - fakultný koordinátor`,
        },
      ],
      {
        skipHeader: true,
        origin: "A1",
      }
    );

    utils.sheet_add_json(
      ws1,
      rows.map((r) => {
        return {
          A: r.name,
          A1: r.department,
          B: r.email,
          C: r.isMrktMember ? "Áno" : "Nie",
          D: translateAuthLevel(r.authorizationLevel),
          E: r.isScienceDepManager ? "Áno" : "Nie",
          F: r.isScienceManager ? "Áno" : "Nie",
        };
      }),
      {
        skipHeader: true,
        origin: "A2",
      }
    );

    ws1["!cols"] = [
      { width: 20 },
      { width: 12 },
      { width: 30 },
      { width: 15 },
      { width: 20 },
      { width: 25 },
      { width: 20 },
    ];

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws1, "ucty");
    writeFile(wb, `zoznam-uctov-mave.xlsx`);
  };

  const onCheckBoxChange = async (event, data, field) => {
    try {
      await api.put(`/admin/user/${data._id}`, {
        [field]: event.target.checked,
      });
      getUsers();
    } catch (err) {
      alert(formatError(err));
    }
  };

  const onDeleteHandler = async (userId) => {
    try {
      const url = "/admin/user/";
      const response = await api.delete(url + userId);
      if (response.status === 200) {
        getUsers();
      } else {
        alert("Chyba pri mazani pouzivatela.", response.status);
      }
    } catch (error) {
      alert(formatError(error));
    }
  };

  const settings = {
    rows: rows,
    loading: loading,
    colOrder: {
      order: "asc",
      orderBy: "email",
    },
    tableId: "manageUsersScreenTable",
    headCells: [
      {
        id: "email",
        numeric: false,
        label: "Používateľ",
        search: true,
      },
      {
        id: "name",
        numeric: false,
        label: "Meno",
        search: true,
      },
      {
        label: "Účet aktívny",
        numeric: false,
        id: "isActive",
        render: (data) => {
          return (
            <Checkbox
              checked={data.isActive}
              onChange={(e) => onCheckBoxChange(e, data, "isActive")}
            />
          );
        },
      },
      {
        label: "Úroveň účtu mrkt",
        numeric: false,
        id: "authorizationLevel",
        render: (data) => {
          return translateAuthLevel(data.authorizationLevel);
        },
      },
      {
        label: "Člen marketing",
        numeric: false,
        id: "isMrktMember",
        search: false,
        render: (data) => {
          return (
            <Checkbox
              checked={data.isMrktMember}
              onChange={(e) => onCheckBoxChange(e, data, "isMrktMember")}
            />
          );
        },
      },
      {
        label: "Veda-katedra",
        numeric: false,
        id: "isScienceDepManager",
        search: false,
        render: (data) => {
          return (
            <Checkbox
              checked={data.isScienceDepManager}
              onChange={(e) => onCheckBoxChange(e, data, "isScienceDepManager")}
            />
          );
        },
      },
      {
        label: "Veda-fakulta",
        numeric: false,
        id: "isScienceManager",
        search: false,
        render: (data) => {
          return (
            <Checkbox
              checked={data.isScienceManager}
              onChange={(e) => onCheckBoxChange(e, data, "isScienceManager")}
            />
          );
        },
      },
      {
        label: "Zaradenie (katedra)",
        numeric: false,
        id: "department",
        search: true,
      },
      {
        label: "Kategória",
        numeric: false,
        id: "personCategory",
        search: true,
        render: (data) => {
          return translatePersonCategoty(data.personCategory);
        },
      },
      {
        id: "buttons",
        numeric: false,
        label: "Akcie",
        render: (data) => {
          return (
            <Stack direction="row" alignItems="center">
              <EditUser values={data} onEdit={getUsers} />
              <DeleteItemDialog
                title="Potvrdenie zmazanie používateľa, všetky výkony spojené s daným užívateľom budú vymazané!"
                secondaryText={`Používateľ: ${data.name}`}
                onDelete={() => {
                  onDeleteHandler(data._id);
                }}
              />
            </Stack>
          );
        },
      },
    ],
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);
  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h4" sx={{ mb: 4 }}>
            Zoznam používateľov
          </Typography>
        </Grid>
        <Grid item>
          <AddUser onEdit={getUsers} />
        </Grid>
        <Grid item>
          <Button sx={{ my: 1 }} variant="contained" onClick={exportFile}>
            Exportuj zoznam používateľov
          </Button>
        </Grid>
        <Grid item>
          <HandleUserMerge users={rows} onEdit={getUsers} />
        </Grid>
      </Grid>
      <Card>
        <Table settings={settings} />
      </Card>
    </Box>
  );
};

export default ManageUsersScreen;
