import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import EventInfo from "../components/manageEvents/EventInfo";
import api from "../api/api";
import {
  formatDate,
  formatError,
  newVersionAvailable,
  translateActivityBase,
} from "../helpers";
import EditOutput from "../components/manageOutputs/EditOutput";
import DeleteItemDialog from "../components/universal/DeleteItemDialog";
import { useSelector } from "react-redux";
import Table from "../components/universal/Table";

import { utils, writeFile } from "xlsx";
import AddOutput from "../components/manageOutputs/AddOutput";
import AddOutputMulti from "../components/manageOutputs/AddOutputMulti";
import MoveOutput from "../components/manageOutputs/MoveOutput";
import ForceRefreshInfo from "../components/universal/ForceRefreshInfo";

const ManageEventScreen = () => {
  const authorizationLevel = useSelector((state) => {
    return state.auth.authorizationLevel;
  });
  const name = useSelector((state) => {
    return state.auth.name;
  });

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [eventBaseData, setEventBaseData] = useState({});
  const [completeOptions, setCompleteOptions] = React.useState([]);
  const [showForceRefresh, setShowForceRefresh] = useState(false);

  const location = useLocation();
  const eventId = location.pathname.split("/").pop();

  const getCompleteOption = useCallback(async () => {
    try {
      let response = await api.get("/fakulta/activity");
      setCompleteOptions(
        response.data.map((r) => {
          return {
            ...r,
            label: `${r.type} ${r.description} ${r.coefficient} ${
              r.example
            } ${translateActivityBase(r.base)}`,
          };
        })
      );
    } catch (error) {
      alert(formatError(error));
    }
  }, []);

  const load = useCallback(async () => {
    setLoading(true);
    try {
      if (!eventId) {
        throw new Error("Neexistujuca aktivita");
      }
      const response = await api.get("/fakulta/event/" + eventId);
      setEventBaseData(response.data);
      setRows(response.data.outputs);
      sessionStorage.setItem("lastSeenTimestamp", new Date().getTime());
      setLoading(false);
    } catch (error) {
      alert(formatError(error));
      setLoading(false);
    }
  }, [eventId]);

  const checkUnseen = useCallback(async () => {
    try {
      const response = await api.get(
        "/user/unseen/" + sessionStorage.getItem("lastSeenTimestamp")
      );
      setShowForceRefresh(newVersionAvailable(response.data));
      if (
        response.data.indexOf("events") !== -1 ||
        response.data.indexOf("outputs") !== -1
      ) {
        await load();
      }
    } catch (error) {}
  }, [load]);

  const sendNotification = async () => {
    try {
      const url = "/fakulta/event/sendNotification";
      const response = await api.post(url, { id: eventId });
      if (response.status === 200) {
        alert("Notifikačné emaily boli úspešne odoslané.");
      }
    } catch (error) {
      alert(formatError(error));
    }
  };

  const onDeleteHandler = async (recordId) => {
    try {
      const url = "/katedra/output/";
      const response = await api.delete(url + recordId);
      if (response.status === 200) {
        load();
      } else {
        alert("Chyba pri mazani vykonu.", response.status);
      }
    } catch (error) {
      alert(formatError(error));
    }
  };
  const calcOutputs = (rowData) => {
    let sum = 0;
    for (const row of rowData) {
      sum += parseFloat(row.output);
    }
    return `Suma zobrazených výkonov: ${sum.toFixed(2)} človeko/deň`;
  };

  const exportFile = () => {
    const ws = utils.json_to_sheet(
      [
        {
          A: eventBaseData.title,
          B: "koeficient: " + eventBaseData.coefficient,
          C: formatDate(eventBaseData.eventDate),
          D: translateActivityBase(eventBaseData.base),
          E: eventBaseData.type,
        },
      ],
      { skipHeader: true }
    );
    utils.sheet_add_json(
      ws,
      [
        {
          A: "Osoba",
          B: "Poznámka",
          C: "Katedra",
          D: "Výkon [človeko/deň]",
        },
      ],
      { skipHeader: true, origin: "A3" }
    );
    utils.sheet_add_json(
      ws,
      rows.map((row) => {
        return {
          A: row.name,
          B: row.note,
          C: row.department,
          D: parseFloat(row.output),
        };
      }),
      { skipHeader: true, origin: "A4" }
    );
    ws["A" + (rows.length + 5)] = { t: "s", v: "Súčet výkonov" };
    ws["D" + (rows.length + 5)] = { t: "n", f: `SUM(D4:D${rows.length + 3})` };

    ws["!ref"] = "A1:E" + rows.length + 5;
    ws["!cols"] = [
      { width: 30 },
      { width: 30 },
      { width: 12 },
      { width: 25 },
      { width: 30 },
    ];

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "vykony");
    writeFile(wb, eventBaseData.title + ".xlsx");
  };

  const head = [
    {
      id: "name",
      numeric: false,
      label: "Osoba",
      search: true,
    },
    {
      id: "note",
      numeric: false,
      label: "Poznámka",
      search: false,
    },
    {
      id: "outputDate",
      numeric: false,
      label: "Dátum výkonu",
      render: (data) => {
        return formatDate(data.outputDate);
      },
    },
    {
      id: "department",
      numeric: false,
      label: "Zaradenie - katedra",
      search: true,
    },
    {
      id: "menDay",
      numeric: false,
      label: "Človeko/deň (víkendový deň)",
      render: (data) => {
        return `${data.menDay} (${data.menDayWeekend})`;
      },
    },
    {
      id: "output",
      numeric: false,
      label: "Výkon",
    },
    {
      id: "approved",
      numeric: false,
      label: "Schválené",
      search: true,
      render: (data) => {
        const text = data.approved ? "Áno" : "Nie";
        const color = data.approved ? "green" : "red";
        return (
          <Box sx={{ bgcolor: color, borderRadius: 2 }}>
            <Typography color="white" align="center">
              {text}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: "approvedBy",
      numeric: false,
      label: "Schvalovatel",
      search: true,
    },
    {
      id: "approvedAt",
      numeric: false,
      label: "Schválené dátum",
      render: (data) => {
        let res = "";
        if (new Date(data.approvedAt).getTime() !== 0) {
          res = formatDate(data.approvedAt);
        }
        return res;
      },
    },

    {
      id: "buttons",
      numeric: false,
      label: "Akcie",
      render: (data) => {
        return (
          <Stack direction="row" alignItems="center">
            {(!data.approved || authorizationLevel > 1) && (
              <MoveOutput values={data} onEdit={load} />
            )}
            <EditOutput
              values={data}
              onEdit={load}
              role={authorizationLevel}
              name={name}
            />
            <DeleteItemDialog
              title="Potvrdenie zmazanie výkonu"
              secondaryText={`Vykon pre pouzivatela: ${data.name} na aktivite: ${data.title}`}
              onDelete={() => {
                onDeleteHandler(data._id);
              }}
            />
          </Stack>
        );
      },
    },
  ];

  const settings = {
    rows: rows,
    loading: loading,
    colOrder: {
      order: "asc",
      orderBy: "name",
    },
    dense: true,
    headCells: head,
    tableId: "manageEventScreenTable",
  };

  useEffect(() => {
    load();
    getCompleteOption();
    window.addEventListener("focus", checkUnseen);
    return () => {
      window.removeEventListener("focus", checkUnseen);
    };
  }, [load, getCompleteOption, checkUnseen]);
  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item>
          <EventInfo
            eventData={eventBaseData}
            completeOptions={completeOptions}
            load={load}
          />
        </Grid>
        <Grid
          item
          sx={{ alignSelf: "center", display: "flex", flexDirection: "column" }}
        >
          <AddOutput values={{ title: eventBaseData.title }} onAdd={load} />
          <AddOutputMulti
            values={{ title: eventBaseData.title }}
            onAdd={load}
          />
          <Button
            sx={{ m: 1 }}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={exportFile}
          >
            Exportuj data
          </Button>
          {eventBaseData.isOpen && (
            <Button
              fullWidth
              sx={{ m: 1 }}
              variant="contained"
              color="secondary"
              onClick={sendNotification}
            >
              Odošli notifikačné emaily
            </Button>
          )}
        </Grid>
      </Grid>
      <Card>
        <Table settings={settings} callbackExtraText={calcOutputs} />
      </Card>
      <ForceRefreshInfo open={showForceRefresh} />
    </Box>
  );
};

export default ManageEventScreen;
