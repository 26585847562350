import { Box, Grid, Typography } from "@mui/material";

const QaComponent = ({ qaData, changeTab }) => {
  return (
    <Grid item xs={12} md={6}>
      <Box sx={{ p: 1, bgcolor: "rgb(222, 219, 219)", borderRadius: "8px" }}>
        <Typography variant="h6">{qaData.question}</Typography>
        <Typography variant="body">{qaData.answer}</Typography>
        {qaData.link && (
          <Typography
            variant="body"
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              changeTab({}, qaData.link.navigateTo);
            }}
          >
            {qaData.link.text}
          </Typography>
        )}
      </Box>
    </Grid>
  );
};

export default QaComponent;
