import { Box, Card, Grid, LinearProgress, Typography } from "@mui/material";
import api from "api/api";
import { defaultTableOptions } from "constants/tableTexts";
import MUIDataTable from "mui-datatables";
import React, { useCallback, useEffect, useState } from "react";

const SystemLogs = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "createdAt",
      label: "Čas",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return new Date(value).toLocaleString("sk");
        },
        setCellProps: () => {
          return {
            style: { minWidth: 150 },
          };
        },
      },
    },
    {
      name: "type",
      label: "Typ záznamu",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "source",
      label: "Zdroj záznamu",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "log",
      label: "Podrobnosti",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return JSON.stringify(value, {}, 2);
        },
      },
    },
  ];

  const load = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get("/admin/systemLog");
      setRecords(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Card>
      <Grid py={1} px={1} container spacing={1}>
        <Grid item>
          <Typography mr={2} variant="h5" sx={{ mt: 1 }}>
            Systémové logy
          </Typography>
        </Grid>
        {/* <Grid item>
          <FormControl>
            <InputLabel id="simple-select-label">Rok</InputLabel>
            <Select
              labelId="simple-select-label"
              value={year}
              label="Rok"
              onChange={handleChange}
            >
              {years.length > 0 ? (
                years.map((d, i) => (
                  <MenuItem key={i} value={d}>
                    {d}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={year}>{year}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <ChartBarComponent chartData={gdata} barData={barDataJournal} />
        </Grid>
        <Grid item>
          <ChartBarComponent
            chartData={journaldata}
            barData={barDataJournal}
            chartWidth={700}
          />
        </Grid> */}
      </Grid>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <MUIDataTable
        // title={"Employee List"}
        data={records}
        columns={columns}
        options={defaultTableOptions({
          sortOrder: {
            name: "createdAt",
            direction: "desc",
          },
          // onDownload: (buildHead, buildBody, columns, data) => {
          //   exportExcel(columns, data);
          //   return false;
          // },
        })}
      />
    </Card>
  );
};

export default SystemLogs;
