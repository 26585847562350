import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  IconButton,
  Box,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Tooltip as MuiTooltip,
  TextField,
  Button,
  Stack,
  Autocomplete,
  Tab,
  Grid,
  Chip,
} from "@mui/material";
import api from "api/api";
import Table from "components/universal/Table";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { pubHasExtAuthors } from "helpers";
import WarningIcon from "@mui/icons-material/Warning";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import DialogExtAuthorClaim from "./DialogExtAuthorClaim";
import DialogPubInfo from "./DialogPubInfo";
import {
  calcAuthorDepPointShare,
  handleUserDataExport,
} from "util/excelExportUtil";
import PointsTable from "./PointsTable";
import ChartBarComponent from "./ChartBarComponent";

export default function MyPublications({ authData, ...rest }) {
  const [publications, setPublications] = React.useState([]);
  const [citations, setCitations] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [years, setYears] = React.useState([]);
  const [authors, setAuthors] = React.useState([]);
  const [author, setAuthor] = React.useState(null);
  const [tab, setTab] = React.useState("1");
  const [userPoints, setUserPoints] = React.useState(null);
  const [gdata, setGdata] = React.useState([]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChange = (event) => {
    setYear(event.target.value);
  };

  useEffect(() => {
    async function fetchData() {
      let response = await api.get("/user/authors");
      let authors = response.data.map((item) => {
        item.label = `${item.name} ${item.lastName} - ${item.orgId}`;
        return item;
      });
      setAuthors(authors);

      if (authData.authorId) {
        let findAuthor = authors.find((a) => a._id === authData.authorId);
        setAuthor(findAuthor ? findAuthor : authors[0]);
      }
    }
    fetchData();
  }, [authData.authorId]);

  const load = useCallback(async () => {
    if (author) {
      setLoading(true);
      const response = await api.get(
        "/user/publication/userReport/" + author._id
      );

      response.data.map(
        (data) => (data.citationNumber = data.citations.length)
      );
      for (const pub of response.data) {
        calcAuthorDepPointShare(pub);
      }
      setPublications(response.data);
      setLoading(false);
    }
  }, [author, setPublications, setLoading]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    let years = [...new Set(publications.map((obj) => obj.epcaRok))].sort();
    if (!years.includes(new Date().getFullYear())) {
      years.push(new Date().getFullYear());
    }
    setYears(years.reverse());
  }, [publications, setYears]);

  useEffect(() => {
    let tmpCitations = [];
    const points = {
      pub: 0,
      cit: 0,
    };
    const tmpGData = [];
    for (let i = year - 4; i <= year; i++) {
      tmpGData.push({ name: i, pub: 0, cit: 0, sum: 0 });
    }
    for (const p of publications) {
      for (const g of tmpGData) {
        if (g.name === p.epcaRok) {
          g.pub += p.authorPoints;
        }
        let cits = p.citations.filter((c) => c.year === g.name);
        for (const c of cits) {
          const pointCitShare = c.points * p.authorProportion;
          g.cit += pointCitShare;
          if (c.year === year) {
            points.cit += pointCitShare;
            c.myPub = p.customAuthorsList + " " + p.title;
            c.pointShare = pointCitShare;
          }
        }
        if (g.name === year) {
          tmpCitations = tmpCitations.concat(cits);
        }
      }
    }

    setCitations(tmpCitations);

    for (const pub of publications) {
      if (pub.epcaRok === year) {
        points.pub += pub.authorPoints;
      }
    }
    for (const g of tmpGData) {
      g.sum = g.pub + g.cit;
    }
    setGdata(tmpGData);
    setUserPoints({ ...points, sum: points.pub + points.cit });
  }, [publications, setCitations, year]);

  const settings = {
    rows: publications.filter((d) => d.epcaRok === year),
    loading: loading,
    dense: true,
    colOrder: {
      order: "asc",
      orderBy: "points",
    },
    headCells: [
      {
        id: "epcaKod",
        numeric: false,
        label: "Kategória publikácie",
        search: true,
      },
      {
        id: "customAuthorsList",
        numeric: false,
        label: "Autori",
        search: false,
      },
      {
        id: "authorPerc",
        numeric: false,
        label: "Podiel autora",
        search: false,
        render: (data) => {
          let d = data.authors.find((d) => d.authorId === author._id);
          return d ? d.proportion : "";
        },
      },
      {
        id: "title",
        numeric: false,
        label: "Názov",
        search: true,
      },
      {
        id: "sourceName",
        numeric: false,
        label: "Zdrojovy dokument",
        search: true,
      },
      {
        id: "quartil",
        numeric: false,

        label: "Kvartil",
        search: true,
        render: (data) => (data.quartil === "undefined" ? "" : data.quartil),
      },
      {
        id: "isWOS",
        numeric: false,
        label: "WOS",
        search: false,
        render: (data) => (data.isWOS ? <CheckIcon /> : <ClearIcon />),
      },
      {
        id: "isScopus",
        numeric: false,
        label: "Scopus",
        search: false,
        render: (data) => (data.isScopus ? <CheckIcon /> : <ClearIcon />),
      },
      {
        id: "points",
        numeric: true,
        label: "Body pre katedru",
        search: false,
        render: (data) => {
          return (
            <Chip
              sx={{ minWidth: 50 }}
              label={data.points}
              color={data.isForced ? "error" : undefined}
            />
          );
        },
      },
      {
        id: "authorPoints",
        numeric: true,
        label: "Moje body",
        search: false,
      },
      {
        id: "citationNumber",
        numeric: true,
        label: "Citácie",
        search: false,
      },
      {
        id: "btns",
        label: "Akcie",
        render: (data) => (
          <Stack direction="row">
            {(data.onlineWOSIndexed && !data.isWOS) ||
            (data.onlineScopusIndexed && !data.isScopus) ? (
              <MuiTooltip
                title={
                  data.onlineWOSIndexed && !data.isWOS
                    ? "Publikácia je evidovaná v databázach WOS, ale v knižnici nie"
                    : "Publikácia je evidovaná v databázach Scopus, ale v knižnici nie"
                }
              >
                <IconButton color="error" size="small" variant="outlined">
                  <WarningIcon />
                </IconButton>
              </MuiTooltip>
            ) : null}
            {pubHasExtAuthors(data) && (
              <DialogExtAuthorClaim props={data} load={load} />
            )}
            <DialogPubInfo props={data} />
          </Stack>
        ),
      },
    ],
  };

  const settingsCitations = {
    rows: citations,
    loading: loading,
    dense: true,
    colOrder: {
      order: "asc",
      orderBy: "pointShare",
    },
    headCells: [
      {
        id: "pointShare",
        numeric: true,
        label: "Body (podiel)",
        search: false,
        render: (data) => data.pointShare.toFixed(1).toString(),
      },
      {
        id: "points",
        numeric: true,
        label: "Body",
        search: false,
      },
      {
        id: "myPub",
        numeric: false,
        label: "Citovaný dokument",
        search: true,
      },
      {
        id: "source",
        numeric: false,
        label: "Zdrojový dokument",
        search: true,
      },
      {
        id: "index",
        numeric: false,
        label: "Index",
        search: true,
      },
    ],
  };
  const barData = [
    {
      value: "pub",
      name: "Publikácie",
      color: "#8884d8",
      showLabelList: false,
    },
    { value: "cit", name: "Citácie", color: "#82ca9d", showLabelList: false },
    { value: "sum", name: "Spolu", color: "#F1416C", showLabelList: false },
  ];

  return (
    <Card>
      <Grid py={2} px={1} container spacing={1}>
        <Grid item xs={12} sm={12} md={3}>
          <Autocomplete
            options={authors}
            value={author}
            sx={{ minWidth: 200 }}
            onChange={(event, newValue) => {
              if (newValue) {
                setAuthor(newValue);
              }
            }}
            renderInput={(params) => <TextField {...params} label="Autor" />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"}>
          <FormControl fullWidth>
            <InputLabel id="simple-select-label">Rok</InputLabel>
            <Select
              labelId="simple-select-label"
              value={year}
              label="Rok"
              onChange={handleChange}
            >
              {years.length > 0 ? (
                years.map((d, i) => (
                  <MenuItem key={i} value={d}>
                    {d}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={year}>{year}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={"auto"}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              handleUserDataExport([year], publications, author);
            }}
          >
            Exportovať
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={"auto"}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              const arr = Array(5)
                .fill("")
                .map((v, idx) => year - idx);
              handleUserDataExport(arr, publications, author);
            }}
          >
            Exportovať 5 rokov od vybraného roku
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={"auto"}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              handleUserDataExport(undefined, publications, author);
            }}
          >
            Exportovať všetko
          </Button>
        </Grid>
        <Grid item></Grid>
      </Grid>

      {userPoints && (
        <Grid py={1} px={1} container spacing={1}>
          <Grid item>
            <PointsTable userPoints={userPoints} isLoading={loading} />
          </Grid>
          <Grid item xs={11} md={4} sx={{ minHeight: 200 }}>
            <ChartBarComponent chartData={gdata} barData={barData} />
          </Grid>
        </Grid>
      )}
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            <Tab label="Publikácie" value="1" />
            <Tab label="Citácie" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Table settings={settings} />
        </TabPanel>
        <TabPanel value="2">
          <Table settings={settingsCitations} />
        </TabPanel>
      </TabContext>
    </Card>
  );
}
