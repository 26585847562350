import React, { useEffect } from "react";

import {
  TextField,
  Alert,
  Autocomplete,
  Button,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import deLocale from "date-fns/locale/de";

const FormularEvent = ({
  values = {},
  completeOptions = [],
  autoCompleteEmails = [],
  setEmails,
  emails,
  error,
}) => {
  const [eventDate, setEventDate] = React.useState(new Date());
  const [eventEndDate, setEventEndDate] = React.useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  );
  const [outputDeadline, setOutputDeadline] = React.useState(
    new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
  );
  const [activitySource, setActivitySource] = React.useState(null);
  const [email, setEmail] = React.useState("");

  const handleEmailPicked = (event) => {
    event.preventDefault();
    setEmails([...emails, email]);
    setEmail(null);
  };

  const completeOptionsEmailsLeft = autoCompleteEmails.filter((user) => {
    for (const selected of emails) {
      if (selected._id === user._id) {
        return false;
      }
    }
    return true;
  });

  //const addDefault = () => {};

  const addCoordinatros = () => {
    const arr = [];
    for (const complete of autoCompleteEmails) {
      if (complete.authorizationLevel === 1) {
        let addComplete = true;
        for (const e of emails) {
          if (e._id === complete._id) {
            addComplete = false;
          }
        }
        if (addComplete) {
          arr.push(complete);
        }
      }
    }
    setEmails([...emails, ...arr]);
  };

  useEffect(() => {
    if (values.eventDate) {
      setEventDate(new Date(values.eventDate));
    }
    if (values.eventEndDate) {
      setEventEndDate(new Date(values.eventEndDate));
    }
    if (values.outputDeadline) {
      setOutputDeadline(new Date(values.outputDeadline));
    }
    for (const c of completeOptions) {
      if (
        c.label.includes(values.type) &&
        c.coefficient === values.coefficient
      ) {
        setActivitySource(c);
        break;
      }
    }
  }, [
    values.eventDate,
    values.outputDeadline,
    values.type,
    completeOptions,
    values.coefficient,
    values.eventEndDate,
  ]);

  return (
    <React.Fragment>
      <Autocomplete
        disablePortal
        id="activitySource"
        options={completeOptions}
        sx={{ mt: 1 }}
        fullWidth
        value={activitySource}
        onChange={(event, newValue) => {
          setActivitySource(newValue);
        }}
        renderInput={(params) => (
          <TextField
            id="activitySource"
            required
            {...params}
            label="Zdroj aktivity"
          />
        )}
      />

      <TextField
        margin="normal"
        required
        id="title"
        name="title"
        label="Názov aktivity"
        fullWidth
        defaultValue={values.title || ""}
      />

      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={deLocale}
      >
        <DatePicker
          label="Začiatok aktivity"
          value={eventDate}
          onChange={(newValue) => {
            setEventDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              required
              id="eventDate"
              {...params}
              sx={{ mt: 2 }}
              fullWidth
            />
          )}
        />
      </LocalizationProvider>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={deLocale}
      >
        <DatePicker
          label="Koniec aktivity"
          value={eventEndDate}
          onChange={(newValue) => {
            setEventEndDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              required
              id="eventEndDate"
              {...params}
              sx={{ mt: 2 }}
              fullWidth
            />
          )}
        />
      </LocalizationProvider>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={deLocale}
      >
        <DatePicker
          label="Uzávierka výkonov"
          value={outputDeadline}
          onChange={(newValue) => {
            setOutputDeadline(newValue);
          }}
          renderInput={(params) => (
            <TextField
              required
              id="outputDeadline"
              {...params}
              sx={{ mt: 2, mb: 1 }}
              fullWidth
            />
          )}
        />
      </LocalizationProvider>

      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography pt={1}>Zoznam emailov</Typography>
        {/* <Button variant="contained" onClick={addDefault}>
          Pridaj prednastavené
        </Button> */}
        <Button variant="contained" onClick={addCoordinatros}>
          Pridaj koordinátorov
        </Button>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Autocomplete
          disablePortal
          id="email"
          options={completeOptionsEmailsLeft}
          value={email}
          onChange={(event, newValue) => {
            setEmail(newValue);
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              id="email"
              {...params}
              size="small"
              label="Notifikačný email"
              margin="normal"
            />
          )}
        />
        <Button onClick={handleEmailPicked} disabled={!email}>
          Pridaj
        </Button>
      </Stack>
      {emails.map((item, index) => {
        return (
          <Stack direction={"row"} justifyContent={"space-between"} key={index}>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                pr: 5,
              }}
            >
              {item.name} - {item.department}
            </Typography>
            <IconButton
              aria-label="uprav"
              size="small"
              onClick={() => {
                setEmails(
                  emails.filter((s) => {
                    return s._id !== item._id;
                  })
                );
              }}
            >
              <RemoveCircleIcon fontSize="medium" color="primary" />
            </IconButton>
          </Stack>
        );
      })}
      <Button
        sx={{ mb: 1, mt: 10 }}
        variant="contained"
        fullWidth
        onClick={() => {
          setEventDate(new Date(new Date().getFullYear(), 0, 1));
          setEventEndDate(new Date(new Date().getFullYear(), 11, 20));
          setOutputDeadline(new Date(new Date().getFullYear(), 11, 24));
        }}
      >
        Nastav ročnú aktivitu
      </Button>

      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
    </React.Fragment>
  );
};

export default FormularEvent;
