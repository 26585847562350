import {
  Box,
  Button,
  Card,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { defaultTableOptions } from "constants/tableTexts";
import { exportExcel, formatError } from "helpers";
import MUIDataTable from "mui-datatables";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "api/api";
import UniversalConfirmationDialog from "components/universal/UniversalConfirmationDialog";
import CancelIcon from "@mui/icons-material/Cancel";
import ClaimAuthor from "./ClaimAuthor";
import { useSelector } from "react-redux";

const ManageAuthors = () => {
  const navigate = useNavigate();
  const { department } = useSelector((state) => {
    return state.auth;
  });
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [authors, setAuthors] = useState([]);

  const handleCancelConnection = async (user) => {
    try {
      await api.put("/science/manageAuthors/clear/", {
        userId: user._id,
      });
      load();
    } catch (error) {
      alert(formatError(error));
    }
  };

  const handleClaimConnection = async (user, author) => {
    try {
      await api.put("/science//manageAuthors/claim/", {
        userId: user._id,
        authorId: author._id,
      });
      load();
    } catch (error) {
      alert(formatError(error));
    }
  };

  const columns = [
    {
      name: "name",
      label: "Meno",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "department",
      label: "Katedra",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "authorName",
      label: "Meno prideleného autora",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "btns",
      label: "Akcie",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const data = records[dataIndex];
          return (
            <Stack direction="row">
              {data.authorId !== "" && (
                <UniversalConfirmationDialog
                  iconTooltipText="Zruš spojenie autora"
                  title="Zruš spojenie autora"
                  secondaryText={`Naozaj chcete zrušiť spojenie pre použivateľa ${data.name} s autorom ${data.authorName} ?`}
                  onConfirm={() => {
                    handleCancelConnection(data);
                  }}
                >
                  <CancelIcon fontSize="medium" color="primary" />
                </UniversalConfirmationDialog>
              )}
              {data.authorId === "" && (
                <ClaimAuthor
                  authors={authors}
                  onConfirm={(author) => {
                    handleClaimConnection(data, author);
                  }}
                />
              )}
            </Stack>
          );
        },
      },
    },
  ];

  const loadAuthors = useCallback(async () => {
    try {
      const response = await api.get("/user/authors");
      let authors = response.data.map((item) => {
        item.label = `${item.name} ${item.lastName} - ${item.orgId}`;
        return item;
      });
      setAuthors(authors);
    } catch (error) {
      alert(formatError(error));
    }
  }, []);

  const load = useCallback(async () => {
    try {
      let response = await api.get(
        "/science/publicationPlan/authors/" + department
      );
      setRecords(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(formatError(error));
    }
  }, [department]);

  useEffect(() => {
    load();
    loadAuthors();
  }, [load, loadAuthors]);

  return (
    <Card sx={{ mt: 4, pt: 2 }}>
      <Stack sx={{ ml: 2, mb: 2 }} direction={"row"} spacing={2}>
        <Typography variant="h5">Spravovanie autorov</Typography>
        <Button
          variant="contained"
          onClick={() => {
            navigate(-1);
          }}
        >
          Návrat na plán
        </Button>
      </Stack>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <MUIDataTable
        data={records}
        columns={columns}
        options={defaultTableOptions({
          sortOrder: {
            name: "name",
            direction: "desc",
          },
          onDownload: (buildHead, buildBody, columns, data) => {
            const wsCols = [40, 35, 8, 30, 8, 30, 30];
            exportExcel(
              "zoznam autorov",
              "zoznam autorov.xlsx",
              columns,
              data,
              wsCols.map((item) => {
                return { width: item };
              })
            );
            return false;
          },
        })}
      />
    </Card>
  );
};

export default ManageAuthors;
