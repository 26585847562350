import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Container,
} from "@mui/material";
import api from "api/api";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Table from "components/universal/Table";
import { formatError } from "helpers";
import { Box } from "@mui/system";
import DeleteItemDialog from "components/universal/DeleteItemDialog";
import EditIcon from "@mui/icons-material/Edit";

function FormDialog({ dialogProperties, open, onClose, ...rest }) {
  const [error, setError] = useState(null);

  const onSubmit = async (event) => {
    event.preventDefault();

    const data = {
      epcaKod: event.target.epcaKod.value,
      quartil: event.target.quartil.value,
      points: event.target.points.value,
      //koeficient: event.target.koeficient.value,
      koeficient: 1,
      isCurrentContent: event.target.isCurrentContent.checked,
      isWOS: event.target.isWOS.checked,
      isScopus: event.target.isScopus.checked,
      isForeighnLanguage: event.target.isForeighnLanguage.checked,
    };

    try {
      if (dialogProperties.neww) {
        await api.post("/science/criteria", data);
      } else {
        await api.put("/science/criteria/" + dialogProperties.values._id, data);
      }
      onClose(false, {}, true);
    } catch (error) {
      setError(formatError(error));
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle>
          {dialogProperties.neww ? "Nové kritérium" : "Editácia"}
        </DialogTitle>
        <DialogContent
          sx={{
            "& .MuiFormControl-root": { mt: 1 },
          }}
        >
          {error ? (
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          ) : null}
          <TextField
            autoFocus
            name="epcaKod"
            label="Epca kód"
            defaultValue={dialogProperties.values.epcaKod}
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel id="quartil-simple-select-label">Kvartil</InputLabel>
            <Select
              labelId="quartil-simple-select-label"
              id="quartil-simple-select"
              name="quartil"
              defaultValue={dialogProperties.values.quartil}
              label="Kvartil"
            >
              <MenuItem value={"Q1"}>Q1</MenuItem>
              <MenuItem value={"Q2"}>Q2</MenuItem>
              <MenuItem value={"Q3"}>Q3</MenuItem>
              <MenuItem value={"Q4"}>Q4</MenuItem>
            </Select>
          </FormControl>
          <TextField
            name="points"
            label="Body"
            type="number"
            defaultValue={dialogProperties.values.points}
            fullWidth
          />
          {/* <TextField
            name="koeficient"
            label="Koeficient"
            type="number"
            defaultValue={dialogProperties.values.koeficient}
            fullWidth
          /> */}
          <FormControlLabel
            control={
              <Checkbox
                name="isCurrentContent"
                defaultChecked={dialogProperties.values.isCurrentContent}
              />
            }
            label="Current content"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isWOS"
                defaultChecked={dialogProperties.values.isWOS}
              />
            }
            label="WOS"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isScopus"
                defaultChecked={dialogProperties.values.isScopus}
              />
            }
            label="Scopus"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isForeighnLanguage"
                defaultChecked={dialogProperties.values.isForeighnLanguage}
              />
            }
            label="Cudzí jazyk"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="error">
            Zruš
          </Button>
          <Button type="submit">Potvrď</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default function ScienceCriteria({ authData, ...rest }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogProperties, setDialogProperties] = useState({});
  const [open, setOpen] = React.useState(false);

  const handleDialogClick = (neww = false, values = {}, refresh = false) => {
    if (refresh) {
      fetchData();
    }
    setOpen(!open);
    if (!open) {
      setDialogProperties({ data: data, neww: neww, values: values });
    } else {
      setDialogProperties({});
    }
  };

  const onDelete = async (data) => {
    await api.delete("/science/criteria/" + data._id);
    fetchData();
  };

  const fetchData = useCallback(async () => {
    const response = await api.get("/user/criteria");
    response.data.map(
      (d) => (d.quartil = d.quartil === "undefined" ? "" : d.quartil)
    );
    setData(response.data);
    setLoading(false);
  }, [setData, setLoading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const settings = {
    rows: data,
    loading: loading,
    dense: true,
    colOrder: {
      order: "desc",
      orderBy: "epcaKod",
    },
    headCells: [
      {
        id: "epcaKod",
        numeric: false,
        label: "Epca kód",
        search: true,
      },
      {
        id: "quartil",
        numeric: false,
        label: "Kvartil",
        search: true,
      },
      {
        id: "isWOS",
        numeric: false,
        label: "WOS",
        search: false,
        render: (data) => (data.isWOS ? <CheckIcon /> : <ClearIcon />),
      },
      {
        id: "isScopus",
        numeric: false,
        label: "Scopus",
        search: false,
        render: (data) => (data.isScopus ? <CheckIcon /> : <ClearIcon />),
      },
      {
        id: "points",
        numeric: true,
        label: "Body",
        search: false,
      },
      {
        id: "isForeighnLanguage",
        numeric: true,
        label: "Zahraničný jazyk",
        search: false,
        render: (data) =>
          data.isForeighnLanguage ? <CheckIcon /> : <ClearIcon />,
      },
      {
        id: "btns",
        numeric: false,
        search: false,
        hide: !(authData.isScienceManager || authData.isAdmin),
        render: (data) => (
          <Stack direction="row">
            <IconButton
              color="primary"
              onClick={() => handleDialogClick(false, data)}
            >
              <EditIcon />
            </IconButton>
            <DeleteItemDialog
              title={"Vymazať katedru " + data.title + "?"}
              onDelete={() => onDelete(data)}
            />
          </Stack>
        ),
      },
    ],
  };

  return (
    <Container maxWidth="md">
      <Card>
        {authData.isScienceManager || authData.isAdmin ? (
          <Box pt={2} pl={2}>
            <Button variant="contained" onClick={() => handleDialogClick(true)}>
              Pridaj kritérium
            </Button>
          </Box>
        ) : null}
        <Table settings={settings} />
        {open ? (
          <FormDialog
            dialogProperties={dialogProperties}
            open={open}
            onClose={handleDialogClick}
          />
        ) : null}
      </Card>
    </Container>
  );
}
