import { Box, Card, Grid, LinearProgress, Typography } from "@mui/material";
import api from "api/api";
import { defaultTableOptions } from "constants/tableTexts";
import MUIDataTable from "mui-datatables";
import React, { useCallback, useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const UnderIndexedPublications = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "title",
      label: "Názov",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "authorsList",
      label: "Autori",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "epcaRok",
      label: "Rok vykazovania",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "epcaKod",
      label: "Kód publikácie",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "isWOS",
      label: "WOS",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const data = records[dataIndex];
          return data.isWOS ? <CheckIcon /> : <ClearIcon />;
        },
      },
    },
    {
      name: "onlineWOSIndexed",
      label: "online WOS",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const data = records[dataIndex];
          return data.onlineWOSIndexed ? <CheckIcon /> : <ClearIcon />;
        },
      },
    },
    {
      name: "isScopus",
      label: "Scopus",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const data = records[dataIndex];
          return data.isScopus ? <CheckIcon /> : <ClearIcon />;
        },
      },
    },
    {
      name: "onlineScopusIndexed",
      label: "online Scopus",
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const data = records[dataIndex];
          return data.onlineScopusIndexed ? <CheckIcon /> : <ClearIcon />;
        },
      },
    },
  ];

  const load = useCallback(async () => {
    setLoading(true);
    try {
      let response = await api.get("/admin/getUnderIndexedPubs");
      setRecords(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Card>
      <Grid py={1} px={1} container spacing={1}>
        <Grid item>
          <Typography mr={2} variant="h5" sx={{ mt: 1 }}>
            Nezaindexované publikácie
          </Typography>
        </Grid>
      </Grid>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <MUIDataTable
        // title={"Employee List"}
        data={records}
        columns={columns}
        options={defaultTableOptions({
          sortOrder: {
            name: "epcaRok",
            direction: "desc",
          },
          // onDownload: (buildHead, buildBody, columns, data) => {
          //   exportExcel(columns, data);
          //   return false;
          // },
        })}
      />
    </Card>
  );
};

export default UnderIndexedPublications;
