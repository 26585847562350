import {
  Button,
  Typography,
  Menu,
  MenuItem,
  Box,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import { authAction } from "../store/auth-slice";

const MainNavigation = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    isAuthentificated,
    isAdmin,
    authorizationLevel,
    personCategory,
    isMrktMember,
    email,
  } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onHomeClicked = () => {
    navigate("/");
  };
  const onLogoutHandler = () => {
    dispatch(authAction.clearAuthData());
  };
  const navData = []; //[{ navTo: "/objects", displayText: "Zoznam objektov" }];
  if (isAdmin || isMrktMember) {
    if (isAdmin || authorizationLevel >= 2) {
      navData.push({
        navTo: "/manageUsers",
        displayText: "Zoznam používateľov",
      });
    }
    if (authorizationLevel >= 2) {
      navData.push({ navTo: "/manageEvents", displayText: "Správa aktivít" });
    } else {
      navData.push({ navTo: "/eventsList", displayText: "Zoznam aktivít" });
    }
    if (authorizationLevel >= 2) {
      navData.push({ navTo: "/warehouse", displayText: "Sklad" });
    }
    navData.push({ navTo: "/manageOutputs", displayText: "Správa výkonov" });
    if (authorizationLevel >= 3) {
      navData.push({ navTo: "/manageInputs", displayText: "Správa vstupov" });
    }
  }
  if (personCategory > 0) {
    navData.push({ navTo: "/scienceOutputs", displayText: "Veda" });
  }
  if (isAdmin || email === "slavomir.matuska@uniza.sk") {
    navData.push({ navTo: "/systemAdmin", displayText: "Admin" });
  }

  if (!matches) {
    navData.push({ navTo: "/", displayText: "Odhlásiť sa" });
  }

  const menuItems = navData.map((item) => {
    return (
      <MenuItem
        key={item.displayText}
        onClick={() => {
          if (item.navTo === "/") {
            onLogoutHandler();
          } else {
            navigate(item.navTo);
          }
          handleMenuClose();
        }}
      >
        {item.displayText}
      </MenuItem>
    );
  });

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={"primary-menu-id"}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {menuItems}
    </Menu>
  );

  let navContent = null;
  if (isAuthentificated && matches) {
    navContent = navData.map((nd) => {
      const itemColor =
        location.pathname.indexOf(nd.navTo) === -1 ? "primary" : "#e6fcfc";
      return (
        <NavLink
          key={nd.navTo}
          to={nd.navTo}
          style={{ textDecoration: "none" }}
        >
          <Typography
            color="primary"
            sx={{
              pl: 2,
              textDecoration: "none",
              color: itemColor,
              "&:hover": {
                color: "#e6fcfc",
              },
            }}
            variant="h6"
          >
            {nd.displayText}
          </Typography>
        </NavLink>
      );
    });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        bgcolor: "primary.dark",
        top: 0,
        justifyContent: "space-between",
        pt: 0.5,
        justifyItems: "center",
        position: "sticky",
        zIndex: 20,
      }}
    >
      <Typography
        variant="h4"
        color="white"
        sx={{
          cursor: "pointer",
          pl: 1,
        }}
        onClick={onHomeClicked}
      >
        FEIT MAVE {process.env.REACT_APP_DEVEL}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignSelf: "center",
        }}
      >
        {navContent}
      </Box>
      {isAuthentificated && matches && (
        <Button
          variant="contained"
          color="primary"
          onClick={onLogoutHandler}
          sx={{ mr: 2, mb: 0.5 }}
        >
          Odhlásiť sa
        </Button>
      )}
      {isAuthentificated && !matches && (
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleProfileMenuOpen}
        >
          <MenuIcon />
        </IconButton>
      )}
      {renderMenu}
    </Box>
  );
};

export default MainNavigation;
